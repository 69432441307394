import React, {useState, useRef, useEffect} from "react";
import Grid from '@mui/material/Grid';
import { Formik, Form, useField } from 'formik';
import { ClassNames } from "@emotion/react";
import "./PopupStyles.css";
import axios from "axios";
import {PopupGridItem, PopupNullClient} from "./PopupImports";
import PopupContent from "./PopupContent";
import PropagateLoader from "react-spinners/PropagateLoader";
import Box from '@mui/material/Box';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { socket, SocketContext } from '../../context/socket';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV

function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
}

const style = {
    bgcolor: 'background.paper',
    border: '2px solid #000',
    p: 4,
};
  
const loadingStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

const getClient = async (username) => {
    var res;
    res = await axios({
        url: API_ENDPOINT + "/user/per",
        method: 'GET',
        params: {
            username: username
        }
    })
    res.data = res.data === '' ? {username: username} : res.data;
    return res.data;
}

export default function Popup(props) {
    const [client, setClient] = useState(PopupNullClient);
    const [loading, setLoading] = useState(true);
    const [clientReceived, setClientReceived] = useState(false);
    const clientUsername = props.currentClient.username
    const handleClose = props.handleClose;
    const viewOnly = props.viewOnly ? props.viewOnly : false;
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        getClient(clientUsername)
        .then((data) => {
            setLoading(false);
            setClient(data);
            setClientReceived(true);
        })
    },[clientReceived]) 

    if (loading) {
        return (
            <Box sx={loadingStyle} >
                <PropagateLoader cssOverride={{margin: "0"}}/>
            </Box>)
    } else if (client.username !== '') {
        return (
            <Grid container rowSpacing={0} spacing={0}
            style ={{maxWidth: 550, minHeight: '60vh', margin: 'auto'}} sx={style} >
                <PopupContent client={client} handleClose={handleClose} viewOnly={viewOnly} setClientReceived={setClientReceived}/>
            </Grid>
        )
    } else {
        return (
            <></>
        )
    }
}