import { Button, IconButton } from '@mui/material';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import MUIDataTable from 'mui-datatables';
import CheckIcon from '@mui/icons-material/Check';
import { socket } from 'context/socket';
import { useSnackbar } from 'notistack';
import ClientsPopup from '../Clients/ClientsPopup';
import DeleteConfirmation from 'reusable/DeleteConfirmation';

import AuthContext from 'utils/auth/auth';
import React, { useState, useContext, useEffect } from 'react';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

async function getInitialData({
  setGetLoad,
  setData,
  setEmployeesReceived,
  employeesReceived,
  user,
  adminTable,
}) {
  if (employeesReceived === false) {
    try {
      const res = await axios({
        method: 'GET',
        url: API_ENDPOINT + '/employee/allEmployee',
        params: {
          admin: adminTable,
          returnAsArray: false,
        },
      });
      console.log(res.data);
      let dataToBeUsed = [];
      res.data.forEach((element) => {
        let oneRow = {};
        Object.keys(element).forEach((el) => {
          if (el == '__v') {
          } else {
            oneRow = { ...oneRow, [el]: element[el] };
          }
        });
        console.log(oneRow);
        dataToBeUsed = [...dataToBeUsed, oneRow];
      });
      console.log(dataToBeUsed);
      console.log('data to be used');
      setData(dataToBeUsed);
      setGetLoad(false);
      setEmployeesReceived(true);
    } catch (error) {
      console.log(error);
    }
  } else {
    //DO Nothing
  }
}

function CurrentEmployeesTable(props) {
  const [getLoad, setGetLoad] = useState(true);
  const [data, setData] = useState([]);
  const [employeesReceived, setEmployeesReceived] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [pEmployee, setPEmployee] = useState(null);

  console.log(data);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const user = useContext(AuthContext);
  const adminTable = props.admin;

  // Handle the displaying of the modal
  const showDeleteModal = (pEmployee) => {
    setPEmployee(pEmployee);
    setDeleteMessage(
      `Are you sure you want to delete ${pEmployee.username}'s profile?`
    );
    setDisplayConfirmationModal(true);
  };
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleClose = () => {
    setSubmitStatus(false);
  };
  const handleDelete = (thisEmployee) => {
    return async (event) => {
      setSubmitLoad(true);
      try {
        var res = await axios.delete(API_ENDPOINT + '/employee/delete', {
          data: thisEmployee,
        });
        var status = res.status;
        if (status === 201) {
          socket.emit('deleteUser', thisEmployee.username);
          setSubmitSucess(true);
          setSubmitResponse('Delete sucessfully!');
        }
      } catch {
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      setDisplayConfirmationModal(false);
      setSubmitStatus(true);
      setSubmitLoad(false);
    };
  };

  useEffect(() => {
    getInitialData({
      setGetLoad,
      setData,
      setEmployeesReceived,
      employeesReceived,
      user,
      adminTable,
    }).catch(console.error);
  }, [employeesReceived]);

  const columns = [
    {
      label: 'Username',
      name: 'username',
      options: {
        filter: false,
      },
    },
    {
      label: 'Name',
      name: 'name',
      options: {
        filter: false,
      },
    },
    {
      label: 'SUMS worktag',
      name: 'SUMSworktag',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'Email Address',
      name: 'email',
      options: {
        filter: false,
      },
    },
    {
      label: 'Phone Number',
      name: 'phoneNumber',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'PI Name',
      name: 'labName',
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `PI: ${v ? v.replace(/^(\w).* (.*)$/, '$1. $2') : ''}`,
        },
        filterType: 'dropdown',
      },
    },
    {
      label: 'University',
      name: 'university',
      options: {
        filter: false,
      },
    },
    {
      label: 'University Tag',
      name: 'universityTag',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'No. Of Orders',
      name: 'noOfOrders',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'Order Tag',
      name: 'orderTag',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'Operation',
      name: 'operation',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <>
              {/* <EditButtonPopup users = {data[dataIndex]}/>
                            <br></br> */}
              {data[dataIndex].username == user.casUser ? (
                <>
                  <Button
                    variant="outlined"
                    onClick={() => showDeleteModal(data[dataIndex])}
                    size="small"
                    disabled={true}
                  >
                    Delete
                  </Button>
                </>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => showDeleteModal(data[dataIndex])}
                  size="small"
                  disabled={false}
                >
                  Delete
                </Button>
              )}
            </>
          );
        },
      },
    },
    {
      label: '',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          return (
            <IconButton>
              <ClientsPopup currentClient={data[value]} />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'multiselect',
    print: false,
    viewColumns: false,
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'primary',
        variant: 'outlined',
        className: 'testClass123',
      };
    },
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
          <PropagateLoader />
        </Backdrop>
        <Backdrop
          sx={{ color: '#fff', zIndex: 1 }}
          open={submitStatus}
          onClick={handleClose}
        >
          {submitSucess ? (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
                window.location.reload(false);
              }}
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              {submitResponse}
            </Alert>
          ) : (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              severity="error"
            >
              {submitResponse}
            </Alert>
          )}
        </Backdrop>
      </Grid>
      <MUIDataTable
        title={adminTable ? 'Admins' : 'Employees'}
        data={data.map((item) => {
          return [
            item.username,
            item.firstName ? item.firstName + ' ' + item.lastName : '',
            item.SUMSworktag ? item.SUMSworktag : '',
            item.email ? item.email : '',
            item.phoneNumber ? item.phoneNumber : '',
            item.PIName ? item.PIName : '',
            item.university ? item.university : '',
            item.universityTag ? item.universityTag : '',
            item.noOfOrders ? item.noOfOrders : '',
            item.orderTag ? item.orderTag : '',
          ];
        })}
        columns={columns}
        options={options}
      />
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleDelete}
        hideModal={hideConfirmationModal}
        id={pEmployee}
        message={deleteMessage}
      />
    </div>
  );
}

export default CurrentEmployeesTable;

