import React, { useState, useEffect, useRef } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import IBB from '../../../images/Locations/IBB.png';
import IBBDropbox from '../../../images/Locations/IBBDropbox.jpg';
import Marcus from '../../../images/Locations/Marcus.png';
import MarcusDropbox from '../../../images/Locations/MarcusDropbox.jpg';
import EBB from '../../../images/Locations/EBB.png';
import EBBDropbox from '../../../images/Locations/EBBDropbox.jpeg';
import EBBImage from '../../../images/Locations/EBBImage.png';
import Ford from '../../../images/Locations/Ford.png';
import FordDropbox from '../../../images/Locations/FordDropbox.jpg';
import FordImage from '../../../images/Locations/FordImage.png';
import Cherry from '../../../images/Locations/Cherry.png';
import CherryDropbox from '../../../images/Locations/CherryDropbox.jpeg';
import CherryImage from '../../../images/Locations/Cherry.jpg';
import MoSE from '../../../images/Locations/MoSE.png';
import MoSEDropbox from '../../../images/Locations/MoSEDropbox.jpg';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { useWindowSize } from './HomePage';
import './HomePage.css';

// const API_ENDPOINT =
//   process.env.NODE_ENV === 'production'
//     ? process.env.REACT_APP_API_ENDPOINT_PROD
//     : process.env.REACT_APP_API_ENDPOINT_DEV;

const center = { lat: 33.77941650228927, lng: -84.39708893070299 };
const zoom = 17.7;
const blueTextColor = 'rgb(0, 48, 87)';
const lightBlueTextColor = 'rgb(0, 138, 252)';
// const mapHeight = 620;

const render = (status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

const buildings = [
  {
    translateX: '-67%',
    translateY: '211%',
    name: 'Marcus',
    imgPath: Marcus,
    longName: <>Marcus Nanotechnology Research Center</>,
    size: 174,
    googleLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13265.226513923866!2d-84.3983734!3d33.7785791!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf6ed21c5264c94d9!2sMarcus%20Nanotechnology%20Research%20Center!5e0!3m2!1sen!2sus!4v1673936281503!5m2!1sen!2sus',
    pic: 'https://bme.gatech.edu/bme/sites/default/files/bme-uploads/core-facilities-marcus-nanotechnology-research-building.jpg',
    address: <>Marcus Nanotechnology Bldg, 345 Ferst Dr NW, Atlanta, GA
      30318</>,
    dropbox: <>3rd floor, next to elevator</>,
    dropboxImg: MarcusDropbox,
  },
  {
    translateX: '165%',
    translateY: '110%',
    name: 'Ford',
    longName: <>Ford Environmental Science & Technology Building</>,
    imgPath: Ford,
    size: 137,
    googleLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13265.259453633076!2d-84.3962362!3d33.7783664!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x95fc924a2789a2d2!2sFord%20Environmental%20Science%20%26%20Technology%20Building!5e0!3m2!1sen!2sus!4v1673936987635!5m2!1sen!2sus',
    pic: FordImage,
    address: <>311 Ferst Dr NW, Atlanta, GA 30332</>,
    dropbox: <>1st level, left of Earth and Atmospheric Sciences main office</>,
    dropboxImg: FordDropbox,
  },
  {
    translateX: '53%',
    translateY: '136%',
    name: 'IBB',
    imgPath: IBB,
    longName: <>Parker H. Petit Institute for Bioengineering and Biosciences at
      Georgia Tech</>,
    size: 198,
    googleLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13265.259453633076!2d-84.3962362!3d33.7783664!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x95fc924a2789a2d2!2sFord%20Environmental%20Science%20%26%20Technology%20Building!5e0!3m2!1sen!2sus!4v1673928854695!5m2!1sen!2sus',
    pic: 'https://www.research.gatech.edu/sites/default/files/inline-images/petit-institute-lotsa-green.jpg',
    address: <>315 Ferst Dr NW, Atlanta, GA 30332</>,
    dropbox: <>3rd floor near bridge to Whitaker</>,
    dropboxImg: IBBDropbox,
  },
  {
    translateX: '36%',
    translateY: '987%',
    name: 'Cherry',
    longName: 'Cherry L. Emerson Building',
    imgPath: Cherry,
    size: 115,
    googleLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.331689309455!2d-84.3970406!3d33.7779318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5048a669d7bed%3A0xa6293927281cc8c!2sCherry%20Emerson%20Bldg%2C%20Atlanta%2C%20GA%2030313!5e0!3m2!1sen!2sus!4v1673936816852!5m2!1sen!2sus',
    pic: CherryImage,
    address: <>310 Ferst Dr NW, Atlanta, GA 30318</>,
    dropbox: <>2nd floor</>,
    dropboxImg: CherryDropbox,
  },
  {
    translateX: '-63%',
    translateY: '5%',
    name: 'EBB',
    longName: <>Krone Engineered Biosystems Building</>,
    imgPath: EBB,
    size: 175,
    googleLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13264.881371591888!2d-84.3983939!3d33.7808077!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xaddb9a66dbbcc6b6!2sKrone%20Engineered%20Biosystems%20Building!5e0!3m2!1sen!2sus!4v1673936207778!5m2!1sen!2sus',
    pic: EBBImage,
    address: <>950 Atlantic Dr NW, Atlanta, GA 30332</>,
    dropbox: <>2nd floor, room 2032</>,
    dropboxImg: EBBDropbox,
  },
  {
    translateX: '73%',
    translateY: '190%',
    name: 'MoSE',
    longName: <>Molecular Sciences And Engineering</>,
    imgPath: MoSE,
    size: 253,
    googleLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13265.032046668393!2d-84.3966424!3d33.7798348!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1701591279ef9cda!2sMolecular%20Sciences%20And%20Engineering!5e0!3m2!1sen!2sus!4v1673937162343!5m2!1sen!2sus',
    pic: 'https://www.newcomb-boyd.com/wp-content/uploads/2017/09/Exterior-1-2.jpg',
    desc: <>The Molecular Science and Engineering Building (MoSE) includes
      275,000 gross square feet and is the fourth building of the biotechnology
      quadrangle complex located
      on the corner of Atlantic Street and Ferst Drive on Georgia Tech’s campus.
      The project was completed in 2006 and houses much of the faculty and staff
      of the College of Sciences and the NSF Center for
      Organic Photonics and Electronics.</>,
    address: <>901 Atlantic Dr NW, Atlanta, GA 30318</>,
    dropbox: <>2nd floor, off elevators to the left</>,
    dropboxImg: MoSEDropbox,

  },
];

// Underlying maps component
const LocationsMap = ({ center, zoom }) => {

  const ref = useRef();

  useEffect(() => {
    new window.google.maps.Map(ref.current, {
      center,
      zoom,
      gestureHandling: 'none',
      disableDefaultUI: true,
      styles: [
        {
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#f5f5f5',
            },
          ],
        },
        {
          'elementType': 'labels.icon',
          'stylers': [
            {
              'visibility': 'off',
            },
          ],
        },
        {
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#616161',
            },
          ],
        },
        {
          'elementType': 'labels.text.stroke',
          'stylers': [
            {
              'color': '#f5f5f5',
            },
          ],
        },
        {
          'featureType': 'administrative.land_parcel',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#bdbdbd',
            },
          ],
        },
        {
          'featureType': 'poi',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#eeeeee',
            },
          ],
        },
        {
          'featureType': 'poi',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#757575',
            },
          ],
        },
        {
          'featureType': 'poi.park',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#e5e5e5',
            },
          ],
        },
        {
          'featureType': 'poi.park',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#9e9e9e',
            },
          ],
        },
        {
          'featureType': 'road',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#ffffff',
            },
          ],
        },
        {
          'featureType': 'road.arterial',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#757575',
            },
          ],
        },
        {
          'featureType': 'road.highway',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#dadada',
            },
          ],
        },
        {
          'featureType': 'road.highway',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#616161',
            },
          ],
        },
        {
          'featureType': 'road.local',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#9e9e9e',
            },
          ],
        },
        {
          'featureType': 'transit.line',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#e5e5e5',
            },
          ],
        },
        {
          'featureType': 'transit.station',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#eeeeee',
            },
          ],
        },
        {
          'featureType': 'water',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#c9c9c9',
            },
          ],
        },
        {
          'featureType': 'water',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#9e9e9e',
            },
          ],
        },
      ],
    });
  }, [Status]);

  return (
    <div
      className={'locations__map'}
      ref={ref}
      id="map"
    />
  );
};

// Individual clickable buildings
const Building = ({ building }) => {
  const translateX = building.translateX;
  const translateY = building.translateY;
  const imgPath = building.imgPath;
  const size = building.size;
  const name = building.name;

  return (
    <div
      className="locations__building"
      style={{ transform: `translate(${translateX}, ${translateY})` }}
    >
      <img
        src={imgPath}
        style={{
          width: size,
          transition: '0.5s',
        }}
        alt={name} />
      <div className="locations__building-name">{name}</div>
    </div>
  );
};

// Card displaying building information
const BuildingCard = ({ index }) => {
  const currentBuilding = buildings[index];
  const displayBuilding = useRef(null);
  const visibilityClass = currentBuilding ? 'building-card--shown' : 'building-card--hidden';

  if (currentBuilding) {
    console.log(`Clicked on ${currentBuilding.name}`);
    displayBuilding.current = currentBuilding;
  }

  return (
    <div
      className={`building-card__container ${visibilityClass}`}>
      {displayBuilding.current && (
        <>
          <div
            className="building-card__title">{displayBuilding.current.name}</div>
          <div
            className="building-card__desc">Address: {displayBuilding.current.address}</div>
          <div className="building-card__desc">Drop
            box: {displayBuilding.current.dropbox}</div>
          <div className="building-card__img-row">
            <div className="building-card__img-container">
              <img src={displayBuilding.current.pic}
                   alt={displayBuilding.current.name} />
            </div>
            <div className="building-card__img-container">
              <img src={displayBuilding.current.dropboxImg}
                   alt={`${displayBuilding.current.name} dropbox image`} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

function PhoneBuildingCard({ name, index }) {
  const [width, height] = useWindowSize();
  const isPhoneSize = width < 985;
  // console.log(buildingCards.get(name)["googleLink"])
  return (
    <>
      <div style={{
        color: isPhoneSize ? blueTextColor : 'white',
        position: 'relative',
        height: isPhoneSize ? 'auto' : 620,
      }}>
        {width < 985 ? (
          <></>
        ) : (<>
            <div style={{ fontSize: 50, marginLeft: 10 }}>
              {buildings[index] !== undefined ? buildings[index].name : ''}
            </div>
          </>
        )}
        <div style={{
          fontSize: isPhoneSize ? 25 : 20,
          marginLeft: 10,
          marginTop: isPhoneSize ? 0 : -18,
          fontWeight: isPhoneSize ? 'bolder' : 'lighter',
          textAlign: isPhoneSize ? 'center' : 'left',
        }}>
          {buildings[index] !== undefined ? buildings[index].longName : ''}
        </div>
        <div style={{ position: 'relative' }}>
          <div style={{ maxHeight: 200, width: '100%', overflow: 'hidden' }}>
            <img style={{
              flex: 1,
              resizeMode: 'contain',
              width: '100%',
              maxHeight: 200,
            }}
                 src={buildings[index] !== undefined ? buildings[index].pic : ''} />
          </div>
          <div>
            <iframe style={{
              position: 'absolute',
              right: '0%',
              bottom: '0%',
              border: 0,
            }}
                    src={buildings[index] !== undefined ? buildings[index].googleLink : ''}
                    width="30%" height={isPhoneSize ? '100%' : '100%'}
                    allowFullScreen={false} loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
        {/* <div>
        {buildings[index] != undefined ? buildings[index].desc : ''}
        </div> */}
        <div style={{ fontSize: isPhoneSize ? 20 : 25, margin: 10 }}>
          <b>Address: </b>
          <text
            style={{ fontSize: isPhoneSize ? 16 : 20 }}>{buildings[index] !== undefined ? buildings[index].address : ''}</text>
          <br></br>
          <b>Drop box: </b>
          <text
            style={{ fontSize: isPhoneSize ? 16 : 20 }}>{buildings[index] !== undefined ? buildings[index].dropbox : ''}</text>
        </div>
        <div style={{
          maxHeight: 210,
          overflow: 'hidden',
          position: isPhoneSize ? 'relative' : 'absolute',
          bottom: 0,
          left: 0,
        }}>
          <img style={{ resizeMode: 'contain', width: '100%' }}
               src={buildings[index] !== undefined ? buildings[index].dropboxImg : ''} />
        </div>
      </div>
    </>
  );
}

// Locations Component, which includes underlying map and all buildings
const LocationsMapComponent = ({ index, setIndex }) => {
  const [click, setClick] = useState(null);
  // const mapMove = click == null ? 0 : -20;
  // const infoMove = click == null ? 100 : 0;

  // useEffect(() => {
  //   console.log(click);
  // }, [click]);

  return (
    <div className="locations__container">
      <div className="locations__title">Locations</div>
      <div className="locations__container-selectable">
        <div className="locations__map-container">
          <Wrapper apiKey={'AIzaSyCR_-XcUT3FoQnVzW4m76bK7hriStjMBuM'}
                   render={render}>
            <LocationsMap center={center} zoom={zoom} />
            <div
              // Empty div to detect clicks
              className="locations__map-container--click-reset"
              onMouseDown={() => setClick(null)}
            />
            {buildings.map((building, index) => {
              return (
                <div onMouseDown={() => setClick(index)}>
                  <Building building={building} />
                </div>
              );
            })}
          </Wrapper>
          <BuildingCard index={click} />
        </div>
        <div className="locations__list">
          {buildings.map((building, index) => (
            <div
              className="locations__list-item"
              onClick={() => setClick(index)}
            >
              {building.longName}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Locations = (props) => {
  const [index, setIndex] = useState(null);
  const screenWidth = props.screenWidth;
  const phoneWidth = props.phoneWidth;

  return (
    <>
      {screenWidth > phoneWidth ? (
        <LocationsMapComponent index={index} setIndex={setIndex} />
      ) : (
        <NestedList sections={buildings} index={index} setIndex={setIndex} />
      )}
    </>
  );
};
export default Locations;

const useStyles = makeStyles((theme) => ({
  listItem: {
    position: 'relative',
  },
  listItemText: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    textAlign: 'center',
    color: 'grey',
    fontSize: '5.0em',
    textTransform: 'uppercase',
    opacity: 0.7,
    fontWeight: 800,
    // fontFamily: 'arial',
    fontStretch: '50% 200%',
  },
  expandIcon: {
    position: 'absolute',
    top: '50%',
    right: '10%',
    transform: 'translateY(-50%) scale(4.5)',
    color: blueTextColor,
  },
  collapseIcon: {
    position: 'absolute',
    top: '50%',
    right: '10%',
    transform: 'translateY(-50%) scale(4.5)',
    color: lightBlueTextColor,
  },
}));

const NestedList = ({ sections, index, setIndex }) => {
  const classes = useStyles();
  const expandedRef = useRef(null);

  useEffect(() => {
    if (expandedRef.current) {
      expandedRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'start',
        inline: 'nearest',
      });
      setTimeout(() => {
        expandedRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 250);
    }
  }, [index]);

  const handleSectionClick = (num) => {
    if (index === num) {
      setIndex(null);
    } else {
      setIndex(num);
    }
  };

  return (
    <List>
      {sections.map((section, num) => (
        <React.Fragment key={section.name}>
          <ListItem button onClick={() => handleSectionClick(num)}
                    className={classes.listItem}
                    ref={(index === num) ? expandedRef : null}>
            <ListItemIcon>
              <img src={section.imgPath} alt={section.name}
                   style={{ height: 100 }} />
              <ListItemText disableTypography primary={section.name}
                            className={classes.listItemText} />
            </ListItemIcon>
            {(index === num ? <ExpandLess className={classes.collapseIcon} /> :
                <ExpandMore className={classes.expandIcon} />
            )}
          </ListItem>
          {(
            <Collapse in={index === num} timeout="auto" unmountOnExit>
              <div>
                <PhoneBuildingCard index={index} />
              </div>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};