import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import MUIDataTable from 'mui-datatables';
import { useSnackbar } from 'notistack';

import AuthContext from 'utils/auth/auth';
import React, { useState, useContext, useEffect } from 'react';
import { getPIResearchers } from 'utils/api/Pi';

async function getInitialData({
  setGetLoad,
  setData,
  setResearchersReceived,
  researchersReceived,
  user,
}) {
  if (researchersReceived === false) {
    try {
      const res = await getPIResearchers(user);
      let dataToBeUsed = [];
      res.data.forEach(async (element) => {
        if (element.username === user.casUser) {
        } else {
          let oneRow = {};
          Object.keys(element).forEach((el) => {
            if (el == '__v') {
            } else {
              oneRow = { ...oneRow, [el]: element[el] };
            }
          });
          dataToBeUsed = [...dataToBeUsed, oneRow];
        }
      });
      console.log(dataToBeUsed);
      setData(dataToBeUsed);
      setGetLoad(false);
      setResearchersReceived(true);
    } catch (error) {
      console.log(error);
    }
  } else {
    //DO Nothing
  }
}

function CurrentResearchersTable(props) {
  const [getLoad, setGetLoad] = useState(true);
  const [data, setData] = useState([]);
  const [researchersReceived, setResearchersReceived] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const user = useContext(AuthContext);

  useEffect(() => {
    //console.log("run");
    getInitialData({
      setGetLoad,
      setData,
      setResearchersReceived,
      researchersReceived,
      user,
    }).catch(console.error);
  }, [researchersReceived]);

  const columns = [
    {
      label: 'Username',
      name: 'username',
      options: {
        filter: false,
      },
    },
    {
      label: 'Name',
      name: 'name',
      options: {
        filter: false,
      },
    },
    {
      label: 'Email Address',
      name: 'email',
      options: {
        filter: false,
      },
    },
    {
      label: 'Phone Number',
      name: 'phoneNumber',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'SUMS worktag',
      name: 'SUMSworktag',
      options: {
        filter: false,
      },
    },
    {
      label: 'University',
      name: 'university',
      options: {
        filter: false,
      },
    },
    {
      label: 'University Tag',
      name: 'universityTag',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'No. Of Orders',
      name: 'noOfOrders',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'Order Tag',
      name: 'orderTag',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    // {
    //     label: 'Expand',
    //     options: {
    //         filter: false,
    //         sort: false,
    //         customBodyRenderLite: (value) => {
    //             return (
    //               <IconButton>
    //                 <ClientsPopup currentClient={data[value]}/>
    //               </IconButton>
    //             )
    //         },
    //         // display: 'excluded',
    //     }
    // }
  ];

  const options = {
    filter: true,
    filterType: 'multiselect',
    print: false,
    viewColumns: false,
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'primary',
        variant: 'outlined',
        className: 'testClass123',
      };
    },
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
          <PropagateLoader />
        </Backdrop>
      </Grid>
      <MUIDataTable
        title={'Current Researchers'}
        data={data.map((item) => {
          return [
            item.username,
            item.firstName + ' ' + item.lastName,
            item.email,
            item.phoneNumber,
            item.SUMSworktag,
            item.university,
            item.universityTag,
            item.noOfOrders,
            item.orderTag,
          ];
        })}
        columns={columns}
        options={options}
      />
    </div>
  );
}

export default CurrentResearchersTable;
