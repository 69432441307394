import { Alert, DialogContentText } from '@mui/material';
import { DialogContent } from '@mui/material';
import { DialogActions } from '@mui/material';
import { DialogTitle } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useState } from 'react';
import { RotateLoader } from 'react-spinners';
import { Button, LoadingButton } from 'reusable/Button';
import { downloadExcel } from 'utils/api/ExcelForm';

export const MenuUpload = (props) => {
  const uploadExcelFile = props.uploadExcelFile;
  const handleClose = props.handleClose;
  const setError = props.setError;
  const setWarning = props.setWarning;
  const setPrimerErrors = props.setPrimerErrors;
  const setSampleErrors = props.setSampleErrors;
  const setSubmitted = props.setSubmitted;
  const orderType = props.orderType;
  const formType = props.formType;

  const [isFilePicked, setIsFilePicked] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setloading] = useState(false);

  async function handleSubmit() {
    setloading(true);
    try {
      const res = await uploadExcelFile(selectedFile);
      console.log(res);
      if (res.status === 200 && formType === 'sanger') {
        const sampleErrorLocation = res.data.samples.errorLocation;
        const primerErrorLocation = res.data.primers.errorLocation;
        console.log(sampleErrorLocation);
        console.log(primerErrorLocation);
        setPrimerErrors(primerErrorLocation);
        setSampleErrors(sampleErrorLocation);
        if (sampleErrorLocation.length > 0 || primerErrorLocation.length > 0) {
          setWarning(true);
        } else {
          // Do nothing
        }
        setSubmitted(true);
      } else if (res.status === 200) {
        //put the logic for the errors here for the samples
        const sampleErrorLocation = res.data.fragments.errorLocation;
        setSampleErrors(sampleErrorLocation);
        if (sampleErrorLocation.length > 0) {
          setWarning(true);
        } else {
          // Do nothing
        }
        setSubmitted(true);
      } else {
        setError(true);
        setSubmitted(true);
        console.log('ERROR EXCEL POPUP');
      }
    } catch (error) {
      // Handle any other errors
      setError(true);
      setSubmitted(true);
    }
    setloading(false);
  }

  const changeHandler = (event) => {
    console.log('onchange');
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  async function DownloadFile(fileNo) {
    downloadExcel(fileNo).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      var fileName;
      if (fileNo === 1) {
        fileName = 'Sanger-Sequencing-form.xlsx';
      } else {
        fileName = 'Fragment-Anlysis-form.xlsx';
      }
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
  }

  return (
    <>
      <DialogTitle>Upload Excel File for Bulk Data</DialogTitle>
      <Alert severity="info">
        We provide the option for you to upload an excel sheet which will parse
        the data on your behalf. Please download the file below for your{' '}
        {orderType} order, and follow the instructions labelled in the first
        sheet.
      </Alert>
      <DialogContent>
        <div style={{ textAlign: 'center' }}>
          <Button
            variant="outlined"
            onClick={() => DownloadFile(formType === 'sanger' ? 1 : 0)}
            endIcon={<DownloadIcon />}
          >
            {orderType} Excel File{' '}
          </Button>
          <br></br>
          <br></br>
        </div>
        <DialogContentText>
          Please only upload the excel file provided by the lab.
          <br></br>
          <br></br>
        </DialogContentText>
        <div style={{ textAlign: 'center' }}>
          <input
            type="file"
            name="clientExcelUploadBody"
            onChange={changeHandler}
            disabled={loading}
          />
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          className="profileSubmit"
          type="submit"
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          disabled={!isFilePicked}
          loading={loading}
          loadingIndicator={
            <RotateLoader
              cssOverride={{ left: '100%' }}
              size={7}
              margin={-15}
            />
          }
        >
          Upload
        </LoadingButton>
      </DialogActions>
    </>
  );
};
