import { DateTime } from 'luxon';
import { Calendar, luxonLocalizer } from 'react-big-calendar';
import { Modal, Box, Typography } from '@mui/material/';

import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useCallback, useState, useEffect } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Container from 'react-bootstrap/Container';

import sampleCalendarEvents from '../Calendar/sampleCalendarEvents';
import './CustomCalendar.css';

import axios from 'axios';
import EventForm from '../Calendar/EventForm';
import NewEventForm from '../Calendar/NewEventForm';
const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 7 });

const CustomCalendar = (props) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [displayModalNew, setDisplayModalNew] = useState(false); 
  const [chosenEvent, setChosenEvent] = useState();
  const [myEvents, setEvents] = useState([]);
  const [startDate, setStartDate] = useState(); 
  const [endDate, setEndDate] = useState(); 
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [usersTasks, setUsersTasks] = useState(); 

  useEffect(() => {
    fetchDataForCalendarTable(); 
  },[displayModal, displayModalNew]); 

  const fetchDataForCalendarTable = async () => {
    const res = await axios.get(API_ENDPOINT + '/user/all'); 
    const res1 = await axios.get(API_ENDPOINT + '/order/ordersForCalendar'); 
    const response = await axios.get(API_ENDPOINT + '/calendar');
    const events = response.data;
    for (let event of events) {
      event.id = event._id;
      event.start = new Date(event.start);
      event.end = new Date(event.end);
    }
    // console.log('refetched events', events);
    setEvents([...sampleCalendarEvents, ...events]);
    
    // Data to populate table
    var usersTasksD = {}; 
    if (res.status === 200 && res1.status === 200) {
        for (let i = 0; i < res.data.length; ++i) {
          var currUser = res.data[i]; 
          usersTasksD[currUser.firstName + " " + currUser.lastName] = []; 
          for (let j = 0; j < response.data.length; ++j) {
            if (response.data[j].receivingUserId.includes(currUser._id)) {
              var currOrderCode = null; 
              for (let k = 0; k < res1.data.length; ++k) {
                if (response.data[j].orderId === res1.data[k]._id) {
                  currOrderCode = res1.data[k].orderCode; 
                  break; 
                }
              }
              if (usersTasksD[currUser.firstName + " " + currUser.lastName].length == 0) {
                usersTasksD[currUser.firstName + " " + currUser.lastName].push(currOrderCode);
              } else {
                usersTasksD[currUser.firstName + " " + currUser.lastName].push("; " + currOrderCode);
              }
            }
          }
        }
        // console.log(usersTasksD); 
        let usersTasksArr = Object.entries(usersTasksD); 
        // console.log(usersTasksArr); 
        setUsersTasks(usersTasksArr); 
        setLoadingUsers(false);
    } else {
        console.log('Error in getting Data for calendar table...')
    }
  }; 
  
  const handleSelectSlot = ({start, end}) => {
    let startNew = DateTime.fromJSDate(start); 
    let endNew = DateTime.fromJSDate(start); 
    let startNewer = startNew.set({hour: 9});
    let endNewer = endNew.set({hour: 10});
    setStartDate(startNewer); 
    setEndDate(endNewer);
    setDisplayModalNew(true); 
  }

  const handleSelectEvent = (event) => {
    setChosenEvent(event);
    setDisplayModal(true);
  };

  if (loadingUsers) {
    return (
        <h2>Loading Data for Calendar ...</h2>
    )
  }
  
  return (
    <>
      <div className="calendarView" >
        <div className="usersTable">
          <b>Technicians & Tasks</b>
          <table>
            <tbody>
              <tr>
                <th>Name</th>
                <th>Assigned Orders</th>
              </tr>
              {
                usersTasks.map((value, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{value[0]}</td>
                      <td>{value[1]}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <Container className="calendarContainer">
          <div className="height600">
            <Calendar
              localizer={localizer}
              events={myEvents}
              // defaultView={Views.WEEK}
              onSelectEvent={handleSelectEvent}
              onSelectSlot={handleSelectSlot}
              selectable
            />
          </div>
        </Container>
        {/* Modal for Update Event */}
        <Modal
          open={displayModal}
          onClose={() => {
            setDisplayModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal">
            <EventForm
              chosenEvent={chosenEvent}
              setDisplayModal={setDisplayModal}
            />
          </Box>
        </Modal>
        {/* Modal for New Event  */}
        <Modal
          open={displayModalNew}
          onClose={() => {
            setDisplayModalNew(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal">
            <NewEventForm
              startDate={startDate}
              endDate={endDate}
              setDisplayModalNew={setDisplayModalNew}
            />
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default CustomCalendar;