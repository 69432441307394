import React, { useState, useEffect } from 'react';
import { Alert, Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Backdrop } from '@mui/material';
import { RotateLoader } from 'react-spinners';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import { Formik, Form, useField } from 'formik';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import PiSelector from '../pages/profile/piSelector';
import ResearcherSelector from './researcherSelector';
import ExcelUploadPopup from '../../features/SubmissionForm/components/ExcelUploadPopup';

import { universities } from '../../context/universities';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  float: 'left',
}));

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="formError">{meta.error}</div>
      ) : null}
    </>
  );
};

export default function NewOrder(props) {
  const handleClose = props.handleClose;
  const [getLoad, setGetLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(true);
  const [submitResponse, setSubmitResponse] = useState(null);
  // const [instrument, setInstrument] = useState('');
  // const [cartridgesReceived, setCartridgesReceived] = useState(false);
  // const [cartridges, setCartridges] = useState([]);
  const [samples, setSamples] = useState([]);
  const primerMap = new Map();
  const [primers, setPrimers] = useState(primerMap);
  const [primerMapToList, setPrimerMapToList] = useState([]);

  const locationOptions = [
    'EBB',
    'Cherry Emerson',
    'IBB',
    'Marcus Nanotech',
    'ES&T',
    'MoSE',
    'Freezer',
  ];
  // const stageOptions = ['Pick-up', 'PCR Prep', 'PCR Machine', 'Purification', 'Ready to Load', 'SS Machine',
  //  'Resulting', 'Storage (Done)', 'Storage (Standby)', 'Discarded (Done)'];
  // const pcrOptions = ['QIAamplifier 96', 'Block 1', 'Block 2', 'Block 3', 'Plate PCR', 'AB ThermoCycler', 'Eppendorf ThermoCycler'];

  const initialValues = {
    dropLocation: '',
    stage: 'Arrived',
    dropOffCode: '',
    PIName: '',
    university: '',
    universityTag: '',
    piid: 0,
    researcherName: '',
    noOfSamples: 0,
    dateDroppedOff: '',
    pcrBlock: '',
    instrument: '',
    cartridgeUsed: '',
    flexBoxUsed: '',
    isRerun: false,
    isComboRun: false,
    sampleLocation: '',
    submissionType: '',
    charged: '',
    username: '',
    fragmentNoOfSamples: 0,
    sangerNoOfSamples: 0,
    completed: false,
    notes: '',
  };

  const validationSchema = Yup.object({
    noOfSamples: Yup.number().required('Required'),
    dateDroppedOff: Yup.date().required('Required'),
    dropLocation: Yup.string().required('Required'),
    university: Yup.string().required('Required').nullable('Required'),
    PIName: Yup.string().required('Required'),
    submissionType: Yup.string().required('Required'),
    username: Yup.string().required('Required'),
    piid: Yup.number().required('Required'),
    researcherName: Yup.string().required('Required'),
  });

  // async function getCartridgeData() {
  //     if (cartridgesReceived === false) {
  //         try {
  //             const res =  await axios.get(API_ENDPOINT + "/cartridge/byLetter");
  //             var cartidgeList = [];
  //             res.data.forEach((element) => {
  //                 cartidgeList.push(element.letter);
  //             })
  //             setCartridges(cartidgeList);
  //             setCartridgesReceived(true);
  //         } catch (error) {
  //             console.log(error);
  //         }
  //     }
  // }

  async function uploadExcelFile(selectedFile) {
    const formData = new FormData();
    formData.append('clientSangerExcelUploadBody', selectedFile);
    var res = await axios({
      url: API_ENDPOINT + '/sampleList/clientSangerExcel',
      method: 'POST',
      data: formData,
    });
    if (res.status == 200) {
      const newPrimers = new Map(Object.entries(res.data.primers.primerData));
      const newSamples = res.data.samples.sampleData;
      setPrimers((primers) => new Map(newPrimers));
      setSamples(newSamples);
    }
    return res;
  }

  // useEffect(() => {
  //     getCartridgeData();
  // }, [cartridgesReceived])

  const keyList = Array.from(primers.keys());
  useEffect(() => {
    var primerMapToListTemp = [];
    for (let i = 0; i < keyList.length; i++) {
      primerMapToListTemp.push({
        key: keyList[i],
        primerName: primers.get(keyList[i])
          ? primers.get(keyList[i]).primerName
          : '',
        vol: primers.get(keyList[i])
          ? parseInt(primers.get(keyList[i]).vol)
          : 0,
        count: primers.get(keyList[i]) ? primers.get(keyList[i]).count : 0,
        labPrimer: primers.get(keyList[i])
          ? primers.get(keyList[i]).labPrimer
          : false,
        labPrimerId: primers.get(keyList[i])
          ? primers.get(keyList[i]).labPrimerId
          : 0,
      });
    }
    setPrimerMapToList(primerMapToListTemp);
  }, [primers]);

  useEffect(() => {
    var primerCount = new Map();
    for (let i = 0; i < samples.length; i++) {
      for (let j = 0; j < samples[i].primerRowList.length; j++) {
        var currentKey = samples[i].primerRowList[j];
        if (primerCount.get(currentKey) === undefined) {
          primerCount.set(currentKey, 1);
        } else {
          primerCount.set(currentKey, primerCount.get(currentKey) + 1);
        }
      }
    }
    var primersCopy = primers;
    Array.from(primersCopy.keys()).forEach((key) => {
      var tempData = primersCopy.get(key);
      var count = 0;
      if (primerCount.has(key)) {
        count = primerCount.get(key);
      }
      tempData = { ...tempData, ...{ count: count } };
      primersCopy.set(key, tempData);
    });
    setPrimers(primersCopy);
  }, [primerMapToList, samples]);

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}></Backdrop>
        <Backdrop
          sx={{ color: '#fff', zIndex: 1 }}
          open={submitStatus}
          onClick={handleClose}
        >
          {submitSuccess ? (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
                window.location.reload(false);
              }}
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              {submitResponse}
            </Alert>
          ) : (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              severity="error"
            >
              {submitResponse}
            </Alert>
          )}
        </Backdrop>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            setSubmitLoad(true);
            try {
              values.samples = samples;
              values.primers = primerMapToList;
              var res = await axios.post(
                API_ENDPOINT + '/order/newAdminOrder',
                values
              );
              var status = res.status;
              await sleep(1e3);
              setSubmitSuccess(true);
              setSubmitResponse('Order sucessfully created!');
            } catch {
              setSubmitSuccess(false);
              setSubmitResponse(
                'Unsucessful! Please try again later or contact adminsitrator if problem persists'
              );
            }
            setSubmitStatus(true);
            setSubmitLoad(false);
          }}
        >
          {(formikProps) => (
            <Form>
              <Grid container spacing={0} width={600}>
                <Grid item xs={12} sm={6}>
                  <Item>
                    <label> University*</label>
                    <Autocomplete
                      disabled={submitLoad}
                      value={formikProps.values.university}
                      isOptionEqualToValue={(option, value) =>
                        option.title === value.title
                      } // Customize the equality test
                      onChange={(e, value) => {
                        formikProps.setFieldValue('university', value.title);
                        formikProps.setFieldValue('universityTag', value.abbr);
                        formikProps.setFieldValue('PIName', '');
                      }}
                      disablePortal
                      id="university"
                      name="university"
                      label="university"
                      options={universities}
                      getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.title;
                      }}
                      sx={{ width: 281 }}
                      renderInput={(params) => (
                        <TextField
                          type="universityProfile"
                          {...params}
                          placeholder="Search or select university"
                          error={
                            formikProps.touched.university &&
                            Boolean(formikProps.errors.university)
                          }
                          helperText={
                            formikProps.touched.university &&
                            formikProps.errors.university
                          }
                        />
                      )}
                    />
                  </Item>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Item>
                    <label> PI Name*</label>
                    <PiSelector
                      disabled={
                        submitLoad || formikProps.values.university === null
                      }
                      PIexists={false}
                      formikProps={formikProps}
                      setFieldValue={formikProps.setFieldValue}
                      name="PIName"
                    />
                  </Item>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Item>
                    <ResearcherSelector
                      disabled={
                        submitLoad || formikProps.values.PIName === null
                      }
                      researcherExists={false}
                      formikProps={formikProps}
                      setFieldValue={formikProps.setFieldValue}
                      name="researcherName"
                    />
                  </Item>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Item>
                    <Autocomplete
                      disabled={submitLoad}
                      options={locationOptions}
                      id="dropLocation"
                      name="dropLocation"
                      onChange={(event, newValue) => {
                        formikProps.setFieldValue('dropLocation', newValue);
                      }}
                      sx={{ width: 281 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Drop Off Location*" />
                      )}
                    />
                  </Item>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Item>
                    <TextInput
                      disabled={submitLoad}
                      label="No. of Reactions*"
                      name="noOfSamples"
                      type="number"
                    />
                  </Item>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Item>
                    <TextInput
                      disabled={submitLoad}
                      label="Drop-Off Date*"
                      name="dateDroppedOff"
                      type="date"
                    />
                  </Item>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Item>
                    <Autocomplete
                      disabled={submitLoad}
                      options={[
                        'Sanger Sequencing',
                        'Fragment Analysis',
                        'Mixed',
                      ]}
                      id="submissionType"
                      name="submissionType"
                      sx={{ width: 281 }}
                      onChange={(event, value) => {
                        if (value === 'Sanger Sequencing') {
                          formikProps.setFieldValue('submissionType', 'sanger');
                          formikProps.setFieldValue(
                            'sangerNoOfSamples',
                            formikProps.values.noOfSamples
                          );
                        } else if (value === 'Fragment Analysis') {
                          formikProps.setFieldValue('submissionType', 'sanger');
                          formikProps.setFieldValue(
                            'fragmentNoOfSamples',
                            formikProps.values.noOfSamples
                          );
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Submission Type*" />
                      )}
                    />
                  </Item>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
								<Item>
									<Autocomplete
										disabled={submitLoad}
										options={pcrOptions}
										id="pcrBlock"
										name="pcrBlock"
										onChange={(event, newValue) => {
											formikProps.setFieldValue('pcrBlock', newValue);
										}}
										sx={{ width: 281 }}
										renderInput={(params) => (
											<TextField {...params} label="Select PCR Block" />
										)}
									/>
								</Item>
							</Grid>  */}
                <Grid item xs={12} sm={6}>
                  <Item>
                    <Autocomplete
                      disabled={submitLoad}
                      options={[true, false]}
                      getOptionLabel={(option) => {
                        if (option) {
                          return 'Yes';
                        } else {
                          return 'No';
                        }
                      }}
                      onChange={(event, newValue) => {
                        formikProps.setFieldValue('isComboRun', newValue);
                      }}
                      id="isComboRun"
                      name="isComboRun"
                      sx={{ width: 281 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Combo Run?" />
                      )}
                    />
                  </Item>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
								<Item>
									<Autocomplete
										disabled={submitLoad}
										options={['SS Machine', 'SS Flex Machine']}
										id="instrument"
										name="instrument"
										sx={{ width: 281 }}
										value={instrument}
										onChange={(event, newValue) => {
											setInstrument(newValue);
											formikProps.setFieldValue('instrument', newValue);
										}}
										renderInput={(params) => (
											<TextField {...params} label="Select Instrument" />
										)}
									/>
								</Item>
							</Grid> 
							{instrument === 'SS Flex Machine' ? (
								<Grid item xs={12} sm={6}>
									<Autocomplete
										sx={{ width: 281 }}
										disabled={submitLoad}
										options={['A', 'B', 'C', 'D']}
										id="flexBoxUsed"
										name="flexBoxUsed"
										onChange={(event, newValue) => {
											formikProps.setFieldValue('flexBoxUsed', newValue);
										}}
										renderInput={(params) => (
											<TextField {...params} label="Select Flex Box Used" />
										)}
									/>
								</Grid>
							) : (
								<Grid item xs={12} sm={6}>
									<Autocomplete
										sx={{ width: 281 }}
										disabled={submitLoad}
										id="cartridgeUsed"
										name="cartridgeUsed"
										options={cartridges}
										onChange={(event, newValue) => {
											formikProps.setFieldValue('cartridgeUsed', newValue);
										}}
										renderInput={(params) => (
											<TextField {...params} label="Select Cartridge Used" />
										)}
									/>
								</Grid>
							)} */}
                <Grid item xs={12} sm={6}>
                  <Item>
                    <Autocomplete
                      disabled={submitLoad}
                      options={['Internal', 'External', 'No']}
                      onChange={(e, value) => {
                        if (value === 'Internal') {
                          formikProps.setFieldValue('isRerun', true);
                          formikProps.setFieldValue('rerunType', 'internal');
                        } else if (value === 'External') {
                          formikProps.setFieldValue('isRerun', true);
                          formikProps.setFieldValue('rerunType', 'external');
                        } else {
                          formikProps.setFieldValue('isRerun', false);
                        }
                      }}
                      id="isRerun"
                      name="isRerun"
                      sx={{ width: 281 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Rerun?" />
                      )}
                    />
                  </Item>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Item>
                    <Autocomplete
                      disabled={submitLoad}
                      options={['Yes', 'No', 'Free']}
                      id="charged"
                      name="charged"
                      onChange={(event, newValue) => {
                        formikProps.setFieldValue('charged', newValue);
                      }}
                      sx={{ width: 281 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Charged?" />
                      )}
                    />
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  <Item>
                    <TextField
                      fullWidth
                      sx={{ width: 580 }}
                      multiline
                      label="Add notes"
                      value={formikProps.values.notes}
                      onChange={(event) =>
                        formikProps.setFieldValue('notes', event.target.value)
                      }
                      disabled={submitLoad}
                    />
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  <Item>
                    <ExcelUploadPopup
                      uploadExcelFile={uploadExcelFile}
                      formType={formikProps.values.submissionType}
                    />
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  <Item>
                    <Box>
                      <LoadingButton
                        size="large"
                        classes="profileSubmit"
                        type="submit"
                        variant="outlined"
                        loading={submitLoad}
                        loadingIndicator={
                          <RotateLoader
                            cssOverride={{ left: '100%' }}
                            size={7}
                            margin={-15}
                          />
                        }
                      >
                        Submit
                      </LoadingButton>
                    </Box>
                  </Item>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
}
