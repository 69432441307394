import React, { useState, useRef } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListSubheader from '@mui/material/ListSubheader';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Switch from '@mui/material/Switch';
import ClickAwayListener from '@mui/base/ClickAwayListener';
//import { Formik, Form, useField } from 'formik';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import RotateLoader from 'react-spinners/RotateLoader';
import { socket, SocketContext } from '../../context/socket';
import axios from 'axios';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV

export default function TotalQuantityRender(props) {
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [displayQSaveButton, setDisplayQSaveButton] = useState(false);
  let thisinventory = props.thisinventory
  //console.log("thisinventory: in render: ")
  //console.log(thisinventory)

  const handleClickAway = () => {
    setDisplayQSaveButton(false)
  };


  const validationSchema = Yup.object({

    totalQuantity: Yup.number()
      .positive("Must be more than 0")
      .integer('Must be a integer'),
  
  });

  const formik = useFormik({
    //thisinventory, attri
    initialValues: thisinventory,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmitLoad(true);
      try {
        var res = await axios.put(API_ENDPOINT + '/inventory/update', values);
        var status = res.status;
        if (status === 201) {
          socket.emit('updateInventory', values.catologNumber);
          setSubmitSucess(true);
          setSubmitResponse('Update sucessfully!');
        } else if (status === 202) {
          setSubmitSucess(false);
          setSubmitResponse(
            'catolog number already exist, please input another one'
          );
        }
      } catch {
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      //console.log('submit');
      //console.log(values);
      setSubmitStatus(true);
      setDisplayQSaveButton(false)
      setSubmitLoad(false);
    },

  });

  const handleClose = () => {
    setSubmitStatus(false);
  };

  return (
    
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleClose}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>
      <ClickAwayListener onClickAway={handleClickAway}>
      <form onSubmit={formik.handleSubmit}>

      <>
      

              <TextField
              id="totalQuantity"
              name="totalQuantity"
              
              type="number"
              size="small"
              sx={{
                width: 70
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.totalQuantity}
              onChange={formik.handleChange}
              error={
                formik.touched.totalQuantity &&
                Boolean(formik.errors.totalQuantity)
              }
              helperText={
                formik.touched.totalQuantity && formik.errors.totalQuantity
              }
              onClick={() => {
                ////console.log("before: " + displayQSaveButton)
                //displayQSaveButton = true
                setDisplayQSaveButton(true)
                ////console.log("after: " + displayQSaveButton)
              }}
              
            />
             
            <br></br>
            <br></br>
              {displayQSaveButton? (
                <>
                <LoadingButton
                size= "small"
                classes="profileSubmit"
                type="submit"
                variant="outlined"
                // onClick={handleClick}
                //onClick={() => handleOnBlur (data[dataIndex], 'totalQuantity')} 
                loading={submitLoad}
                loadingIndicator={
                  <RotateLoader
                    cssOverride={{ left: '100%' }}
                    size={7}
                    margin={-15}
                  />
                }
              >
                Submit
              </LoadingButton>
              <br></br>
              <br></br>
              <Button variant="outlined" onClick={() => {setDisplayQSaveButton(false)}} size = "small"> Cancel</Button>
              </>
                //<Button variant="outlined" onClick={() => handleOnBlur (data[dataIndex], 'totalQuantity')} size = "small"> Save</Button>
              ) : (
                <></>
              )}
                
      </>
    
              
      </form>
      </ClickAwayListener>
    </Grid>
    
  );
}
