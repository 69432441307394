import { Button, IconButton } from '@mui/material';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import MUIDataTable from 'mui-datatables';
import CheckIcon from '@mui/icons-material/Check';
import { socket } from 'context/socket';
import { useSnackbar } from 'notistack';
import ClientsPopup from './ClientsPopup';
import EditButtonPopup from './EditButtonPopup';
import DeleteConfirmation from 'reusable/DeleteConfirmation';

import AuthContext from 'utils/auth/auth';
import React, { useState, useContext, useEffect } from 'react';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

async function getInitialData({
  setGetLoad,
  setData,
  setClientsReceived,
  clientsReceived,
  user,
}) {
  if (clientsReceived === false) {
    try {
      const res = await axios.get(API_ENDPOINT + '/user/all');
      let dataToBeUsed = [];
      res.data.forEach((element) => {
        let oneRow = {};
        Object.keys(element).forEach((el) => {
          if (el == '__v') {
          } else {
            oneRow = { ...oneRow, [el]: element[el] };
          }
        });
        dataToBeUsed = [...dataToBeUsed, oneRow];
      });
      setData(dataToBeUsed);
      setGetLoad(false);
      setClientsReceived(true);
    } catch (error) {
      console.log(error);
    }
  } else {
    //DO Nothing
  }
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function CurrentClientsTable(props) {
  const [getLoad, setGetLoad] = useState(true);
  const [data, setData] = useState([]);
  const [clientsReceived, setClientsReceived] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [pClient, setPClient] = useState(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const user = useContext(AuthContext);

  // Handle the displaying of the modal
  const showDeleteModal = (pClient) => {
    setPClient(pClient);
    setDeleteMessage(
      `Are you sure you want to delete ${pClient.firstName} ${pClient.lastName}'s profile?`
    );
    setDisplayConfirmationModal(true);
  };
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleClose = () => {
    setSubmitStatus(false);
  };
  const handleDelete = (thisClient) => {
    return async (event) => {
      setSubmitLoad(true);
      try {
        var res = await axios.delete(API_ENDPOINT + '/user/delete', {
          data: thisClient,
        });
        var status = res.status;
        if (status === 201) {
          socket.emit(
            'deleteUser',
            thisClient.username,
            user.firstName,
            user.lastName
          );
          setSubmitSucess(true);
          setSubmitResponse('Delete sucessfully!');
        }
      } catch {
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      setDisplayConfirmationModal(false);
      setSubmitStatus(true);
      setSubmitLoad(false);
    };
  };

  useEffect(() => {
    getInitialData({
      setGetLoad,
      setData,
      setClientsReceived,
      clientsReceived,
      user,
    }).catch(console.error);
  }, [clientsReceived]);

  const columns = [
    {
      label: 'Username',
      name: 'username',
      options: {
        filter: false,
        // setCellHeaderProps: value => ({
        //     style: {
        //         textAlign:'left',
        //     }
        // })
      },
    },
    {
      label: 'Name',
      name: 'name',
      options: {
        filter: false,
      },
    },
    {
      label: 'SUMS worktag',
      name: 'SUMSworktag',
      options: {
        filter: false,
      },
    },
    {
      label: 'Email Address',
      name: 'email',
      options: {
        filter: false,
      },
    },
    {
      label: 'Phone Number',
      name: 'phoneNumber',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'PI Name',
      name: 'labName',
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `PI: ${v ? v.replace(/^(\w).* (.*)$/, '$1. $2') : ''}`,
        },
        filterType: 'dropdown',
      },
    },
    {
      label: 'University',
      name: 'university',
      options: {
        filter: false,
      },
    },
    {
      label: 'University Tag',
      name: 'universityTag',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'No. Of Orders',
      name: 'noOfOrders',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'Order Tag',
      name: 'orderTag',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: 'Operation',
      name: 'operation',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <>
              <EditButtonPopup users={data[dataIndex]} />
              <br></br>
              <Button
                variant="outlined"
                onClick={() => showDeleteModal(data[dataIndex])}
                size="small"
                disabled={true}
              >
                {' '}
                Delete
              </Button>
            </>
          );
        },
      },
    },
    {
      label: '',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          return (
            <IconButton>
              <ClientsPopup currentClient={data[value]} />
            </IconButton>
          );
        },
        // display: 'excluded',
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'multiselect',
    print: false,
    viewColumns: false,
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'primary',
        variant: 'outlined',
        className: 'testClass123',
      };
    },
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
          <PropagateLoader />
        </Backdrop>
        <Backdrop
          sx={{ color: '#fff', zIndex: 1 }}
          open={submitStatus}
          onClick={handleClose}
        >
          {submitSucess ? (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
                window.location.reload(false);
              }}
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              {submitResponse}
            </Alert>
          ) : (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              severity="error"
            >
              {submitResponse}
            </Alert>
          )}
        </Backdrop>
      </Grid>
      <MUIDataTable
        title={'Clients'}
        data={data.map((item) => {
          return [
            item.username,
            item.firstName + ' ' + item.lastName,
            item.SUMSworktag,
            item.email,
            item.phoneNumber,
            item.PIName,
            item.university,
            item.universityTag,
            item.noOfOrders,
            item.orderTag,
          ];
        })}
        columns={columns}
        options={options}
      />
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleDelete}
        hideModal={hideConfirmationModal}
        id={pClient}
        message={deleteMessage}
      />
    </div>
  );
}

export default CurrentClientsTable;

