import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

import { SnackbarProvider } from 'notistack';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AuthProvider } from 'utils/auth/auth';
import ContactUs from './components/pages/ContactUs/ContactUs.js';
import HomePage from './components/pages/HomePage/HomePage';
import Faq from './components/pages/faq/FaqComponent';
import Sanger from './features/SubmissionForm/Sanger';
import Fragment from './features/SubmissionForm/Fragment';
import NotFoundPage from './components/pages/NotFoundPage.js';
import LoginPage from './components/pages/login';
import Orders from './features/ClientOrders/Orders';
import Drop_off from './components/pages/Drop_off';
import DropOffCode from './features/DropOff/DropOffCode';
import {
  PrivateRoute,
  PrivateRouteAdmin,
  PrivateRouteEmployee,
} from 'utils/auth/privateRoute';
import WithFooter from './components/Footer/WithFooter';
import WithMessage from './components/Message/WithMessage';
import WithNav from './components/Navbar/WithNav';
import WithoutNav from './components/Navbar/WithoutNav';
import SampleMap from './features/SampleMap/SampleMap';
import AboutUs from './components/pages/AboutUs';
import Admin from './components/pages/Admin';
import Billing from './components/pages/Billing';
import EmployeeOrders from './components/pages/EmployeeOrders';
import ExecutiveCommands from './components/pages/ExecutiveCommands';
import Inventory from './components/pages/Inventory';
import Pricing from './components/pages/Pricing';
import Team from './components/pages/Team/Team';
import Profile from './components/pages/profile/profile.js';
import ProtectedPage from './components/pages/protectedPage';
import UploadSelect from './features/SubmissionForm/UploadSelect';
import CustomCalendar from './components/pages/CustomCalendar';
import { DownloadFile } from 'features/DownloadFile/DownloadFile';

const App = () => {
  return (
    <DndProvider debugMode={true} backend={HTML5Backend}>
      <SnackbarProvider maxSnack={3}>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route element={<WithoutNav />}>
                <Route
                  path="samplemap/:sampleMapId"
                  element={<SampleMap stage={'some random stage'} />}
                />
                <Route
                  path="/Drop_off/EBB"
                  element={<Drop_off location="EBB" />}
                />
                <Route
                  path="/Drop_off/IBB"
                  element={<Drop_off location="IBB" />}
                />
                <Route
                  path="/Drop_off/ES&T"
                  element={<Drop_off location="ES&T" />}
                />
                <Route
                  path="/Drop_off/MoSE"
                  element={<Drop_off location="MoSE" />}
                />
                <Route
                  path="/Drop_off/cherry_emerson"
                  element={<Drop_off location="Cherry Emerson" />}
                />
                <Route
                  path="/Drop_off/Marcus"
                  element={<Drop_off location="Marcus" />}
                />
              </Route>
              <Route element={<WithMessage />}>
                <Route element={<WithNav />}>
                  <Route element={<WithFooter />}>
                    <Route exact path="/" element={<HomePage />} />
                    <Route path="/Aboutus" element={<AboutUs />} />
                    <Route path="/Contact-Us" element={<ContactUs />} />
                    <Route path="/Faq" element={<Faq />} />
                    <Route path="/Upload" element={<UploadSelect />} />
                    <Route path="/Upload/Sanger" element={<Sanger />} />
                    <Route path="/Upload/Fragment" element={<Fragment />} />
                    <Route path="/Team" element={<Team />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/profile" element={<PrivateRoute />}>
                      <Route path="/profile" element={<Profile />} />
                    </Route>

                    <Route path="/Orders" element={<PrivateRoute />}>
                      <Route path="/Orders" element={<Orders />} />
                    </Route>
                    {/* <Route path="/Upload" element={<PrivateRoute />}>
                      <Route path="/Upload" element={<Upload />} />
                    </Route> */}
                    <Route
                      path="/EmployeeOrders/:tabIndex"
                      element={<PrivateRouteEmployee />}
                    >
                      <Route
                        path="/EmployeeOrders/:tabIndex"
                        element={<EmployeeOrders />}
                      />
                    </Route>
                    <Route path="/Admin" element={<PrivateRouteAdmin />}>
                      <Route path="/Admin" element={<Admin />} />
                    </Route>
                    <Route path="/Inventory" element={<PrivateRoute />}>
                      {/* <Route path="/Inventory" element={<PrivateRouteAdmin />}> */}
                      <Route path="/Inventory" element={<Inventory />} />
                    </Route>
                    <Route path="/Billing" element={<PrivateRouteAdmin />}>
                      <Route path="/Billing" element={<Billing />} />
                    </Route>
                    <Route path="/Calendar" element={<PrivateRouteAdmin />}>
                      <Route path="/Calendar" element={<CustomCalendar />} />
                    </Route>
                    <Route
                      path="/ExecutiveCommands/:tabIndex"
                      element={<PrivateRouteAdmin />}
                    >
                      <Route
                        path="/ExecutiveCommands/:tabIndex"
                        element={<ExecutiveCommands />}
                      />
                    </Route>
                    <Route path="/Drop_off_code" element={<PrivateRoute />}>
                      <Route path="/Drop_off_code" element={<DropOffCode />} />
                    </Route>
                    <Route
                      path="/download/:downloadId"
                      element={<DownloadFile />}
                    />
                    <Route path="/Login" element={<LoginPage />} />
                    <Route path="/protectedPage" element={<ProtectedPage />} />
                    <Route path="/*" element={<NotFoundPage />} />
                  </Route>
                </Route>
              </Route>
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </DndProvider>
  );
};

export default App;
