import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import MUIDataTable from 'mui-datatables';
import { useSnackbar } from 'notistack';
import BillingPopup from '../Billing_Updated/BillingPopup';

import AuthContext from 'utils/auth/auth';
import React, { useContext, useEffect, useState } from 'react';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

async function getInitialData({
  setGetLoad,
  setData,
  setOrderReceived,
  ordersReceived,
  user,
}) {
  if (ordersReceived === false) {
    //console.log("getInitialData run" + user.casUser);
    try {
      const res = await axios.get(API_ENDPOINT + '/order/completed');
      let dataToBeUsed = [];
      //console.log("res.data: " + res.data.length);
      res.data.forEach((element) => {
        let oneRow = {};
        Object.keys(element).forEach((el) => {
          if (el == '__v') {
          } else {
            //console.log("key: " + el+": inital Data inside: " + element[el]);
            oneRow = { ...oneRow, [el]: element[el] };
          }
        });
        dataToBeUsed = [...dataToBeUsed, oneRow];
      });

      setData(dataToBeUsed);
      setGetLoad(false);
      setOrderReceived(true);
    } catch (error) {
      console.log(error);
    }
  } else {
    //DO Nothing
  }
}

function CompletedOrders(props) {
  const [getLoad, setGetLoad] = useState(true);
  const [data, setData] = useState([]);
  const [ordersReceived, setOrderReceived] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const user = useContext(AuthContext);

  useEffect(() => {
    //console.log("run");
    getInitialData({
      setGetLoad,
      setData,
      setOrderReceived,
      ordersReceived,
      user,
    }).catch(console.error);
  }, [ordersReceived]);

  const dropOffLocations = [
    'EBB',
    'Cherryemerson',
    'MoSE',
    'IBB',
    'ES&T',
    'Marcus',
  ];
  // retrieve data for orders from the backend
  // this ensures that even if the location is entered weirdly, we can still filter it out later
  data.forEach((order, index) => {
    if (order.dropLocation) {
      const loc = order.dropLocation
        .toLowerCase()
        .replace(/\s+/g, '')
        .replace(/-/g, '');
      if (loc.includes(dropOffLocations[0].toLowerCase())) {
        order.dropLocation = 'EBB';
      } else if (loc.includes(dropOffLocations[1].toLowerCase())) {
        order.dropLocation = 'Cherry Emerson';
      } else if (loc.includes(dropOffLocations[2].toLowerCase())) {
        order.dropLocation = 'MoSE';
      } else if (loc.includes(dropOffLocations[3].toLowerCase())) {
        order.dropLocation = 'IBB';
      } else if (loc.includes(dropOffLocations[4].toLowerCase())) {
        order.dropLocation = 'ES&T';
      } else if (loc.includes(dropOffLocations[5].toLowerCase())) {
        order.dropLocation = 'Marcus';
      }
    } else if (order.isRerun) {
      order.dropLocation = 'RERUN';
    }
  });

  const columns = [
    {
      label: 'Order Number',
      name: 'orderCode',
      options: {
        filter: false,
      },
    },
    {
      label: 'PI',
      name: 'labName',
      options: {
        filter: true,
        customFilterListOptions: {
          render: (v) => `PI: ${v ? v.replace(/^(\w).* (.*)$/, '$1. $2') : ''}`,
        },
        filterType: 'dropdown',
      },
    },
    {
      label: 'Drop-off Location',
      name: 'dropLocation',
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (v) => `Drop-off Location: ${v.map((l) => l.toUpperCase())}`,
          update: (filterList, filterPos, index) => {
            console.log('update');
            console.log(filterList, filterPos, index);
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          logic: (dropLocation, filters, row) => {
            if (filters.length) return !filters.includes(dropLocation);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            const dropOffLocations = [
              'EBB',
              'Cherry Emerson',
              'MoSE',
              'IBB',
              'ES&T',
              'Marcus',
            ];
            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">
                  Drop-off Location
                </InputLabel>
                <Select
                  multiple
                  value={filterList[index]}
                  renderValue={(selected) => selected.join(', ')}
                  onChange={(event) => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {dropOffLocations.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox
                        color="primary"
                        checked={filterList[index].indexOf(item) > -1}
                      />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      },
    },
    {
      label: 'Date of Submission',
      name: 'date',
      options: {
        filter: true,
        display: 'true',
        sortDirection: 'desc',
        customBodyRender: (value) => {
          return new Date(value).toLocaleDateString();
        },
        filterType: 'custom',
        customFilterListRender: (v) => {
          if (v[0] && v[1] && isChecked) {
            return [`Start Date: ${v[0]}`, `End Date: ${v[1]}`];
          } else if (v[0] && v[1] && !isChecked) {
            return `Start Date: ${v[0]}, End Date: ${v[1]}`;
          } else if (v[0]) {
            return `After this date: ${v[0]}`;
          } else if (v[1]) {
            return `Before this date: ${v[1]}`;
          }
          return false;
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            var check = new Date(date);
            var from = new Date(filters[0]);
            var to = new Date(filters[1]);
            from.setDate(from.getDate() + 1);
            to.setDate(to.getDate() + 1);
            if (filters[0] && filters[1]) {
              return check <= from || check >= to;
            } else if (filters[0]) {
              return check <= from;
            } else if (filters[1]) {
              return check >= to;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Date of Submission</FormLabel>
              <br></br>
              <FormGroup row>
                <TextField
                  id="startDate"
                  label="Start Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][0] || ''}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '60%', marginRight: '5%' }}
                />
                <TextField
                  id="endDate"
                  label="End Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][1] || ''}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '60%', marginRight: '5%' }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={(event) => setIsChecked(event.target.checked)}
                    />
                  }
                  label="Separate Values"
                  style={{ marginLeft: '0px' }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      label: 'Active',
      name: 'active',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
    {
      label: '',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          return (
            <IconButton>
              <BillingPopup currentOrder={data[value]} />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'multiselect',
    print: false,
    viewColumns: false,
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'primary',
        variant: 'outlined',
        className: 'testClass123',
      };
    },
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
          <PropagateLoader />
        </Backdrop>
      </Grid>
      <MUIDataTable
        title={'Completed Orders'}
        data={data.map((item) => {
          return [
            item.orderCode,
            item.labName,
            item.dropLocation,
            item.dateDroppedOff,
          ];
        })}
        columns={columns}
        options={options}
      />
    </div>
  );
}

export default CompletedOrders;
