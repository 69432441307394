import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import backgroundVideo from '../../../videos/dnaCompressed.mp4';
import backgroundVideoPhone from '../../../videos/newTests/phoneCut.mp4';
import backgroundDesktopThumb from '../../../videos/desktopDnaThumb.jpeg';
import phoneDnaThumb from '../../../images/HomePage/phoneDnaThumb.jpg';
import './HomePage.css';
import HomeInstruct from './HomeInstruct';
import Trail from './TitleTrail';
import Locations from './Locations';
import { useInView } from 'react-intersection-observer';
import { DownloadFile } from '../Upload';
import '../faq/FaqComponent.css';
import { Nav as Navs } from 'react-bootstrap';
import AuthContext from '../../../utils/auth/auth';
import { NavLink } from '../../Navbar/NavBarElements';

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

const HomePage = () => {
  // window.onscroll =()=>{
  //   if (window.scrollY < 0) setScrollY(1);
  //   if (window.scrollY >= 0) setScrollY((500 - window.scrollY)/500);
  // }
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [scrolledY, setScrollY] = useState(0.9);
  const [titleOneRef, inViewTitleOne] = useInView({
    threshold: 1,
    triggerOnce: true,
    delay: 1500,
  });
  const [titleTwoRef, inViewTitleTwo] = useInView({
    threshold: 1,
    triggerOnce: true,
    delay: 1500,
  });
  const titleOneViewed = inViewTitleOne ? 'left' : 'right';
  const titleTwoViewed = inViewTitleTwo ? 'left' : 'right';
  // var opacityBox = Math.min(scrollY , 1);

  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  const handleScroll = () => {
    const scrollY = window.scrollY;
    if (scrollY < 0) setScrollY(0.9);
    if (scrollY >= 0) setScrollY((0.9 * (500 - scrollY)) / 500);
    if (scrollY <= 300) set(true);
    if (scrollY >= 500) set(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);
  const [width, height] = useWindowSize();

  const phoneWidth = 985;
  const phoneSize = width < phoneWidth;
  const phoneTitleScale = width < phoneWidth ? 'scale(1)' : 'scale(1)';
  const phoneTitleFontSize = width < phoneWidth ? '55px' : '79px';
  const phoneTitleScaleWrap = width < phoneWidth ? '' : 'nowrap';

  const user = useContext(AuthContext);

  // const textPosition = {
  //   textAlign: width > phoneWidth ? "left" : 'left'
  // }

  // const bodyTextPading = width > phoneWidth ? 10 : 4
  // const bodyTextColumn = width > phoneWidth ? 6 : 12
  // const bodyTextColumn2 = width > phoneWidth ? 8 : 12
  // const whoWeAreLeftPad = width > phoneWidth ? 80 : 30
  // const buttonColumns = width > phoneWidth ? 3 : 6
  // const phoneTitleFontSize = {
  //   fontSize: width < 400 ? "36px" : "45px"
  // }
  // const phoneSubTitleFontSize = {
  //   fontSize: width < 400 ? "18px" : "24px"
  // }

  // const backgroundVideo = `${API_ENDPOINT}/static/videos/dnaCompressed.mp4`;
  // const backgroundVideoPhone = `${API_ENDPOINT}/static/videos/newTests/phoneCut.mp4`;
  // const backgroundDesktopThumb = `${API_ENDPOINT}/static/videos/desktopDnaThumb.jpeg`;
  // const phoneDnaThumb = `${API_ENDPOINT}/static/images/HomePage/phoneDnaThumb.jpg`

  const blueTextColor = 'rgb(0, 48, 87)';
  // const lightBlueTextColor = 'rgb(0, 138, 252)';
  const instructions = [
    {
      title: 'Upload Form',
      text: (
        <div className="instruct--spaced">
          Log in to place an order.
          <br></br>
          <item className="instruct__button">
            <button onClick={() => DownloadFile(1)}>Sanger Sequencing</button>
          </item>
          <item className="instruct__button">
            <button onClick={() => DownloadFile(0)}>Fragment Analysis</button>
          </item>
        </div>
      ),
      footer: <>Save your drop-off code.</>,
    },
    {
      title: 'Drop Off Samples',
      text: (
        <>
          Submit your samples. Include your contact info and unique code with
          your samples. Drop off at one of the 6 locations listed below.
        </>
      ),
      footer: <>Scan the QR code at a drop-box and input your code.</>,
    },
    {
      title: 'Get Results',
      text: (
        <>
          Your results will be available through your account's inbox in 0* to 2
          business days.
          <br></br>
          <div className="instruct__note">
            *First 24 samples submitted before 9:30 AM can be processed same-day
          </div>
        </>
      ),
      footer: (
        <item className="instruct__button">
          {!user || user.casUser === 'Not authenticated' ? (
            <Navs.Link
              href={`/cas/authenticate?returnTo=${window.location.pathname}`}
            >
              Sign In
            </Navs.Link>
          ) : (
            <NavLink to="/Profile">Sign In</NavLink>
          )}
        </item>
      ),
    },
  ];

  const [open, set] = useState(true);

  return (
    <div className="home__container">
      <div
        className="containerHome"
        style={{
          maxheight: phoneSize ? 100 : null,
          minHeight: phoneSize ? 654 : '',
        }}
      >
        <img
          src={width > phoneWidth ? backgroundDesktopThumb : phoneDnaThumb}
          className="video-thumb tinyThumb"
          alt="thumb"
          width="100%"
          style={{ opacity: isVideoLoaded ? 0 : 1 }}
        />
        <video autoPlay loop playsInline id="video" width={'100%'}>
          <source
            src={width < phoneWidth ? backgroundVideoPhone : backgroundVideo}
            onLoadedData={onLoadedData}
            type="video/mp4"
            style={{ opacity: isVideoLoaded ? 1 : 0 }}
          ></source>
        </video>
        <div
          className="overlayHome"
          style={{
            backgroundColor: 'white',
            width: width < phoneWidth ? '100%' : '40%',
            textAlign: 'center',
            opacity: scrolledY,
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translateX(-50%) translateY(-50%)',
              fontSize: '58px',
              fontWeight: 800,
              color: blueTextColor,
            }}
          >
            <Trail open={open}>
              Easy.
              <br></br> Convenient.
              <br></br> Fast.
              <br></br>
              {/* <div style={{fontWeight: 1, fontSize: 30, color: 'black', fontFamily: "roboto-thin,roboto,sans-serif"}}>Your results in 3-days' time.</div> */}
            </Trail>
          </div>
        </div>
      </div>
      <div
        ref={titleOneRef}
        style={{ position: 'relative', transform: phoneTitleScale }}
      >
        <div
          className="TextColorSlide"
          style={{
            backgroundPosition: titleOneViewed,
            transitionDuration: '5s',
            height: 'auto',
            textAlign: 'center',
            fontSize: phoneTitleFontSize,
            fontWeight: 'bold',
            color: blueTextColor,
            marginTop: 35,
            marginBottom: 35,
            whiteSpace: phoneTitleScaleWrap,
          }}
        >
          <text
            style={{
              WebkitTextFillColor: 'transparent',
              whiteSpace: phoneTitleScaleWrap,
              marginRight: 20,
            }}
          >
            Easy
          </text>
          <text style={{ whiteSpace: phoneTitleScaleWrap }}>
            Sample Submission.
          </text>
        </div>
      </div>
      <HomeInstruct items={instructions} phoneWidth={phoneWidth} />
      <Locations screenWidth={width} phoneWidth={phoneWidth} />
    </div>
  );
};

export default HomePage;
