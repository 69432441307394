import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

export default function ContactUs() {
  return (
    <div
      className="faq-style-wrapper"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        overflow: 'scroll',
        paddingTop: '100px',
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <h3>
            {' '}
            While we build a contact form to better serve your needs and
            requests,
          </h3>
        </Grid>
        <Grid item xs={12}>
          <h3> please contact us instead at</h3>
        </Grid>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Grid item xs={12}>
          <h3> sanger@ibb.gatech.edu</h3>
        </Grid>
      </Grid>
    </div>
  );
}
// import React, { useState, useEffect } from 'react';
// import Grid from '@material-ui/core/Grid';
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import { TextField } from "@material-ui/core";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import axios from "axios";


// import './styles.css';

// export default function ContactUs() {
//   const [response, setResponse] = useState("");
//   const validationSchema = Yup.object().shape({
//     name: Yup.string()
//       .max(40, "Name must be at most 40 characters long")
//       .required("Name is required"),
//     email: Yup.string()
//       .email("Invalid email address")
//       .required("Email is required"),
//     subject: Yup.string().required("Subject is required"),
//     message: Yup.string()
//       .max(500, "Message must be at most 500 characters long")
//       .required("Message is required")
//   });

//   const { handleSubmit, handleChange, values, errors } = useFormik({
//     initialValues: { name: "", email: "", subject: "", message: "" },
//     validationSchema,
//     onSubmit: async (values, { setSubmitting, resetForm }) => {
//       try {
//         await axios.post("https://jsonplaceholder.typicode.com/posts", values);
//         setSubmitting(false);
//         resetForm();
//         setResponse("Form submitted successfully!");
//       } catch (err) {
//         setSubmitting(false);
//         setResponse("There was an error submitting the form. Please try again later.");
//       }
//     },
//   });

//   return (
//     <div className="form-container">
//       <div className="form-left-container">
//         <div className="form-title">Contact Us</div>
//         <div className="address">315 Ferst Dr Later, Atlanta, GA 30332</div>
//         <div className="email">sanger@ibb.gatech.edu</div>
//       </div>
//       <div className="form-right-container">
//         <form onSubmit={handleSubmit}>
//           <div className="form-row">
//             <TextField
//               name="name"
//               placeholder="Name"
//               onChange={handleChange}
//               value={values.name}
//               error={!!errors.name}
//               helperText={errors.name}
//             />
//             <TextField
//               name="email"
//               placeholder="Email"
//               onChange={handleChange}
//               value={values.email}
//               error={!!errors.email}
//               helperText={errors.email}
//             />
//           </div>
//           <TextField
//             name="subject"
//             placeholder="Subject"
//             onChange={handleChange}
//             value={values.subject}
//             error={!!errors.subject}
//             helperText={errors.subject}
//             className="form-full-width"
//           />
//           <div className="form-row form-row-end">
//             <TextField
//               name="message"
//               placeholder="Message"
//               onChange={handleChange}
//               value={values.message}
//               error={!!errors.message}
//               helperText={errors.message}
//               className="form-full-width"
//               multiline
//             />
//           </div>
//           <div className="form-submit-center">
//             <button type="submit">Submit</button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };