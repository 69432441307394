import {
  FormGroup,
  Box,
  FormLabel,
  FormControl,
  ListItemText,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  InputLabel,
  MenuItem,
  Button,
  ButtonGroup,
  IconButton,
} from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckIcon from '@mui/icons-material/Check';
import MUIDataTable from 'mui-datatables';
import Autocomplete from '@mui/material/Autocomplete';
import MUICustomFilter from '../../../reusable/MUICustomFilter';
import Chip from '@mui/material/Chip';
import { socket, SocketContext } from '../../../context/socket';
import { useSnackbar } from 'notistack';
import { SnackbarProvider } from 'notistack';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import React, {
  useState,
  useRef,
  useContext,
  Redirect,
  useEffect,
} from 'react';
const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function filterLogic(catolog, filters) {
  //console.log("filters: " + filters[0])
  ////console.log(filters[0])
  var check = catolog;
  //console.log("check: " + check);
  var filteredCato = filters[0];
  if (filteredCato) {
    let result = false;
    filters.forEach((filter) => {
      result = result || filter === check;
    });
    //let result = filteredCato === check;
    //console.log("result: " + result);
    return !result;
  } else {
    return false;
  }
}
async function getInitialData({
  setGetLoad,
  setInitialData,
  setGroupByCatolog,
  inventoryidthis,
  setOrderReceived,
  setGroupByOrderID,
  setGroupByUser,
  ordersReceived,
  whichLog,
}) {
  if (ordersReceived === false) {
    try {
      let smallUrl = '/Inventory/inventoryLog';
      if (whichLog === 'the Whole Inventory') {
        smallUrl = '/Inventory/inventoryLog/all';
      }
      const res = await axios.get(API_ENDPOINT + smallUrl, {
        params: { inventoryItem: inventoryidthis },
      });
      let dataToBeUsed = [];
      //let groupByCatolog = [];
      let groupByCatolog = new Map();
      let groupByOrderID = new Map();
      let groupByUser = new Map();
      res.data.forEach((element) => {
        let oneRow = {};
        Object.keys(element).forEach((el) => {
          if (el == 'user') {
            //oneRow = { ...oneRow, [el]: element[el].firstName + ' ' + element[el].lastName};
            ////console.log("element[el]: " + JSON.stringify(element[el]));

            if (element[el] == null) {
            } else {
              let name = element[el].firstName + ' ' + element[el].lastName;

              if (name == null || name == ' ') {
                name = element[el].deletedUserWholeName;
              }
              ////console.log("name: " + name);
              oneRow = { ...oneRow, user: name };
            }

            ////console.log("onewRow" + JSON.stringify(oneRow));
          } else if (el == 'inventoryItem') {
            //oneRow = { ...oneRow, [el]: element[el].catologNumber};
            ////console.log(JSON.stringify(oneRow));
            ////console.log("onewRow" + JSON.stringify(oneRow));
            if (element[el] == null) {
              //console.log("element[el] == null: " + JSON.stringify(element[el]));
            } else {
              let cat = element[el].catologNumber;
              if (
                cat == undefined ||
                cat == '' ||
                cat == '' ||
                cat == ' ' ||
                cat == ' '
              ) {
                //cat = element[el].deletedCatolog;
                //console.log("cat == undefined");
                //oneRow = { ...oneRow, inventoryItemCombined : {catolog : element[el].deletedCatolog, deleted :  "Deleted Inventory Item"}};
                //cat = element[el].deletedCatolog;
              } else {
                //oneRow = { ...oneRow, inventoryItemCombined : {catolog : cat, deleted :  "Existing Inventory Item"}};
              }
              ////console.log("element[el] != null: " + JSON.stringify(element[el]));
              ////console.log("element[el].catologNumber: " + cat);
              //oneRow = { ...oneRow, inventoryItemCombined : {catolog : cat, deleted :  "Existing Inventory Item"}};
              oneRow = { ...oneRow, inventoryItem: cat };
            }
          } else if (el == 'processingOrder') {
            //oneRow = { ...oneRow, [el]: element[el].orderID};
            ////console.log(JSON.stringify(oneRow));
            ////console.log("onewRow" + JSON.stringify(oneRow));
            if (element[el] == null) {
            } else {
              let oid = element[el].orderID;
              if (
                oid == null ||
                oid == '' ||
                oid == '' ||
                oid == ' ' ||
                oid == ' '
              ) {
                oid = element[el].deletedOrderID;
              }
              ////console.log("element[el].orderID: " + oid);
              ////console.log("element[el]: " + JSON.stringify(element[el]));
              oneRow = { ...oneRow, processingOrder: oid };
            }
          } else {
            ////console.log("key: " + el+": inital Data inside: " + element[el]);
            oneRow = { ...oneRow, [el]: element[el] };
          }
        });
        if (oneRow.deletedCatolog) {
          oneRow = { ...oneRow, inventoryItemCombined: oneRow.deletedCatolog };
          let CatologRow = {
            catolog: oneRow.deletedCatolog,
            deleted: 'Deleted Inventory Item',
          };
          //groupByCatolog = [...groupByCatolog, CatologRow];
          groupByCatolog.set(oneRow.deletedCatolog, CatologRow);
        } else if (oneRow.inventoryItem) {
          oneRow = { ...oneRow, inventoryItemCombined: oneRow.inventoryItem };
          let CatologRow = {
            catolog: oneRow.inventoryItem,
            deleted: 'Existing Inventory Item',
          };
          //groupByCatolog = [...groupByCatolog, CatologRow];
          groupByCatolog.set(oneRow.inventoryItem, CatologRow);
        }

        if (oneRow.deletedOrderID) {
          oneRow = { ...oneRow, orderIDCombined: oneRow.deletedOrderID };
          let CatologRow = {
            catolog: oneRow.deletedOrderID,
            deleted: 'Deleted Order ID',
          };
          //groupByCatolog = [...groupByCatolog, CatologRow];
          groupByOrderID.set(oneRow.deletedOrderID, CatologRow);
        } else if (oneRow.orderID) {
          oneRow = { ...oneRow, orderIDCombined: oneRow.orderID };
          let CatologRow = {
            catolog: oneRow.orderID,
            deleted: 'Existing Order ID',
          };
          //groupByCatolog = [...groupByCatolog, CatologRow];
          groupByOrderID.set(oneRow.orderID, CatologRow);
        }

        if (oneRow.deletedUserWholeName) {
          oneRow = {
            ...oneRow,
            userWholeNameCombined: oneRow.deletedUserWholeName,
          };
          let CatologRow = {
            catolog: oneRow.deletedUserWholeName,
            deleted: 'Deleted User',
          };
          //groupByCatolog = [...groupByCatolog, CatologRow];
          groupByUser.set(oneRow.deletedUserWholeName, CatologRow);
        } else if (oneRow.user) {
          oneRow = { ...oneRow, userWholeNameCombined: oneRow.user };
          let CatologRow = { catolog: oneRow.user, deleted: 'Existing User' };
          //groupByCatolog = [...groupByCatolog, CatologRow];
          groupByUser.set(oneRow.user, CatologRow);
        }
        dataToBeUsed = [...dataToBeUsed, oneRow];
      });

      /** 
          dataToBeUsed.forEach((element) => {
            //let oneRow = {};
            //console
            Object.keys(element).forEach((el) => {
              if (el == 'deletedCatolog') {
                //console.log("el == deletedCatolog")
                element = { ...element, inventoryItemCombined : {catolog : element[el], deleted :  "Deleted Inventory Item"}};
              } else if (el == 'inventoryItem') {
                //console.log("el == inventoryItem")
                element = { ...element, inventoryItemCombined : {catolog : element[el], deleted :  "Existing Inventory Item"}};
                
              } 


            });
            dataToBeUsed = [...dataToBeUsed];
          });
          */
      //console.log('dataToBeUsed:');
      //console.log(dataToBeUsed);

      groupByCatolog = Array.from(groupByCatolog.values());
      groupByOrderID = Array.from(groupByOrderID.values());
      groupByUser = Array.from(groupByUser.values());
      //console.log('groupByCatolog:');
      //console.log(groupByCatolog);
      //console.log('groupByOrderID:');
      //console.log(groupByOrderID);
      //console.log('groupByUser:');
      //console.log(groupByUser);
      setInitialData(dataToBeUsed);
      setGroupByCatolog(groupByCatolog);
      setGroupByOrderID(groupByOrderID);
      setGroupByUser(groupByUser);
      setGetLoad(false);
      setOrderReceived(true);
    } catch (error) {
      //console.log(error);
    }
  } else {
    //DO Nothing
  }
}

export default function LogPopup(props) {
  const [isChecked, setIsChecked] = useState(false);
  const [getLoad, setGetLoad] = useState(true);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [initialData, setInitialData] = useState([]);
  const [groupByCatolog, setGroupByCatolog] = useState([]);
  const [groupByOrderID, setGroupByOrderID] = useState([]);
  const [groupByUser, setGroupByUser] = useState([]);
  const [ordersReceived, setOrderReceived] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let displayCatologNumber = props.whichLog === 'the Whole Inventory';
  let whichLog = props.whichLog;
  let inventoryidthis;
  if (!displayCatologNumber) {
    inventoryidthis = props.products._id;
  }

  const handleCloseSubmit = () => {
    setSubmitStatus(false);
  };

  useEffect(() => {
    socket.on('updateOtherslog', (msg) => {
      ////console.log("run");
      setOrderReceived(false);
      enqueueSnackbar(msg, { variant: 'info' });
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelflog', () => {
      ////console.log("run");
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateOthers', (msg) => {
      ////console.log("run");
      setOrderReceived(false);
      enqueueSnackbar(msg, { variant: 'info' });
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelf', () => {
      ////console.log("run");
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateAll', () => {
      ////console.log("run");
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateOthersProcessingOrder', (msg) => {
      ////console.log("run");
      setOrderReceived(false);
      //enqueueSnackbar(msg, { variant: 'info' });
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelfProcessingOrder', () => {
      //console.log('run');
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('connect', () => console.log(socket.id));
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on('disconnect', () => console.log('Server disconnected'));
  }, []);

  useEffect(() => {
    getInitialData({
      setGetLoad,
      setInitialData,
      setGroupByCatolog,
      inventoryidthis,
      setOrderReceived,
      setGroupByOrderID,
      setGroupByUser,
      ordersReceived,
      whichLog,
    }).catch(console.error);
  }, [ordersReceived]);
  const columns = [
    {
      label: 'Time',
      name: 'time',
      options: {
        filter: true,
        display: 'excluded',
        sortDirection: 'desc',

        customBodyRender: (value) => {
          return value.toLocaleString();
        },

        filterType: 'custom',
        customFilterListRender: (v) => {
          if (v[0] && v[1] && isChecked) {
            return [`Start Date: ${v[0]}`, `End Date: ${v[1]}`];
          } else if (v[0] && v[1] && !isChecked) {
            return `Start Date: ${v[0]}, End Date: ${v[1]}`;
          } else if (v[0]) {
            return `After this date: ${v[0]}`;
          } else if (v[1]) {
            return `Before this date: ${v[1]}`;
          }
          return false;
        },
        filterOptions: {
          names: [],

          logic(date, filters) {
            var check = new Date(date);
            var from = new Date(filters[0]);
            var to = new Date(filters[1]);
            from.setDate(from.getDate() + 1);
            to.setDate(to.getDate() + 1);
            if (filters[0] && filters[1]) {
              return check <= from || check >= to;
            } else if (filters[0]) {
              return check <= from;
            } else if (filters[1]) {
              return check >= to;
            }
            return false;
          },

          display: (filterList, onChange, index, column) => {
            //console.log(filterList)
            //console.log(filterList[index])
            //console.log(index)
            //console.log(column)

            return (
              <div>
                <FormLabel>Time</FormLabel>
                <br></br>
                <FormGroup row>
                  <TextField
                    id="startDate"
                    label="Start Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][0] || ''}
                    onChange={(event) => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '60%', marginRight: '5%' }}
                  />
                  <TextField
                    id="endDate"
                    label="End Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][1] || ''}
                    onChange={(event) => {
                      filterList[index][1] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '60%', marginRight: '5%' }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={(event) => setIsChecked(event.target.checked)}
                      />
                    }
                    label="Separate Values"
                    style={{ marginLeft: '0px' }}
                  />
                </FormGroup>
              </div>
            );
          },
        },
        print: false,
      },
    },
    {
      name: 'user',
      label: 'User',
      options: {
        filter: false,
        display: 'excluded',
        sort: true,
      },
    },

    {
      name: 'userWholeNameCombined',
      label: 'User',

      options: {
        filter: true,
        display: 'excluded',
        sort: true,

        filterType: 'custom',

        filterOptions: {
          names: [],

          logic(catolog, filters) {
            return filterLogic(catolog, filters);
          },

          display: (filterList, onChange, index, column) => {
            //let currIndex = -1;
            return (
              <MUICustomFilter
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
                groupByCatolog={groupByUser}
                labelName="User"
                deleteCategory="Deleted User"
              />
            );
          },
        },
      },
    },
    {
      name: 'deletedUserWholeName',
      label: 'Deleted User',
      options: {
        filter: false,
        display: 'excluded',
        sort: true,
      },
    },
    {
      name: 'inventoryItem',
      label: 'Catolog Number(Inventory Item)',

      options: {
        filter: false,
        display: 'excluded',
        sort: true,
      },
    },

    {
      name: 'inventoryItemCombined',
      label: 'Catolog Number(Inventory Item)',

      options: {
        filter: displayCatologNumber,
        display: 'excluded',
        sort: true,

        filterType: 'custom',

        filterOptions: {
          names: [],

          logic(catolog, filters) {
            return filterLogic(catolog, filters);
          },

          display: (filterList, onChange, index, column) => {
            //let currIndex = -1;
            //console.log("groupByCatolog: ")
            //console.log(groupByCatolog)
            return (
              <MUICustomFilter
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
                groupByCatolog={groupByCatolog}
                labelName="Catolog Number(Inventory Item)"
                deleteCategory="Deleted Inventory Item"
              />
            );
          },
        },
      },
    },

    {
      name: 'deletedCatolog',
      label: 'Deleted Catolog Number(Inventory Item)',
      options: {
        filter: false,
        display: 'excluded',
        sort: true,
      },
    },

    {
      name: 'processingOrder',
      label: 'Order ID(Processing Order)',
      options: {
        filter: false,
        display: 'excluded',
        sort: true,
      },
    },

    {
      name: 'orderIDCombined',
      label: 'Order ID(Processing Order)',

      options: {
        filter: true,
        display: 'excluded',
        sort: true,

        filterType: 'custom',

        filterOptions: {
          names: [],

          logic(catolog, filters) {
            return filterLogic(catolog, filters);
          },

          display: (filterList, onChange, index, column) => {
            //let currIndex = -1;
            //console.log("groupByOrderID: ")
            //console.log(groupByOrderID)
            return (
              <MUICustomFilter
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
                groupByCatolog={groupByOrderID}
                labelName="Order ID(Processing Order)"
                deleteCategory="Deleted Order ID"
              />
            );
          },
        },
      },
    },

    {
      name: 'deletedOrderID',
      label: 'Deleted Order ID(Processing Order)',
      options: {
        filter: false,
        display: 'excluded',
        sort: true,
      },
    },

    {
      name: 'operation',
      label: 'Operation',
      options: {
        filter: true,
        display: 'excluded',
        sort: false,
      },
    },
    {
      name: 'specificOperation',
      label: 'Specific Operation',
      options: {
        filter: false,
        display: 'excluded',
        sort: false,
      },
    },

    {
      name: 'field',
      label: 'Field',
      options: {
        filter: false,
        display: 'excluded',
        sort: true,
      },
    },
    {
      name: 'before',
      label: 'Before',
      options: {
        filter: false,
        display: 'excluded',
        sort: false,
      },
    },

    {
      name: 'after',
      label: 'After',
      options: {
        filter: false,
        display: 'excluded',
        sort: false,
      },
    },

    {
      name: 'message',
      label: 'Records',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];
  const options = {
    filter: true,
    filterType: 'multiselect',
    print: false,
    viewColumns: false,
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'primary',
        variant: 'outlined',
        className: 'testClass123',
      };
    },
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
          <PropagateLoader />
        </Backdrop>

        <Backdrop
          sx={{ color: '#fff', zIndex: 1 }}
          open={submitStatus}
          onClick={handleCloseSubmit}
        >
          {submitSucess ? (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              {submitResponse}
            </Alert>
          ) : (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              severity="error"
            >
              {submitResponse}
            </Alert>
          )}
        </Backdrop>
      </Grid>

      <MUIDataTable
        title={'Log for ' + props.whichLog}
        data={initialData}
        columns={columns}
        options={options}
      />
    </div>
  );
}
