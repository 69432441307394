import axios from 'axios';

export const getControls = async () => {
  try {
    const res = await axios.get(`/api/controls/`);
    return res.data;
  } catch (error) {
    console.error('Error getting controls: ', error);
    throw error;
  }
};

export const putControls = async (values) => {
  try {
    const res = await axios.put(`/api/controls/`, values);
    return res;
  } catch (error) {
    console.error('Error putting controls: ', error);
    throw error;
  }
};
