import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Form, Formik, useField } from 'formik';
import { React, useState } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import RotateLoader from 'react-spinners/RotateLoader';
import * as Yup from 'yup';
import PiSelector from './piSelector';
import './styles.css';

import { universities } from '../../../context/universities'

import axios from 'axios';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  float: 'left',
}));

//sleep function for cleaner UI
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

//Reusable text component for the form below, shows error and helper text when passed through
const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="formError">{meta.error}</div>
      ) : null}
    </>
  );
};

async function getInitialData({
  setGetLoad,
  setInititalData,
  initialData,
  setNewUser,
  setPIexists,
}) {
  let response;
  if (initialData.username === '') {
    try {
      const res = await axios.get(API_ENDPOINT + '/user');
      var newData = res.data;
      var currData = { ...initialData, username: newData.username };
      if (newData.firstName && newData.lastName) {
        currData = {
          ...currData,
          firstName: newData.firstName,
          lastName: newData.lastName,
        };
      }
      if (newData.email) {
        currData = { ...currData, email: newData.email };
      }
      if (newData.phoneNumber) {
        currData = { ...currData, phoneNumber: newData.phoneNumber };
      }
      if (newData.SUMSworktag) {
        currData = { ...currData, SUMSworktag: newData.SUMSworktag };
      }
      if (newData.SUMSGroupName) {
        currData = { ...currData, SUMSGroupName: newData.SUMSGroupName };
      }
      if (newData.PIName) {
        currData = { ...currData, PIName: newData.PIName };
        setPIexists(true);
      }
      if (newData.university) {
        currData = { ...currData, university: newData.university };
      }
      if (newData.universityTag) {
        currData = { ...currData, universityTag: newData.universityTag };
      }
      if (newData.piid) {
        currData = { ...currData, piid: newData.piid };
      }
      setInititalData(currData);
      setGetLoad(false);
      if (
        currData.firstName === '' ||
        currData.lastName === '' ||
        currData.SUMSworktag === '' ||
        currData.email === '' ||
        currData.PIName === '' ||
        currData.university === '' ||
        currData.SUMSGroupName === ''
      ) {
        setNewUser(true);
      }
    } catch (error) {
      console.log(error);
      console.log('Get profile data - profile.js');
    }
  } else {
    //DO NOTHING! If we did not check for a username this would endlessly rerender
  }
}

//The actual form built with formik. Please refer to package npm to understand the structure.
const Profile = () => {
  //The relevant RegExp
  const phoneRegExp = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
  const SUMStagRegExp = /^[A-Z]{2}[0-9]{8}$/;
  const [getLoad, setGetLoad] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [initialData, setInititalData] = useState({
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    SUMSworktag: '',
    PIName: '',
    university: '',
    piid: 0,
    SUMSGroupName: '',
  });
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [newUser, setNewUser] = useState(false);
  const [PIexists, setPIexists] = useState(false);
  getInitialData({
    setGetLoad,
    setInititalData,
    initialData,
    setNewUser,
    setPIexists,
  });
  const handleClose = () => {
    setSubmitStatus(false);
  };
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
        <PropagateLoader />
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleClose}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>
      <Grid container spacing={0} width={570}>
        <Grid xs={12}>
          <Item>
            <ptitle className="ProfilePageTitle">Profile</ptitle>
          </Item>
        </Grid>
        <Grid xs={12}>
          {newUser ? (
            <Alert severity="info">
              <AlertTitle>
                <strong> Hello!</strong>
              </AlertTitle>
              Welcome to the Sanger Sequencing website! Since this is your first
              time logging in, please fill out the required information below so
              you may start using the website!{' '}
              <strong>Please submit the changes at the bottom!</strong>
            </Alert>
          ) : null}
        </Grid>
      </Grid>
      <Formik
        //Initial vlaues of the form that are also updated via tha backend per the logged in user
        initialValues={initialData}
        enableReinitialize={true}
        //form validation for each field
        validationSchema={Yup.object({
          // firstName: Yup.string()
          //   .max(20, "Can't be larger than 20 characters")
          //   .min(1, 'Must be at least 1 character')
          //   .matches(
          //     /^[aA-zZ\s]+$/,
          //     'Only alphabets are allowed for this field '
          //   )
          //   .required('Required'),
          // lastName: Yup.string()
          //   .max(20, "Can't be larger than 20 characters")
          //   .min(1, 'Must be at least 1 character')
          //   .matches(
          //     /^[aA-zZ\s]+$/,
          //     'Only alphabets are allowed for this field '
          //   )
          //   .required('Required'),
          email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
          phoneNumber: Yup.string()
            .matches(phoneRegExp, 'Invalid phone number')
            .required('Required'),
          SUMSworktag: Yup.string()
            .matches(SUMStagRegExp, 'Invalid SUMS worktag')
            .required('Required'),
          PIName: Yup.string().required('Required'),
          university: Yup.string().required('Required').nullable('Required'),
          universityTag: Yup.string(),
          piid: Yup.number(),
          SUMSGroupName: Yup.string().required(
            'Group Billing name is required'
          ),
        })}
        //On Submit action fo the form upadting the values int he backend for the logged in user.
        onSubmit={async (values) => {
          setSubmitLoad(true);
          try {
            var res = await axios.put(API_ENDPOINT + '/user/update', values);
            var status = res.status;
            await sleep(1e3);
            if (status === 201) {
              setSubmitSucess(true);
              setPIexists(true);
              setSubmitResponse('Changes sucessfully submitted!');
            }
          } catch {
            setSubmitSucess(false);
            setSubmitResponse(
              'Unsucessful! Please try again later or contact adminsitrator if problem persists'
            );
          }
          console.log('submit');
          console.log(values);
          setSubmitStatus(true);
          setSubmitLoad(false);
        }}
      >
        {(formikProps) => (
          <Form>
            <Grid container spacing={0} width={580}>
              <Grid item xs={12}>
                <Item>
                  <TextInput
                    disabled={true}
                    label="Username"
                    name="username"
                    type="textProfile"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextInput
                    disabled={
                      formikProps.initialValues.firstName === '' ? false : true
                    }
                    label="First Name"
                    name="firstName"
                    type="textProfile"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextInput
                    disabled={
                      formikProps.initialValues.lastName === '' ? false : true
                    }
                    label="Last Name"
                    name="lastName"
                    type="textProfile"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextInput
                    disabled={true}
                    label="Email address *"
                    name="email"
                    type="emailProfile"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextInput
                    disabled={submitLoad}
                    label="Phone number *"
                    name="phoneNumber"
                    type="textProfile"
                  />
                </Item>
              </Grid>
              {PIexists === false ? (
                <Grid item xs={12} sm={12}>
                  <Item>
                    <label>
                      Are you the PI? If so, email us! We can provide special
                      features and priveleges!
                    </label>
                    <label style={{ fontWeight: 'bold' }}>
                      {' '}
                      Please fill out your PI's universty and name details
                      below.
                      <br></br>
                      <br></br>
                    </label>
                    <label style={{ textAlign: 'left', fontStyle: 'italic' }}>
                      {' '}
                      Please check if your PI exists in our database by
                      searching their name and checking for their PI ID.
                      <br></br>
                      Ask your PI if they know their ID.
                      <br></br>
                      If no entry exists, please type in a new name and add it.
                    </label>
                  </Item>
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={12} sm={6}>
                <Item>
                  <label> University *</label>
                  <Autocomplete
                    disabled={submitLoad || PIexists}
                    value={formikProps.values.university}
                    onChange={(e, value) => {
                      formikProps.setFieldValue('university', value.title);
                      formikProps.setFieldValue('universityTag', value.abbr);
                      formikProps.setFieldValue('PIName', '');
                    }}
                    disablePortal
                    id="university"
                    name="university"
                    label="university"
                    options={universities}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.title;
                    }}
                    sx={{ width: 281 }}
                    renderInput={(params) => (
                      <TextField
                        type="universityProfile"
                        {...params}
                        placeholder="Search or select your university"
                        error={
                          formikProps.touched.university &&
                          Boolean(formikProps.errors.university)
                        }
                        helperText={
                          formikProps.touched.university &&
                          formikProps.errors.university
                        }
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <label> PI Name *</label>
                  <PiSelector
                    disabled={
                      submitLoad || formikProps.values.university === null
                    }
                    PIexists={PIexists}
                    formikProps={formikProps}
                    setFieldValue={formikProps.setFieldValue}
                    name="PIName"
                  />
                  {/* If PI is already set, show message saying it's unchangeable. */}
                  {PIexists === false ? (
                    <div className="PIwarning">
                      Please make sure to include the middle initial if one
                      exists.{' '}
                    </div>
                  ) : (
                    <div className="PIwarning">
                      Changing your PI through the profile is not allowed.
                      Please contact us to change.{' '}
                    </div>
                  )}
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  <TextInput
                    disabled={submitLoad}
                    label="SUMS worktag *"
                    name="SUMSworktag"
                    type="textProfile"
                  />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  <TextInput
                    disabled={submitLoad}
                    label="Group Billing Name *"
                    name="SUMSGroupName"
                    type="textProfile"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <Box mb={5}>
                    <LoadingButton
                      size="large"
                      classes="profileSubmit"
                      type="submit"
                      variant="outlined"
                      loading={submitLoad}
                      loadingIndicator={
                        <RotateLoader
                          cssOverride={{ left: '100%' }}
                          size={7}
                          margin={-15}
                        />
                      }
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

const override = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};

export default Profile;

