import MUIDataTable from 'mui-datatables';
import { React, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import { Button } from 'reusable/Button';
import { getLabPrimers } from 'utils/api/LabPrimers';

const SangerLabPrimerPopup = ({ pickedLabPrimersMap, addNewLabPrimer }) => {
  const labPrimersMap = pickedLabPrimersMap ?? new Map();
  const [allLablPrimers, setAllLabPrimers] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const labPrimers = await getLabPrimers();
      setAllLabPrimers(labPrimers);
    })();
  }, []);

  const columns = [
    {
      name: 'primerName',
      label: 'Primer name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'seqData',
      label: 'Sequencing Data',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const disabledRows = labPrimersMap;
  const selectedRowIndices = allLablPrimers
    .map((row, index) => ({ ...row, index })) // Add index to each row
    .filter((row) => labPrimersMap.has(row.primerID)) // Select rows with primerID in the hashmap
    .map((row) => row.index); // Map to array of indices

  const options = {
    selectableRows: 'multiple',
    selectToolbarPlacement: 'above',
    isRowSelectable: (dataIndex, selectedRows) => {
      // Disable the row if its primer ID is in the disabledRows array
      return !disabledRows.has(allLablPrimers[dataIndex].primerID);
    },
    rowsSelected: selectedRowIndices,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      // selectedRows object contains information about the selected rows
      // you can add a submit button in this custom toolbar
      const selectedData = selectedRows.data.map(
        (datum) => allLablPrimers[datum.index]
      );
      var selectedDataCopy = selectedData;
      selectedDataCopy.filter((row) => !labPrimersMap.has(row.primerID));
      if (selectedDataCopy <= 0) {
        return <></>;
      }
      return (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            let primersToAdd = [];
            selectedData.forEach((eachPrimer) => {
              if (!labPrimersMap.has(eachPrimer.primerID)) {
                primersToAdd.push(eachPrimer);
              }
            });
            addNewLabPrimer(primersToAdd);
            setOpen(false);
          }}
        >
          Add selected primers
        </Button>
      );
    },
    filter: false,
    viewColumns: false,
    print: false,
    download: false,
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setOpen(true)}
      >
        Add lab primer
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div>
          Sanger at MECL provides an assortment of primers that you can use for
          your sample submission. Please view them below and if you would like
          to
        </div>
        <Alert severity="info">
          If you already have a lab primer selected, it will be greyed out, as
          you only need one declaration as there is an infinite volume.
        </Alert>
        <MUIDataTable
          title={'Lab Primers'}
          data={allLablPrimers}
          columns={columns}
          options={options}
        />
      </Dialog>
    </>
  );
};

export default SangerLabPrimerPopup;

