import React, { useState, useRef, useEffect, useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListSubheader from '@mui/material/ListSubheader';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Switch from '@mui/material/Switch';
import InputAdornment from '@mui/material/InputAdornment';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItemButton from '@mui/material/ListItemButton';

//import { Formik, Form, useField } from 'formik';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import RotateLoader from 'react-spinners/RotateLoader';
import { socket, SocketContext } from '../../../context/socket';
import { useSnackbar } from 'notistack';
import { SnackbarProvider } from 'notistack';
import axios from 'axios';



export default function ExpandMoreForOneStatus(props) {
  const handleClickOpen = (selectedOrderStatus) => {
    return (event) => {
      props.CatologFilter(selectedOrderStatus);
     
    }
  };

  return (
      <List
        sx={{
          width: '100%',
          maxWidth: 2000,
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 450,
        }}
      >
        {props.inventoryItems.length === 0 ? (
          <>
          </>
        ) : (
          props.inventoryItems.map((item1) => (
            <>
              <ListItem key={item1._id} alignItems="flex-start">

                <ListItemButton onClick={handleClickOpen(item1.catologNumber)}>
                <ListItemText
                  primary={
                    <>
                      <span>{'Catolog#: ' + item1.catologNumber}</span>
                      <br></br>
                      <span>{item1.name}</span>
                    </>
                  }
                  primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                  secondary={
                    <>
                    <span>{'Processing Total Quantity: ' + item1.processingQuantity}</span>
    
                      <br></br>
                    <span>{'Total Quantity: ' + item1.totalQuantity}</span>
                    <br></br>
                    <span>{'Trigger Quantity: ' + item1.triggerAmount}</span>
                    </>
                  }
               
                />
                </ListItemButton>
              </ListItem>
            </>
          ))
        )}
      </List>
  );
}

