import React, { useState, useEffect, useContext } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import RotateLoader from 'react-spinners/RotateLoader';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import './styles.css';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';

import AuthContext from 'utils/auth/auth';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  float: 'left',
}));

async function getPIs({
  setGetLoad,
  setPIs,
  setPIsReceived,
  pisReceived,
  user,
}) {
  if (pisReceived === false) {
    try {
      const res = await axios.get(API_ENDPOINT + '/pi/all');
      let dataToBeUsed = [];
      res.data.forEach((element) => {
        let oneRow = {};
        Object.keys(element).forEach((el) => {
          if (el == '__v') {
          } else {
            oneRow = { ...oneRow, [el]: element[el] };
          }
        });
        dataToBeUsed = [...dataToBeUsed, oneRow];
      });
      setPIs(dataToBeUsed);
      setGetLoad(false);
      setPIsReceived(true);
    } catch (error) {
      console.log(error);
    }
  } else {
    //DO Nothing
  }
}

async function getUsers({
  setGetLoad,
  setData,
  setClientsReceived,
  clientsReceived,
  user,
}) {
  if (clientsReceived === false) {
    try {
      const res = await axios.get(API_ENDPOINT + '/user/all');
      let dataToBeUsed = [];
      res.data.forEach((element) => {
        let oneRow = {};
        Object.keys(element).forEach((el) => {
          if (el == '__v') {
          } else {
            oneRow = { ...oneRow, [el]: element[el] };
          }
        });
        dataToBeUsed = [...dataToBeUsed, oneRow];
      });
      setData(dataToBeUsed);
      setGetLoad(false);
      setClientsReceived(true);
    } catch (error) {
      console.log(error);
    }
  } else {
    //DO Nothing
  }
}

export default function ReassignPI(props) {
  const [getLoad, setGetLoad] = useState(false);
  const [data, setData] = useState([]);
  const [pis, setPIs] = useState([]);
  const [pisReceived, setPIsReceived] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [clientsReceived, setClientsReceived] = useState(false);
  const initialData = { username: '' };
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [usernameExists, setUsernameExists] = useState(false);
  const user = useContext(AuthContext);

  const handleClose = () => {
    setSubmitStatus(false);
  };

  const validationSchema = Yup.object({
    pi: Yup.string().required('Required'),
    username: Yup.string().required('Required'),
  });

  useEffect(() => {
    getPIs({ setGetLoad, setPIs, setPIsReceived, pisReceived, user }).catch(
      console.error
    );
  }, [pisReceived]);

  useEffect(() => {
    getUsers({
      setGetLoad,
      setData,
      setClientsReceived,
      clientsReceived,
      user,
    }).catch(console.error);
  }, [clientsReceived]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
        <PropagateLoader />
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleClose}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
              window.location.reload(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>
      <Formik
        initialValues={initialData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setSubmitLoad(true);
          try {
            var res = await axios.put(API_ENDPOINT + '/pi/reassign', values);
            var status = res.status;
            console.log(status);
            await sleep(1e3);
            if (status === 202) {
              setUsernameExists(true);
              setSubmitSucess(false);
              setSubmitResponse(
                'Cannot reassign PI. User has a different PI ID.'
              );
            }
            if (status === 201) {
              setSubmitSucess(true);
              setUsernameExists(false);
              setSubmitResponse('PI sucessfully reassigned!');
            }
            if (status === 200) {
              setUsernameExists(false);
              setSubmitSucess(false);
              setSubmitResponse(
                'User has no PI reference. Please update user information.'
              );
            }
            if (status === 205) {
              setUsernameExists(false);
              setSubmitSucess(false);
              setSubmitResponse('User already assigned.');
            }
          } catch {
            setSubmitSucess(false);
            setSubmitResponse(
              'Unsucessful! Please try again later or contact adminsitrator if problem persists'
            );
          }
          setSubmitStatus(true);
          setSubmitLoad(false);
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={0} width={400}>
              <Grid item xs={12}>
                <Item>
                  <label> Search for PI by username or ID.</label>
                  <br></br>
                  <Autocomplete
                    sx={{ width: 370 }}
                    id="pi"
                    name="pi"
                    onChange={(e, value) => {
                      props.setFieldValue('pi', value.PIName);
                      props.setFieldValue('piid', value.piid);
                      props.setFieldValue('piUniversity', value.university);
                      props.setFieldValue(
                        'piUniversityTag',
                        value.universityTag
                      );
                    }}
                    disableClearable
                    options={pis}
                    getOptionLabel={(option) => {
                      return option.piid + ': ' + option.PIName;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search for PI"
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <label> Search for User by username.</label>
                  <br></br>
                  <Autocomplete
                    sx={{ width: 370 }}
                    id="username"
                    name="username"
                    onChange={(e, value) => {
                      props.setFieldValue('username', value.username);
                    }}
                    disableClearable
                    options={data}
                    getOptionLabel={(option) => {
                      return option.username;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Username"
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                  />
                  {usernameExists ? (
                    <div className="usernameWarning">
                      Please try another username.{' '}
                    </div>
                  ) : (
                    <></>
                  )}
                </Item>
              </Grid>
              {/* <Grid item xs={12} sm={8}></Grid> */}
              <Grid item xs={12} sm={6}>
                <Item>
                  <Box>
                    <LoadingButton
                      size="large"
                      classes="profileSubmit"
                      type="submit"
                      variant="outlined"
                      loading={submitLoad}
                      loadingIndicator={
                        <RotateLoader
                          cssOverride={{ left: '100%' }}
                          size={7}
                          margin={-15}
                        />
                      }
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}
