import { emptyTableStyle } from './TableStyles';

export const EmptyTableRow = ({ cols }) => {
  return (
    <tr>
      <td colSpan={cols} style={emptyTableStyle}>
        This table is empty.
      </td>
    </tr>
  );
};
