import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Popup from './Popup';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Stack from '@mui/material/Stack';
import { useDrag } from 'react-dnd';
import { socket, SocketContext } from '../../context/socket';
import getOrderCode from '../../utils/orderCode';

var stringToColour = function (str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

function ColorLuminance(hex, lum) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  var rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }

  return rgb;
}

String.prototype.hashCode = function () {
  var hash = 0,
    i,
    chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export default function OrderButton(props) {
  const [open, setOpen] = React.useState(false);
  let currentOrder = props.currentOrder;
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    socket.off('updateSaveCR');
    socket.off('updateOrder');
    socket.off('saveCR');
    socket.off('orderSubmitted');
    socket.off('submitCR');
  };

  const [{ isDraggingOrder }, dragOrder] = useDrag(
    () => ({
      type: 'order',
      item: { id: currentOrder },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [props.currentOrder]
  );

  let comboRunNo;
  let stringCode;
  let color;
  let style;
  let darkerColor;
  let disabled = false;
  if (currentOrder.inComboRun || currentOrder.isComboRun) {
    comboRunNo = currentOrder.comboRunNo;
    stringCode =
      comboRunNo + 'CR' + comboRunNo + 'adw1' + comboRunNo + 'RC' + comboRunNo;
    let result = stringCode.hashCode();
    color = stringToColour(stringCode);
    darkerColor = ColorLuminance(color, -0.1);
    style = {
      backgroundColor: color,
      borderColor: color,
      '&:hover': {
        background: darkerColor,
      },
    };
  } else {
    style = {};
  }
  if (props.currentOrder.orderCode === '.') {
    style = {
      '&.Mui-disabled': {
        backgroundColor: 'white',
      },
    };
    disabled = true;
  }
  const orderCode = getOrderCode(props.currentOrder);
  return (
    <div>
      {currentOrder.inComboRun ? (
        <Button
          variant="contained"
          onClick={handleOpen}
          style={{ maxWidth: '10px', zIndex: 2 }}
          sx={style}
          disabled={disabled}
          ref={dragOrder}
        >
          {orderCode}
        </Button>
      ) : currentOrder.temp === true ? (
        <Button
          variant="contained"
          onClick={handleOpen}
          style={{ maxWidth: '10px', zIndex: 0 }}
          sx={style}
          disabled={disabled}
        >
          {orderCode}
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={handleOpen}
          style={{ maxWidth: '10px', zIndex: 2 }}
          sx={style}
          disabled={disabled}
          ref={dragOrder}
        >
          {orderCode}
        </Button>
      )}
      <Modal open={open} onClose={handleClose} style={{ display: 'flex' }}>
        <Popup
          currentOrder={currentOrder}
          handleClose={handleClose}
          modalOpen={open}
          workflow={true}
        />
      </Modal>
    </div>
  );
}
