import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import MUIDataTable from 'mui-datatables';
import CheckIcon from '@mui/icons-material/Check';
import { socket } from 'context/socket';
import { useSnackbar } from 'notistack';
import LogButtonPopup from './Log/LogButtonPopup';
import EditButtonPopup from './Edit/EditButtonPopup.js';
import DeleteConfirmation from 'reusable/DeleteConfirmation';

import AuthContext from 'utils/auth/auth';
import React, { useContext, useEffect, useState } from 'react';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

async function getInitialData({
  setGetLoad,
  setData,
  setCartridgesReceived,
  cartridgesReceived,
  user,
}) {
  if (cartridgesReceived === false) {
    try {
      const res = await axios.get(API_ENDPOINT + '/cartridge/all');
      let dataToBeUsed = [];
      res.data.forEach((element) => {
        let oneRow = {};
        Object.keys(element).forEach((el) => {
          if (el == '__v') {
          } else {
            oneRow = { ...oneRow, [el]: element[el] };
          }
        });
        dataToBeUsed = [...dataToBeUsed, oneRow];
      });
      setData(dataToBeUsed);
      setGetLoad(false);
      setCartridgesReceived(true);
    } catch (error) {
      console.log(error);
    }
  } else {
    //DO Nothing
  }
}

function TableMUI(props) {
  const [getLoad, setGetLoad] = useState(true);
  const [data, setData] = useState([]);
  const [cartridgesReceived, setCartridgesReceived] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [currCartridge, setCurrCartridge] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const user = useContext(AuthContext);

  // Handle the displaying of the modal
  const showDeleteModal = (currCartridge) => {
    setCurrCartridge(currCartridge);
    setDeleteMessage(
      `Are you sure you want to delete ${currCartridge.cartridgeID}'s record?`
    );
    setDisplayConfirmationModal(true);
  };
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleClose = () => {
    setSubmitStatus(false);
  };
  const handleDelete = (thisCartridge) => {
    return async (event) => {
      setSubmitLoad(true);
      try {
        var res = await axios.delete(API_ENDPOINT + '/cartridge/delete', {
          data: thisCartridge,
        });
        var status = res.status;
        if (status === 201) {
          socket.emit(
            'deletecartridge',
            currCartridge.cartridgeID,
            user.firstName,
            user.lastName
          );
          setSubmitSucess(true);
          setSubmitResponse('Deleted sucessfully!');
        }
      } catch {
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      setDisplayConfirmationModal(false);
      setSubmitStatus(true);
      setSubmitLoad(false);
    };
  };

  const formatID = (cartridgeID) => {
    if (cartridgeID.length > 6) {
      return `${cartridgeID.slice(0, 6)}-${cartridgeID.slice(6)}`;
    } else {
      return cartridgeID;
    }
  };

  useEffect(() => {
    getInitialData({
      setGetLoad,
      setData,
      setCartridgesReceived,
      cartridgesReceived,
      user,
    }).catch(console.error);
  }, [cartridgesReceived]);

  const columns = [
    {
      label: 'Cartridge ID',
      name: 'cartridgeID',
      options: {
        filter: false,
        customBodyRender: (value) => formatID(value),
      },
    },
    {
      label: 'Expiration Date',
      name: 'expirationDate',
      options: {
        filter: true,
        display: 'true',
        sortDirection: 'desc',
        customBodyRender: (value) => {
          return new Date(value).toLocaleDateString('en-us', {
            timeZone: 'UTC',
          });
        },
        filterType: 'custom',
        customFilterListRender: (v) => {
          if (v[0] && v[1] && isChecked) {
            return [`Start Date: ${v[0]}`, `End Date: ${v[1]}`];
          } else if (v[0] && v[1] && !isChecked) {
            return `Start Date: ${v[0]}, End Date: ${v[1]}`;
          } else if (v[0]) {
            return `After this date: ${v[0]}`;
          } else if (v[1]) {
            return `Before this date: ${v[1]}`;
          }
          return false;
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            var check = new Date(date);
            var from = new Date(filters[0]);
            var to = new Date(filters[1]);
            from.setDate(from.getDate() + 1);
            to.setDate(to.getDate() + 1);
            if (filters[0] && filters[1]) {
              return check <= from || check >= to;
            } else if (filters[0]) {
              return check <= from;
            } else if (filters[1]) {
              return check >= to;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Expiration Date</FormLabel>
              <br></br>
              <FormGroup row>
                <TextField
                  id="startDate"
                  label="Start Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][0] || ''}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '60%', marginRight: '5%' }}
                />
                <TextField
                  id="endDate"
                  label="End Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][1] || ''}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '60%', marginRight: '5%' }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={(event) => setIsChecked(event.target.checked)}
                    />
                  }
                  label="Separate Values"
                  style={{ marginLeft: '0px' }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      label: 'Capillaries',
      name: 'capillaries',
      options: {
        filter: false,
        customBodyRender: (value) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {value.map((item) => (
              <div style={{ marginRight: '5px' }}>
                <FiberManualRecordIcon
                  style={{
                    color: item === 'broken' ? 'red' : 'green',
                  }}
                />
              </div>
            ))}
          </div>
        ),
      },
    },
    {
      label: 'No. of Injections',
      name: 'injections',
      options: {
        filter: false, // display: 'excluded',
      },
    },
    {
      label: 'Operation',
      name: 'operation',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <>
              <EditButtonPopup cartridges={data[dataIndex]} />
              <br></br>
              <Button
                variant="outlined"
                onClick={() => showDeleteModal(data[dataIndex])}
                size="small"
                disabled={false}
              >
                {' '}
                Delete
              </Button>
            </>
          );
        },
      },
    },
    {
      label: 'Log',
      name: 'log',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <>
              <LogButtonPopup
                products={data[dataIndex]}
                whichLog={data[dataIndex]._id}
              />
            </>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'multiselect',
    print: false,
    viewColumns: false,
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'primary',
        variant: 'outlined',
        className: 'testClass123',
      };
    },
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
          <PropagateLoader />
        </Backdrop>
        <Backdrop
          sx={{ color: '#fff', zIndex: 1 }}
          open={submitStatus}
          onClick={handleClose}
        >
          {submitSucess ? (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
                window.location.reload(false);
              }}
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              {submitResponse}
            </Alert>
          ) : (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              severity="error"
            >
              {submitResponse}
            </Alert>
          )}
        </Backdrop>
      </Grid>
      <MUIDataTable
        title={'Cartridges'}
        data={data.map((item) => {
          return [
            item.cartridgeID,
            item.expirationDate,
            item.capillaries,
            item.injections,
            item.letter,
          ];
        })}
        columns={columns}
        options={options}
      />
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleDelete}
        hideModal={hideConfirmationModal}
        id={currCartridge}
        message={deleteMessage}
      />
    </div>
  );
}

export default TableMUI;

