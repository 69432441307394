import { IconButton } from 'reusable/IconButton';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  borderLeft,
  borderLeftRight,
  borderRight,
  rowButtonStyle,
  rowContainerStyle,
} from 'reusable/TableStyles';
import { ReactionTableWell } from './ReactionTableWell';
import { ReactionTableSample } from './ReactionTableSample';
import { ReactionTableTargetConc } from './ReactionTableTargetConc';
import { ReactionTableConc } from './ReactionTableConc';
import { ReactionTableVolume } from './ReactionTableVolume';
import { ReactionTableWater } from './ReactionTableWater';
import { useDraggable } from 'hooks/useDraggable';

export const ReactionTableRow = ({
  sample,
  index,
  setSample,
  moveSample,
  removeSample,
  tableWidth,
}) => {
  const { setDraggable, draggableProps } = useDraggable(moveSample, index);
  const rowWidth = tableWidth - 130;

  return (
    <tr style={rowContainerStyle} {...draggableProps}>
      <td style={borderRight}>
        <ReactionTableWell well={sample.well} />
      </td>
      <td style={borderLeftRight}>
        <ReactionTableSample
          sampleName={sample.name}
          setSampleName={(newName) => setSample({ name: newName })}
          width={rowWidth * 0.4}
        />
      </td>
      <td style={borderLeftRight}>
        <ReactionTableTargetConc
          conc={sample.targetConcentration}
          width={rowWidth * 0.15}
        />
      </td>
      <td style={borderLeftRight}>
        <ReactionTableConc
          conc={sample.concentration}
          width={rowWidth * 0.15}
        />
      </td>
      <td style={borderLeftRight}>
        <ReactionTableVolume
          target={sample.targetConcentration}
          conc={sample.concentration}
          width={rowWidth * 0.15}
        />
      </td>
      <td style={borderLeft}>
        <ReactionTableWater
          target={sample.targetConcentration}
          conc={sample.concentration}
          width={rowWidth * 0.15}
        />
      </td>
      <td style={{ width: 0, height: '100%', position: 'static' }}>
        <IconButton
          style={{
            ...rowButtonStyle,
            left: 0,
            transform: 'translateY(-50%) translateX(-100%)',
          }}
          onMouseDown={() => setDraggable(true)}
        >
          <DragHandleIcon />
        </IconButton>
        {sample.type === 'control' && (
          <div style={rowButtonStyle}>
            <IconButton onClick={removeSample}>
              <RemoveIcon />
            </IconButton>
          </div>
        )}
      </td>
    </tr>
  );
};
