import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { Form, Formik, useField } from 'formik';
import React, { useState } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import RotateLoader from 'react-spinners/RotateLoader';
import * as Yup from 'yup';
import PiSelector from '../pages/profile/piSelector';
import './styles.css';

import { universities } from '../../context/universities'

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  float: 'left',
}));

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="formError">{meta.error}</div>
      ) : null}
    </>
  );
};

export default function AddClient(props) {
  const phoneRegExp = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
  const usernameRegExp = /^[a-zA-Z0-9]+$/;
  const SUMStagRegExp = /^[A-Z]{2}[0-9]{8}$/;
  const [getLoad, setGetLoad] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const initialData = {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    SUMSworktag: '',
    PIName: '',
    university: '',
    universityTag: '',
  };
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [PIexists, setPIexists] = useState(false);
  const [usernameExists, setUsernameExists] = useState(false);

  const handleClose = () => {
    setSubmitStatus(false);
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .min(4, 'Must be at least 4 characters')
      .max(20, "Can't be larger than 20 characters")
      .matches(usernameRegExp, 'Invalid username')
      .required('Required'),
    // firstName: Yup.string()
    //   .max(20, "Can't be larger than 20 characters")
    //   .min(1, 'Must be at least 1 character')
    //   .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    //   .required('Required'),
    // lastName: Yup.string()
    //   .max(20, "Can't be larger than 20 characters")
    //   .min(1, 'Must be at least 1 character')
    //   .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    //   .required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, 'Invalid phone number')
      .required('Required'),
    SUMSworktag: Yup.string()
      .matches(SUMStagRegExp, 'Invalid SUMS worktag')
      .required('Required'),
    PIName: Yup.string().required('Required'),
    university: Yup.string().required('Required').nullable('Required'),
    universityTag: Yup.string(),
    noOfOrders: Yup.number(),
    orderTag: Yup.string(),
  });

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
        <PropagateLoader />
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleClose}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
              window.location.reload(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>
      <Formik
        initialValues={initialData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setSubmitLoad(true);
          try {
            var res = await axios.put(API_ENDPOINT + '/user/add', values);
            var status = res.status;
            console.log(status);
            await sleep(1e3);
            if (status === 202) {
              setUsernameExists(true);
              setSubmitSucess(false);
              setSubmitResponse('Username already exists. Please try another.');
            }
            if (status === 201) {
              setSubmitSucess(true);
              setPIexists(true);
              setUsernameExists(false);
              setSubmitResponse('User sucessfully added!');
            }
          } catch {
            setSubmitSucess(false);
            setSubmitResponse(
              'Unsucessful! Please try again later or contact adminsitrator if problem persists'
            );
          }
          setSubmitStatus(true);
          setSubmitLoad(false);
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={0} width={600}>
              <Grid item xs={12}>
                <Item>
                  <TextInput
                    disabled={submitLoad}
                    label="Username"
                    name="username"
                    type="text"
                  />
                  {usernameExists === true ? (
                    <div className="usernameWarning">
                      Username already exists. Please try another.{' '}
                    </div>
                  ) : (
                    <></>
                  )}
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextInput
                    disabled={submitLoad}
                    label="First Name"
                    name="firstName"
                    type="text"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextInput
                    disabled={submitLoad}
                    label="Last Name"
                    name="lastName"
                    type="text"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextInput
                    disabled={submitLoad}
                    label="Email address"
                    name="email"
                    type="email"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <TextInput
                    disabled={submitLoad}
                    label="Phone number"
                    name="phoneNumber"
                    type="text"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <label> University</label>
                  <Autocomplete
                    disabled={submitLoad || PIexists}
                    value={props.values.university}
                    onChange={(e, value) => {
                      props.setFieldValue('university', value.title);
                      props.setFieldValue('universityTag', value.abbr);
                      props.setFieldValue('PIName', '');
                    }}
                    disablePortal
                    id="university"
                    name="university"
                    label="university"
                    options={universities}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.title;
                    }}
                    sx={{ width: 281 }}
                    renderInput={(params) => (
                      <TextField
                        type="universityProfile"
                        {...params}
                        placeholder="Search or select university"
                        error={
                          props.touched.university &&
                          Boolean(props.errors.university)
                        }
                        helperText={
                          props.touched.university && props.errors.university
                        }
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <label> PI Name</label>
                  <PiSelector
                    disabled={submitLoad || props.values.university === null}
                    PIexists={PIexists}
                    formikProps={props}
                    setFieldValue={props.setFieldValue}
                    selectOnly={true}
                    name="PIName"
                  />
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <TextInput
                    disabled={submitLoad}
                    label="SUMS worktag"
                    name="SUMSworktag"
                    type="text"
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Item>
                  <Box>
                    <LoadingButton
                      size="large"
                      classes="profileSubmit"
                      type="submit"
                      variant="outlined"
                      loading={submitLoad}
                      loadingIndicator={
                        <RotateLoader
                          cssOverride={{ left: '100%' }}
                          size={7}
                          margin={-15}
                        />
                      }
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

const override = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};
