import { useState } from 'react';
import { Button } from 'reusable/Button';
import { AddControlPopup, NewControlPopup } from 'reusable/ControlTablePopups';

export const ControlTableButtons = ({
  controls,
  createControl,
  refreshControls,
}) => {
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [newDialogOpen, setNewDialogOpen] = useState(false);

  /**
   * Create new control in the samples with the given index
   */
  const handleAddControl = async (index) => {
    console.log(index);
    await createControl(index);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 40,
        paddingTop: 20,
        gap: 10,
      }}
    >
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setAddDialogOpen(true)}
      >
        Add Control
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setNewDialogOpen(true)}
      >
        Create Control
      </Button>
      <Button variant="contained" color="secondary">
        Manage Controls
      </Button>
      <AddControlPopup
        open={addDialogOpen}
        setOpen={setAddDialogOpen}
        controls={controls}
        openNewPopup={() => setNewDialogOpen(true)}
        addControl={handleAddControl}
      />
      <NewControlPopup
        open={newDialogOpen}
        setOpen={setNewDialogOpen}
        refreshControls={refreshControls}
      />
    </div>
  );
};
