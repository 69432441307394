import {
  FormGroup,
  Box,
  FormLabel,
  FormControl,
  ListItemText,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  InputLabel,
  MenuItem,
  Button,
  ButtonGroup,
  IconButton,
} from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import MUIDataTable from 'mui-datatables';
import BillingPopup from './BillingPopup';
import { socket, SocketContext } from '../../context/socket';
import { useSnackbar } from 'notistack';
import { SnackbarProvider } from 'notistack';
import React, {
  useState,
  useRef,
  useContext,
  Redirect,
  useEffect,
} from 'react';
const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV

export default function CustomToolbar(props) {
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const handleClose = () => {
    setSubmitStatus(false);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleBilled = (selectedRows) => {
    return async (event) => {
      let s = selectedRows.data.map((row, k) => row.dataIndex);
      let id = [];
      s.forEach((e) => {
        id.push(props.data[e]._id);
        console.log(props.data[e]._id);
      });
      let idss = { ids: id };

      setSubmitLoad(true);
      try {
        var res = await axios.put(API_ENDPOINT + '/billing/markBilled', idss);

        var status = res.status;

        console.log('status' + status);
        if (status === 201) {
          socket.emit('markBilled');
          console.log('status == 201');
          setSubmitSucess(true);
          setSubmitResponse('mark as billed sucessfully!');
        }
      } catch {
        console.log('error');
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }

      console.log('submit');
      console.log(idss);
      setSubmitStatus(true);
      setSubmitLoad(false);
    };
  };

  const handleFree = (selectedRows) => {
    return async (event) => {
      let s = selectedRows.data.map((row, k) => row.dataIndex);
      let id = [];

      s.forEach((e) => {
        id.push(props.data[e]._id);
        console.log(props.data[e]._id);
      });
      let idss = { ids: id };

      setSubmitLoad(true);
      try {
        var res = await axios.put(API_ENDPOINT + '/billing/markFree', idss);

        var status = res.status;

        console.log('status' + status);
        if (status === 201) {
          socket.emit('markFree');
          console.log('status == 201');
          setSubmitSucess(true);
          setSubmitResponse('mark as Free sucessfully!');
        }
      } catch {
        console.log('error');
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }

      console.log('submit');
      console.log(idss);
      setSubmitStatus(true);
      setSubmitLoad(false);
    };
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: 1 }}
          open={submitStatus}
          onClick={handleClose}
        >
          {submitSucess ? (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              {submitResponse}
            </Alert>
          ) : (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              severity="error"
            >
              {submitResponse}
            </Alert>
          )}
        </Backdrop>
      </Grid>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        <Button onClick={handleBilled(props.selectedRows)}>
          Mark Order(s) as Billed
        </Button>
        {/* <br></br>
        <Button onClick={handleFree(props.selectedRows)}>
          Mark Order(s) as Free
        </Button> */}
      </ButtonGroup>
    </div>
  );
}
