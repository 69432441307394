import axios from "axios";
import React, {useState, useEffect} from "react";
import LetterQuadrant from "./LetterQuadrant";
import { Grid } from "@mui/material";
import PropagateLoader from "react-spinners/PropagateLoader";
import Backdrop from '@mui/material/Backdrop';
const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV

function AssignedLetters (props) {
    const [getLoad, setGetLoad] = useState(true);
    const [data, setData] = useState([]);
    const [cartridgesReceived, setCartridgesReceived] = useState(false);
    const [cartridges, setCartridges] = useState([
        {
            cartridgeID: "",
            capillaries: ['good', 'good', 'good', 'good'],
            injections: 0,
            letter: 'N/A'
        }
    ]);

    async function getCartridgeData() {
        if (cartridgesReceived === false) {
            try {
                const res =  await axios.get(API_ENDPOINT + "/cartridge/byLetter");
                var cartidgeList = [];
                res.data.forEach((element) => {
                    cartidgeList.push(element);
                })
                setCartridges(cartidgeList);
                setGetLoad(false);
                setCartridgesReceived(true);
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        getCartridgeData();
    }, [cartridgesReceived])

    return (
        <div>
            <Grid container
                direction="column"
                alignItems="center"
                spacing={0}
                margin={0}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: 1 }}
                    open={getLoad}
                >
                    <PropagateLoader/>
                </Backdrop>
            </Grid>
            <div style={{ padding: "8px"}} >
                <h4>Assigned Letters</h4>
            </div>
            <Grid container spacing={2}>
                {cartridges.map((element) => {
                    return (
                        <Grid item xs={6} key={element._id}>
                            <LetterQuadrant element = {element} />
                        </Grid>
                    );
                })}
            </Grid>   
        </div>
    );
};

export default AssignedLetters;