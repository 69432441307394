import * as React from 'react';

export default function ProtectedPage() {
  return (
    <div>
      <h1 style={{ textAlign: 'center', padding: '4em' }}>
        {' '}
        401 Not Authorized
      </h1>
      <h1 style={{ textAlign: 'center', padding: '4em' }}>
        {' '}
        Please sign in to view the contents of this page
      </h1>
    </div>
  );
}
