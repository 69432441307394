import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Typography } from 'reusable/Typography';
import { MasterMixTableHeading } from './MasterMixTableCommponents/MasterMixTableHeading';
import { MasterMixTableRow } from './MasterMixTableCommponents/MasterMixTableRow';

export const MasterMixTable = ({ reactionCount }) => {
  const [curReactionCount, setCurReactionCount] = useState(reactionCount);

  useEffect(() => {
    setCurReactionCount(reactionCount);
  }, [reactionCount]);

  const BDTVol = ((curReactionCount / 4) * 2.25).toFixed(2);
  const BDTBufferVol = ((curReactionCount / 4) * 7).toFixed(2);
  const H2OVol = ((curReactionCount / 4) * 26.75).toFixed(2);
  const masterMixData = [
    {
      name: 'Big Dye Terminator',
      vol: BDTVol,
    },
    {
      name: 'Big Dye Terminator Buffer',
      vol: BDTBufferVol,
    },
    {
      name: 'Nuclease-free water',
      vol: H2OVol,
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12} sx={{ pl: 2 }}>
        <Typography variant="h2" sx={{ textAlign: 'left' }}>
          Master Mix Table
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <table
          style={{
            position: 'relative',
            width: 'calc(100% - 80px)',
            left: 40,
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <MasterMixTableHeading />
          <tbody>
            {masterMixData.map((data, i) => (
              <MasterMixTableRow
                key={i}
                name={data.name}
                reactionCount={curReactionCount}
                setReactionCount={setCurReactionCount}
                changed={curReactionCount !== reactionCount}
                resetReactionCount={() => setCurReactionCount(reactionCount)}
                volume={data.vol}
              />
            ))}
          </tbody>
        </table>
      </Grid>
      <Grid item sx={{ width: '100%', height: 60 }} />
    </Grid>
  );
};
