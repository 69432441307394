import { useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useSampleMapApi } from '../../hooks/useSampleMapApi';
import { Backdrop, Box, Grid } from '@mui/material';
import { SampleMapHeading } from './components/SampleMapHeading';
import { Map } from './components/Map';
import { ControlTable } from './components/ControlTable';
import { PrimerTable } from './components/PrimerTable';
import { MasterMixTable } from './components/MasterMixTable';
import { ReactionTable } from './components/ReactionTable';
import { PropagateLoader } from 'react-spinners';
import { Notes } from './components/Notes';

const SampleMap = () => {
  // hooks
  const { sampleMapId } = useParams();
  const { setMapData, updateSampleMap } = useSampleMapApi();

  // states
  const [samples, setSamples] = useState([]);
  const [primers, setPrimers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [instrument, setInstrument] = useState(undefined);
  const [cartridgeId, setCartridgeId] = useState('');
  const [cartridge, setCartridge] = useState({});
  const [block, setBlock] = useState('');
  const [orderCode, setOrderCode] = useState('');
  const [colorMap, setColorMap] = useState({});
  const [usedSections, setUsedSections] = useState([]);
  const [notes, setNotes] = useState([]);

  const [loading, setLoading] = useState(true);

  const { innerWidth: pageWidth, innerHeight: pageHeight } = window;

  useEffect(() => {
    // Load data
    (async () => {
      await setMapData(
        sampleMapId,
        setSamples,
        setPrimers,
        setOrders,
        setCartridgeId,
        setOrderCode,
        setColorMap,
        setNotes,
        setInstrument,
        setBlock
      );
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    // update the used sections based on the samples
    const newUsedSection = Array(20).fill(false);
    samples.forEach(
      (sample) => (newUsedSection[Math.floor(sample.well / 4)] = true)
    );
    setUsedSections(newUsedSection);
  }, [samples]);

  const [scroll, setScroll] = useState(0);
  const scrollContainerRef = useRef(null);

  // to calculate scroll for smooth shadow transitions
  const handleScroll = () => {
    if (scrollContainerRef.current) {
      let scrollTop = scrollContainerRef.current.scrollTop;
      let newScroll = Math.min(scrollTop, 255);
      setScroll(newScroll);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
      return () => scrollContainer.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <>
      {pageWidth > 1600 ? (
        <Grid
          container
          sx={{ pl: 5, pr: 5, pb: 5, height: pageHeight, overflow: 'hidden' }}
          spacing={2}
        >
          <Grid item xs={12}>
            <SampleMapHeading
              orderCode={orderCode}
              sampleMapId={sampleMapId}
              instrument={instrument}
              setInstrument={setInstrument}
              cartridgeId={cartridgeId}
              setCartridgeId={setCartridgeId}
              cartridge={cartridge}
              setCartridge={setCartridge}
              block={block}
              setBlock={setBlock}
              samples={samples}
              setSamples={setSamples}
              primers={primers}
            />
          </Grid>
          <Grid item xs={8} container sx={{ height: '100%' }}>
            <Box
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
              }}
            >
              <Map
                samples={samples}
                setSamples={setSamples}
                cartridge={cartridge}
                usedSections={usedSections}
                scroll={scroll}
              />
              <Box
                sx={{
                  height: 'auto',
                  overflowY: 'scroll',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                }}
                ref={scrollContainerRef}
              >
                <Notes notes={notes} />
                <ReactionTable samples={samples} setSamples={setSamples} />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              height: '100%',
              overflowY: 'scroll',
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
            }}
          >
            <PrimerTable primers={primers} setPrimers={setPrimers} />
            <ControlTable
              samples={samples}
              setSamples={setSamples}
              sampleMapId={sampleMapId}
            />
            <MasterMixTable reactionCount={samples.length} />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} sx={{ pl: 5, pr: 5, pb: 5 }}>
          <Grid item xs={12}>
            <SampleMapHeading
              orderCode={orderCode}
              sampleMapId={sampleMapId}
              instrument={instrument}
              setInstrument={setInstrument}
              cartridgeId={cartridgeId}
              setCartridgeId={setCartridgeId}
              cartridge={cartridge}
              setCartridge={setCartridge}
              block={block}
              setBlock={setBlock}
              samples={samples}
              setSamples={setSamples}
              primers={primers}
            />
          </Grid>
          <Grid item xs={12}>
            <Map
              samples={samples}
              setSamples={setSamples}
              cartridge={cartridge}
              usedSections={usedSections}
              scroll={scroll}
            />
          </Grid>
          <Grid item xs={12}>
            <Notes notes={notes} />
          </Grid>
          <Grid item xs={12} md={7}>
            <ControlTable
              samples={samples}
              setSamples={setSamples}
              sampleMapId={sampleMapId}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <MasterMixTable reactionCount={samples.length} />
          </Grid>
          <Grid item xs={12} md={7}>
            <ReactionTable samples={samples} setSamples={setSamples} />
          </Grid>
          <Grid item xs={12} md={5}>
            <PrimerTable primers={primers} setPrimers={setPrimers} />
          </Grid>
        </Grid>
      )}
      <Backdrop sx={{ color: '#FFF', zIndex: 100 }} open={loading}>
        <PropagateLoader color="#FFFFFF" />
      </Backdrop>
    </>
  );
};

export default SampleMap;
