import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import numToWell from '../../../utils/numToWell';
import { Button } from 'reusable/Button';

const ErrorFormPopup = (props) => {
  const open = props.open;
  const samples = props.samples ? props.samples : [];
  const primerList = props.primerList ? props.primerList : [];
  const primerData = props.primerData ? props.primerData : [];
  const handler = props.handler;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handler(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Form errors'}</DialogTitle>
        <Alert severity="warning">
          Your form has errors, please fix them before submission!
        </Alert>
        <DialogContent>
          <ul>
            {samples.map((well, index) =>
              well != undefined ? (
                <li key={index}>
                  <strong>Well: {numToWell(index)}</strong>
                  <ul>
                    {well != undefined ? (
                      Object.keys(well).map((key, i) => (
                        <li key={key + i}>{well[key]}</li>
                      ))
                    ) : (
                      <></>
                    )}
                  </ul>
                </li>
              ) : (
                <></>
              )
            )}
            {primerList.map((primer, index) =>
              primer != undefined ? (
                <li>
                  <strong>
                    Primer:{' '}
                    {primerData[index] == undefined
                      ? ''
                      : primerData[index].primerName}
                  </strong>
                  <ul>
                    {primer != undefined ? (
                      Object.keys(primer).map((key, i) => (
                        <li>{primer[key]}</li>
                      ))
                    ) : (
                      <></>
                    )}
                  </ul>
                </li>
              ) : (
                <></>
              )
            )}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handler(false)} color="primary" autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ErrorFormPopup;
