import axios from 'axios';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

export const downloadExcel = (fileNo) => {
  return axios({
    url: API_ENDPOINT + '/upload/download',
    method: 'GET',
    responseType: 'blob', // important
    params: {
      fileType: fileNo,
    },
    onDownloadProgress: (progressEvent) => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      ); // you can use this to show user percentage of file downloaded
    },
  });
};
