import { Button, TextField, Divider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import UserAutoComplete from './UsersDropDown'; 

import axios from 'axios';
const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

const EventForm = ({ chosenEvent, setDisplayModal }) => { 
  const [title, setTitle] = useState(chosenEvent.title);
  const [startDateTime, setStartDateTime] = useState(chosenEvent.start);
  const [endDateTime, setEndDateTime] = useState(chosenEvent.end); 
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [eventOrderCode, setEventOrderCode] = useState(); 
  const [eventStage, setEventStage] = useState(); 
  const [eventDropLocation, setEventDropLocation] = useState(); 
  const [eventNoOfSamples, setEventNoOfSamples] = useState(); 
  const [usersForCalendar, setUsersForCalendar] = useState(); 
  const [currentUsersArr, setCurrentUsersArr] = useState([]); 
  const [loadingUsers, setLoadingUsers] = useState(true);

  useEffect(() => {
    fetchOrdersForCalendar(); 
    fetchUsersForCalendar(); 
  },[]); 

  const fetchOrdersForCalendar = async () => {
    const res = await axios.get(API_ENDPOINT + '/order/ordersForCalendar'); 
    if (res.status === 200) {
        setLoadingOrders(false); 
        // Add this section to retrieve the current event's orderCode and stage
        for (let i = 0; i < res.data.length; ++i) {
          if (res.data[i]._id === chosenEvent.orderId) {
            setEventOrderCode(res.data[i].orderCode); 
            setEventStage(res.data[i].stage); 
            setEventDropLocation(res.data[i].dropLocation ? res.data[i].dropLocation : "N/A"); 
            setEventNoOfSamples(res.data[i].noOfSamples); 
            break; 
          }
        }
    } else {
        console.log('Error in getting Orders for calendar...')
    }
  }; 

  const fetchUsersForCalendar = async () => {
    const res = await axios.get(API_ENDPOINT + '/user/all'); 
    let currentUsers = []; 
    if (res.status === 200) {
        // Add this section to retrieve the current technicicans information
        for (let i = 0; i < chosenEvent.receivingUserId.length; ++i) {
          for (let j = 0; j < res.data.length; ++j) {
            if (chosenEvent.receivingUserId[i] === res.data[j]._id) {
              currentUsers.push(res.data[j]); 
              break; 
            }
          }
        }
        setCurrentUsersArr(currentUsers); 
        setUsersForCalendar(res.data); 
        setLoadingUsers(false);
    } else {
        console.log('Error in getting Users for calendar...')
    }
  }; 

  const handleCancel = async (e) => {
    e.preventDefault();

    let isUsersChange = false; 
    let finalDescription = "Order: " + eventOrderCode + "\nSample Count: " + eventNoOfSamples + "\nDropLocation: " + eventDropLocation; 

    const event = {
      eventUid: chosenEvent.uid, 
      orderId: chosenEvent.orderId,
      receivingUserId: chosenEvent.receivingUserId,
      start: startDateTime,
      end: endDateTime,
      title: title,
      stage: chosenEvent.stage, 
      description: finalDescription, 
      changeUsers: isUsersChange, 
      newUsers: null, 
      sequence: chosenEvent.sequence
    };
    
    const res = await axios.patch(
      API_ENDPOINT + `/calendar/cancel/${chosenEvent._id}`, 
      event
    );
    if (res.status === 200) {
      alert('Cancel Event Successfully!');
      window.location.reload();  
    } else {
      alert('something went wrong!');
    }
  }

  const handleDelete = async (e) => {
    e.preventDefault(); 

    let eventId = chosenEvent._id;  

    const res = await axios.delete(
      API_ENDPOINT + `/calendar/delete/${chosenEvent._id}`
    ); 
    if (res.status === 200) {
      alert('Delete Event Successfully!'); 
      setDisplayModal(false);
      setSubmitLoadUsers(true); 
    } else {
      alert('something went wrong!');
      setSubmitLoadUsers(false); 
    }
  }

  const [openUsers, setOpenUsers] = React.useState(false);
  const [submitLoadUsers, setSubmitLoadUsers] = useState(false);
  const loadingUsersDropdown = openUsers && usersForCalendar.length === 0; 
  const [selectedUsers, setSelectedUsers] = useState([]);
  const formik = useFormik({
    initialValues: {
      users: []
    },
    onSubmit: async (values) => {
      // Determine if Users/Technicians are being changed
      let isUsersChange = false; 
      if (selectedUsers.length > 0) {
        isUsersChange = true; 
      }

      let finalDescription = "Order: " + eventOrderCode + "\nSample Count: " + eventNoOfSamples + "\nDropLocation: " + eventDropLocation + "\nWork format: In person, at EBB, Room B160."; 

      const event = {
        eventUid: chosenEvent.uid, 
        orderId: chosenEvent.orderId,
        receivingUserId: chosenEvent.receivingUserId,
        start: startDateTime,
        end: endDateTime,
        title: title,
        stage: chosenEvent.stage, 
        description: finalDescription, 
        changeUsers: isUsersChange, 
        newUsers: selectedUsers, 
        sequence: chosenEvent.sequence
      };
      const res = await axios.patch(
        API_ENDPOINT + `/calendar/${chosenEvent._id}`,
        event
      );
      if (res.status === 200) {
        alert('Update Successfully!');
        setDisplayModal(false);
        setSubmitLoadUsers(true); 
      } else {
        alert('something went wrong!');
        setSubmitLoadUsers(false); 
      }
    }
  });

  const handleChangeUsers = (event, newValues) => {
    formik.setFieldValue('users', newValues);
    setSelectedUsers(newValues); 
  }; 


  if (loadingOrders || loadingUsers) {
    return (
        <h2>Loading Data for Calendar ...</h2>
    )
  }
  
  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <h2>{title}</h2>
      <TextField
        label="Event Name"
        onChange={(e) => setTitle(e.target.value)}
        required
        variant="outlined"
        color="secondary"
        type="Title"
        sx={{ mb: 3 }}
        fullWidth
        value={title}
      />
      <Divider component="li" style={{marginBottom: 15, borderBottomWidth: 3, borderBottomColor: 'gray'}}/>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DateTimePicker
          label="Start"
          value={startDateTime}
          onChange={(newValue) => setStartDateTime(newValue)}
          renderInput={(params) => <TextField {...params} sx={{ mb: 3 }} />}
        />
        <DateTimePicker
          label="End"
          value={endDateTime}
          onChange={(newValue) => setEndDateTime(newValue)}
          renderInput={(params) => <TextField {...params} sx={{ mb: 3 }} />}
        />
        <h6>After changing time, click "Update" button below to update.</h6>
      </LocalizationProvider>
      <Divider component="li" style={{marginBottom: 15, borderBottomWidth: 3, borderBottomColor: 'gray'}}/>
      <h6>Order & Stage being assigned:</h6>
      <div style={{marginBottom: 15}}>
        {eventOrderCode + " - Stage: " + eventStage}
      </div>
      <h6>Current assigned technicians:</h6>
      <div style={{marginBottom: 15}}>
        {currentUsersArr.length > 0 ? currentUsersArr.map((eachUser) => {
          return <div> {eachUser.firstName + " " + eachUser.lastName} </div>
        }) : "None"}
      </div>
      <Divider component="li" style={{marginBottom: 15, borderBottomWidth: 3, borderBottomColor: 'gray'}}/>
      <div style={{marginBottom: 15}}>
          <h5>Change the technicians responsible for this task</h5>
          <h6>First: Cancel this event before choosing new technicians, page will be reloaded:</h6>
          <Button variant="contained" type="submit" onClick={handleCancel} style={{marginBottom: 10, backgroundColor: "#008AFC", color: 'white'}}>
            Cancel This Event
          </Button>
          <h6>Second: Assign to new technicians, then click "Update" button below:</h6>
          <UserAutoComplete
            value={formik.values.users}
            onChange={(event, value) => handleChangeUsers(event, value)}
            open={openUsers}
            setOpen={setOpenUsers}  // This prop assumes that the OrderAutocomplete component accepts this prop to handle open state changes
            submitLoad={submitLoadUsers}
            options={usersForCalendar}
            loading={loadingUsersDropdown}
            error={formik.touched.users && Boolean(formik.errors.users)}
            helperText={formik.touched.users && formik.errors.users}
          />
      </div>
      <Button variant="contained" type="submit" style={{marginBottom: 15, backgroundColor: "#008AFC", color: 'white'}}>
        Update
      </Button>
      <Divider component="li" style={{marginBottom: 15, borderBottomWidth: 3, borderBottomColor: 'gray'}}/>
      <div style={{marginBottom: 15}}>
          <h5>Delete this event from the Calendar</h5>
          <h6>First: Cancel this event using the "Cancel This Event" button above, page will be reloaded.</h6>
          <h6>Second: Click "Delete This Event" button below to remove this event from the calendar:</h6>
          <Button variant="contained" type="submit" onClick={handleDelete} style={{marginBottom: 10, backgroundColor: "#008AFC", color: 'white'}}>
            Delete This Event
          </Button>
      </div>
    </form>
  );
};

export default EventForm;