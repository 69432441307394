import React, { useState, useEffect, useLayoutEffect } from 'react';
import headerImage from '../../images/homepage-header.png';
import img00 from '../../images/homepage-img21.png';
import img01 from '../../images/aboutuspage-img02.png';
import img10 from '../../images/aboutuspage-img03.png';
import img11 from '../../images/aboutuspage-img04.png';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import SimpleImageSlider from "react-simple-image-slider";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const images = [
  { url: img00 },
  { url: img01 },
  { url: img10 },
  { url: img11 },
];

const blueTextColor = "rgb(0, 48, 87)"
const HomePage = () => {
  const [width, height] = useWindowSize();

  const phoneWidth = 985;
  const phoneSize = width < phoneWidth;

  return (
    <>
    <div style={{marginLeft: 25, marginRight: 25}}>
        <div style={{color: blueTextColor, fontSize: 40, textAlign: 'center',marginTop: 60, marginBottom: 30, fontWeight: 'bolder'}}>
          ABOUT US
      </div>
      <div style={{alignItems: 'center', fontSize: 15, color: blueTextColor, textAlign: 'center', minWidth: 150, maxWidth: 750, marginLeft: 'auto', marginRight: 'auto'}}>
          The Sanger Sequencing Initiative at the 
          Molecular Evolution Core has evolved from a  
          staff-driven to a student-run, student-enabled  
          project over the last several years. The program 
          gives undergraduate students an opportunity to 
          experience a combination of science and business-oriented 
          goals common in biotech companies these days. It also helps 
          them to develop valuable bench skills and a results-oriented 
          vision in order to propel them into successful research careers
          in the future.
          <br />
          <br />
          This program was built from the ground up by students under the 
          supervision and guidance of Molecular Evolution laboratory personnel. 
          The sequencing results match or exceed in quality the Sanger sequencing 
          services provided by big-name competitors by providing high-quality data and 
          one-on-one customer service. The student team managing the project grew from 1 student
           (Nicole Diaz, a first-year BME major) in 2019 to a team of 7 students in 2022.
            Six drop-off locations have been established around Georgia Tech's campus.
          <br />
          <br />
          The Molecular Evolution Core Sanger Sequencing project serves as a problem-based 
          learning environment and skill incubator that promotes workflow development, interpersonal 
          communication, targeted execution, negotiation, and customer engagement. Interdisciplinary 
          students have recently come on board to pilot the expansion of the project with newly developed 
          automation algorithms.
          <br />
          <br />
          We are hiring! If you would like to join the team, please send Dr. Gregory Hair an email with your cover letter and resume. 
          We will be accepting Federal Work Study and Work for Credit applications for the academic semesters and will also be 
          offering a limited summer internship opportunities. We look forward to hearing from you!
          <br />
          <br />
        </div>
       </div>
       <Grid container
        paddingBottom={3}>
               <div style={{alignItems: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
               <SimpleImageSlider
                 width={phoneSize ? width : (width * 0.7)}
                 height={phoneSize ? width * 0.6 : (width * 0.4)}
                 images={images}
                 showBullets={true}
                 showNavs={true}
               />
               </div>
        </Grid>
    </>
  );
};

export default HomePage;
