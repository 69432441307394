import labImage from '../../../images/homepage-img10.jpeg';
import Grid from '@material-ui/core/Grid';
import FaqButton from './FaqButton';
import React from 'react';
import './FaqComponent.css';
import { getData } from './FaqData';

const FaqOverview = ({ setFaqCategory, visibility }) => {
  const visibilityClass = visibility ? 'faq__overview--hidden' : 'faq__overview--shown';

  const data = getData(null);

  return (
    <div className={`faq__overview ${visibilityClass}`}>
      <img src={labImage} alt="Lab technician operating equipment"
           width={'100%'} />
      <div className="faq__grid-container">
        <Grid
          container
          spacing={3}
          justifyContent="center"
        >
          {data.map((category, index) => (
            <Grid item md key={index}>
              <FaqButton
                onClick={() => setFaqCategory(index)}
              >
                {category.title}
              </FaqButton>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default FaqOverview;