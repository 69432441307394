import React, {useState, useRef} from "react";
import { Formik, useFormik, yupToFormErrors, Form, useField } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';

import * as Yup from 'yup';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import PropagateLoader from "react-spinners/PropagateLoader";
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import RotateLoader from "react-spinners/RotateLoader";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import axios from "axios";

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV

function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
}

const Item = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
    float: 'left'
}));

const TextInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
    <>
        <label htmlFor={props.id || props.name}>{label}</label>
        <input className="text-input" {...field} {...props} />
        {meta.touched && meta.error ? (
            <div className="formError">{meta.error}</div>
        ) : null}
        </>
    );
};

export default function PopupUpdate(props) {
    const [getLoad, setGetLoad] = useState(false);
    const [submitLoad, setSubmitLoad] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [submitResponse, setSubmitResponse] = useState(null);
    const [submitSucess, setSubmitSucess] = useState(true);
    const [primerExists, setPrimerExists] = useState(false);
    const initialData = props.primer;

    const handleClose = () => {
        setSubmitStatus(false);
    };

    const validationSchema= Yup.object({
        name: Yup.string().required('Required'),
        primerID: Yup.number("Must be a number").required('Required'),
        seqData: Yup.string()
        .max(20,"No more than one character")
        .min(1, "Must be at least one character")
        .matches(/^[cCgGtTaA]+$/, "Only C, G, T, or A are allowed")
        .required('Required'),
    });  

    return (
        <Grid container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}> 
            <Backdrop
                sx={{ color: '#fff', zIndex: 1 }}
                open={getLoad}
            >
                <PropagateLoader/>
            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: 1 }}
                open={submitStatus}
                onClick={handleClose}
            >
                {submitSucess ? (
                <Alert onClose={() => {setSubmitStatus(false); window.location.reload(false);}} icon={<CheckIcon fontSize="inherit" />} severity="success">
                {submitResponse}
                </Alert>
                ) : (
                <Alert onClose={() => {setSubmitStatus(false)}} severity="error">
                {submitResponse}
                </Alert>
                )}
            </Backdrop>               
            <Formik 
            initialValues={initialData} 
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={ async (values) => {
                setSubmitLoad(true);
                try {
                    var res = await axios.put(API_ENDPOINT + '/labPrimer/edit', values);
                    var status = res.status
                    await sleep(1e3);
                    if (status === 202) {
                        setSubmitSucess(false);
                        setSubmitResponse("Lab Primer with this ID already exists. Please try another.");
                    }
                    if (status === 201) {
                        setSubmitSucess(true);
                        setSubmitResponse("Primer sucessfully updated!")
                    }
                } catch {
                    setSubmitSucess(false);
                    setSubmitResponse("Unsucessful! Please try again later or contact adminsitrator if problem persists")
                }
                setSubmitStatus(true);
                setSubmitLoad(false);
            }}>
                {(props) => (
                    <Form>
                        <Grid container spacing={0} width={360}>
                            <Grid item xs={12} >
                                <Item>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        style = {{width: 280}}
                                        id="name"
                                        name="name"
                                        value={props.values.name}
                                        onChange={props.handleChange}
                                        label="Name"
                                        type="dialogText"
                                        variant="standard"
                                        error={props.touched.name && Boolean(props.errors.name)}
                                        helperText={props.touched.name && props.errors.name}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={12} >
                                <Item>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        style = {{width: 280}}
                                        id="primerID"
                                        name="primerID"
                                        value={props.values.primerID}
                                        onChange={props.handleChange}
                                        label="Primer ID"
                                        type="dialogText"
                                        variant="standard"
                                        error={props.touched.primerID && Boolean(props.errors.primerID)}
                                        helperText={props.touched.primerID && props.errors.primerID}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={12} >
                                <Item>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        style = {{width: 280}}
                                        id="seqData"
                                        name="seqData"
                                        value={props.values.seqData}
                                        onChange={props.handleChange}
                                        label="Seq. Data"
                                        type="dialogText"
                                        variant="standard"
                                        error={props.touched.seqData && Boolean(props.errors.seqData)}
                                        helperText={props.touched.seqData && props.errors.seqData}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Item>
                                    <Box>
                                        <LoadingButton
                                            size="large"
                                            classes="profileSubmit"
                                            type="submit"
                                            variant="outlined"
                                            loading={submitLoad}
                                            loadingIndicator={<RotateLoader cssOverride={{left: "100%"}} size={7} margin={-15}/>}
                                            >
                                                Submit
                                        </LoadingButton>
                                    </Box>
                                </Item>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    );
}