import { Button, IconButton } from '@mui/material';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import MUIDataTable from 'mui-datatables';
import CheckIcon from '@mui/icons-material/Check';
import { socket } from 'context/socket';
import { useSnackbar } from 'notistack';
import EditButtonPopup from './EditButtonPopup';
import DeleteConfirmation from 'reusable/DeleteConfirmation';

import AuthContext from 'utils/auth/auth';
import React, { useState, useContext, useEffect } from 'react';
const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

async function getInitialData({
  setGetLoad,
  setData,
  setPrimersReceived,
  primersReceived,
  user,
}) {
  if (primersReceived === false) {
    try {
      const res = await axios.get(API_ENDPOINT + '/labPrimer/all');
      let dataToBeUsed = [];
      res.data.forEach((element) => {
        let oneRow = {};
        Object.keys(element).forEach((el) => {
          if (el == '__v') {
          } else {
            oneRow = { ...oneRow, [el]: element[el] };
          }
        });
        dataToBeUsed = [...dataToBeUsed, oneRow];
      });
      setData(dataToBeUsed);
      setGetLoad(false);
      setPrimersReceived(true);
    } catch (error) {
      console.log(error);
    }
  } else {
    //DO Nothing
  }
}

function CurrentPrimersTable(props) {
  const [getLoad, setGetLoad] = useState(true);
  const [data, setData] = useState([]);
  const [primersReceived, setPrimersReceived] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [currPrimer, setCurrPrimer] = useState(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const user = useContext(AuthContext);

  // Handle the displaying of the modal
  const showDeleteModal = (currPrimer) => {
    setCurrPrimer(currPrimer);
    setDeleteMessage(
      `Are you sure you want to delete ${currPrimer.name}'s record?`
    );
    setDisplayConfirmationModal(true);
  };
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleClose = () => {
    setSubmitStatus(false);
  };
  const handleDelete = (thisPrimer) => {
    return async (event) => {
      setSubmitLoad(true);
      try {
        var res = await axios.delete(API_ENDPOINT + '/labPrimer/delete', {
          data: thisPrimer,
        });
        var status = res.status;
        if (status === 201) {
          socket.emit(
            'deleteprimer',
            currPrimer.name,
            user.firstName,
            user.lastName
          );
          setSubmitSucess(true);
          setSubmitResponse('Delete sucessfully!');
        }
      } catch {
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      setDisplayConfirmationModal(false);
      setSubmitStatus(true);
      setSubmitLoad(false);
    };
  };

  useEffect(() => {
    getInitialData({
      setGetLoad,
      setData,
      setPrimersReceived,
      primersReceived,
      user,
    }).catch(console.error);
  }, [primersReceived]);

  const columns = [
    {
      label: 'Name',
      name: 'name',
      options: {
        filter: false,
      },
    },
    {
      label: 'Sequencing Data',
      name: 'seqData',
      options: {
        filter: false,
      },
    },
    {
      label: 'Primer ID',
      name: 'primerID',
      options: {
        filter: false,
      },
    },
    {
      label: 'Expand',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (value) => {
          return (
            <IconButton>{/* <PIsPopup currentPI={data[value]}/> */}</IconButton>
          );
        },
        display: 'excluded',
      },
    },
    {
      label: 'Operation',
      name: 'operation',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <>
              <EditButtonPopup primer={data[dataIndex]} />
              <br></br>
              <Button
                variant="outlined"
                onClick={() => showDeleteModal(data[dataIndex])}
                size="small"
                disabled={false}
              >
                {' '}
                Delete
              </Button>
            </>
          );
        },
        // display: 'excluded'
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'multiselect',
    print: false,
    viewColumns: false,
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'primary',
        variant: 'outlined',
        className: 'testClass123',
      };
    },
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
          <PropagateLoader />
        </Backdrop>
        <Backdrop
          sx={{ color: '#fff', zIndex: 1 }}
          open={submitStatus}
          onClick={handleClose}
        >
          {submitSucess ? (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
                window.location.reload(false);
              }}
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              {submitResponse}
            </Alert>
          ) : (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              severity="error"
            >
              {submitResponse}
            </Alert>
          )}
        </Backdrop>
      </Grid>
      <MUIDataTable
        title={'Lab Primers'}
        data={data.map((item) => {
          return [item.name, item.seqData, item.primerID];
        })}
        columns={columns}
        options={options}
      />
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleDelete}
        hideModal={hideConfirmationModal}
        id={currPrimer}
        message={deleteMessage}
      />
    </div>
  );
}

export default CurrentPrimersTable;

