import { useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { StepsComponent } from './InfoTabs/StepsComponent';
import { GuidelinesComponent } from './InfoTabs/GuidelinesComponent';
import { PackagingComponent } from './InfoTabs/PackagingComponent';
import { Button } from 'reusable/Button';
import { IconButton } from 'reusable/IconButton';

export const SangerInfoTabs = [
  {
    title: '5 steps',
    desc: <StepsComponent />,
  },
  {
    title: 'Guidelines',
    desc: <GuidelinesComponent />,
  },
  {
    title: 'Packaging',
    desc: <PackagingComponent />,
  },
];

const InfoDropdown = ({ selected, setSelected }) => {
  const vis = selected !== -1;
  const dropdownInfo = useRef('');
  if (selected !== -1) dropdownInfo.current = SangerInfoTabs[selected].desc;

  return (
    <Collapse in={vis} sx={{ width: '100%' }}>
      <Box
        sx={{
          backgroundColor: '#D9D9D95E',
          padding: '10px 40px',
          borderRadius: 2,
        }}
      >
        {dropdownInfo.current}
        <Box style={{ display: 'flex' }}>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <IconButton
              disabled={selected === 0}
              onClick={() => setSelected(selected - 1)}
            >
              <ChevronLeftIcon style={{ fontSize: 40, padding: 0 }} />
            </IconButton>
            <IconButton
              disabled={selected === SangerInfoTabs.length - 1}
              onClick={() => setSelected(selected + 1)}
            >
              <ChevronRightIcon style={{ fontSize: 40, padding: 0 }} />
            </IconButton>
          </Box>
          <Button
            variant="text"
            startIcon={<CloseIcon />}
            onClick={() => setSelected(-1)}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Collapse>
  );
};

export const SangerInfoDropdown = () => {
  const [selected, setSelected] = useState(-1);

  return (
    <Grid container>
      {SangerInfoTabs.map((tab, idx) => (
        <Grid item xs={4} key={idx}>
          <Button
            variant={idx === selected ? 'accent' : 'contained'}
            color="primary"
            size="large"
            onClick={() => setSelected(idx)}
            disableElevation
          >
            {tab.title}
          </Button>
        </Grid>
      ))}
      <InfoDropdown selected={selected} setSelected={setSelected} />
    </Grid>
  );
};
