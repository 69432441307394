import { Grid } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { deleteSample } from 'utils/api/Samples';
import { EmptyTableRow } from 'reusable/EmptyTableRow';
import { Typography } from 'reusable/Typography';
import { ReactionTableHeading } from './ReactionTableComponents/ReactionTableHeading';
import { ReactionTableRow } from './ReactionTableComponents/ReactionTableRow';

export const ReactionTable = ({ samples, setSamples }) => {
  const widthRef = useRef(null);
  const [tableWidth, setTableWidth] = useState(0);

  const updateSample = (i) => (updatedSample) => {
    setSamples((samples) => {
      const newSamples = [...samples];
      newSamples[i] = { ...newSamples[i], ...updatedSample };
      console.log(newSamples[i]);
      return newSamples;
    });
  };

  const moveSample = (startIdx, endIdx) => {
    setSamples((samples) => {
      const newSamples = [...samples];
      const movedSample = newSamples[startIdx];
      newSamples.splice(startIdx, 1);
      newSamples.splice(endIdx, 0, movedSample);
      return newSamples;
    });
  };

  const removeSample = (index) => async () => {
    if (samples[index].type !== 'control') {
      console.error('Non-control sample cannot be removed');
      return;
    }
    setSamples((samples) => {
      const newSamples = [...samples];
      newSamples.splice(index, 1);
      return newSamples;
    });
    await deleteSample(samples[index]._id);
  };

  useEffect(() => {
    const updateMapWidth = () => {
      if (widthRef.current) {
        setTableWidth(widthRef.current.offsetWidth);
      }
    };

    // Initial call to set the width
    updateMapWidth();

    // Add resize event listener
    window.addEventListener('resize', updateMapWidth);
    return () => {
      window.removeEventListener('resize', updateMapWidth);
    };
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} sx={{ pl: 2 }}>
        <Typography variant="h2" sx={{ textAlign: 'left' }}>
          Reaction Table
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <table
          style={{
            position: 'relative',
            width: tableWidth - 80,
            left: 40,
          }}
        >
          <ReactionTableHeading />
          <tbody>
            {samples.length === 0 ? (
              <EmptyTableRow cols={6} />
            ) : (
              samples.map((sample, i) => (
                <ReactionTableRow
                  key={i}
                  index={i}
                  sample={sample}
                  setSample={updateSample(i)}
                  moveSample={moveSample}
                  removeSample={removeSample(i)}
                  tableWidth={tableWidth}
                />
              ))
            )}
          </tbody>
        </table>
        <div style={{ width: '100%' }} ref={widthRef} />
      </Grid>
      <Grid item xs={12} sx={{ height: 80, width: '100%' }} />
    </Grid>
  );
};
