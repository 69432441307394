import React, { useState, useRef, useContext } from 'react';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import AuthContext from '../../utils/auth/auth';
import CheckIcon from '@mui/icons-material/Check';
import Countdown from 'react-countdown';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export function PopupGridItem(props) {
  return (
    <>
      <Grid container rowSpacing={0} spacing={0}>
        <Grid item xs={6}>
          <h3 className="PopupGridItem">{props.attribute}</h3>
        </Grid>
        <Grid item xs={6}>
          <h6 className="PopupGridItem" style={{ float: 'right' }}>
            {props.data}
          </h6>
        </Grid>
        <Grid item xs={12}>
          <hr
            style={{
              background: 'black',
              height: '2px',
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export function PopupNullClient() {
  return {
    username: '',
    firstName: '',
    lastName: '',
    SUMSworktag: '',
    email: '',
    phoneNumber: '',
    labName: '',
    university: '',
    universityTag: '',
    noOfOrders: '',
    orderTag: '',
  };
}

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

