import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import axios from 'axios';
import TableMUI from '../Inventory/TableMUI';
import Dashboard from '../Inventory/Dashboard/Dashboard';

import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { socket, SocketContext } from '../../context/socket';
import { useSnackbar } from 'notistack';
import { SnackbarProvider } from 'notistack';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

async function getInitialData({
  setGetLoad,
  setProducts,
  setData,
  setOrderReceived,
  ordersReceived,
  products,
  setFilterListCato,
  setFilterList,
}) {
  if (ordersReceived === false) {
    ////console.log('inventory products:' + products);
    ////console.log('inventory products true or false:' + (products.length === 0));

    try {
      const res = await axios.get(API_ENDPOINT + '/inventory');
      let dataToBeUsed = [];
      res.data.forEach((element) => {
        let oneRow = {};
        Object.keys(element).forEach((el) => {
          if (el == '__v') {
          } else {
            ////console.log("key: " + el+": inital Data inside: " + element[el]);
            oneRow = { ...oneRow, [el]: element[el] };
          }
        });
        dataToBeUsed = [...dataToBeUsed, oneRow];
      });

      setProducts(dataToBeUsed);
      //setFilterListCato([]);
      //setFilterList([]);
      setData(dataToBeUsed);
      setGetLoad(false);
      setOrderReceived(true);
    } catch (error) {
      //console.log(error);
    }
  } else {
    //DO Nothing
  }
}

export default function Inventory(props) {
  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  const [getLoad, setGetLoad] = useState(true);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [ordersReceived, setOrderReceived] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [filterListCato, setFilterListCato] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const deliverOrderStatus = (temp) => {
    if (JSON.stringify(temp) == '[]') {
      setFilterList([]);
    } else {
      setFilterList([temp]);
    }
  };

  const CatologFilter = (temp) => {
    if (JSON.stringify(temp) == '[]') {
      setFilterListCato([]);
    } else {
      setFilterListCato([temp]);
    }
  };
  //let othersEdit = '';
  //const [otherEdit, setOtherEdit] = useState()
  useEffect(() => {
    socket.on('updateOthers', (msg) => {
      ////console.log("run");
      setOrderReceived(false);
      enqueueSnackbar(msg, { variant: 'info' });
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelf', () => {
      ////console.log("run");
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateAll', () => {
      ////console.log("run");
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('connect', () => console.log(socket.id));
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on('disconnect', () => console.log('Server disconnected'));
  }, []);

  useEffect(() => {
    getInitialData({
      setGetLoad,
      setProducts,
      setData,
      setOrderReceived,
      //setFilterList,
      //setFilterListCato,
      ordersReceived,
      products,
      enqueueSnackbar,
    }).catch(console.error);
  }, [ordersReceived]);

  const handleCloseSubmit = () => {
    setSubmitStatus(false);
  };

  /** 
  useEffect(() => {
    // declare the async data fetching function
    const fetchData = async () => {
      // get the data from the api
      const res =  await axios.get(API_ENDPOINT + "/inventory" );
      // convert the data to json
      //const json = await response.json();
  
      // set state with the result
      
      ////console.log("inital Data inside" + res.data);
      let dataToBeUsed = [];

      res.data.forEach((element) => {
        let oneRow = {};
    
        Object.keys(element).forEach(el => {
          if (el === "_id" || el == "__v") {
    
          } else {
            ////console.log("key: " + el+": inital Data inside: " + element[el]);
            oneRow = {...oneRow,[el]:element[el]}
    
          }
        })
    
        dataToBeUsed = [...dataToBeUsed,oneRow]
    
      } )
      setProducts(dataToBeUsed);
      setData(dataToBeUsed);
      setGetLoad(false);

    }
  //console.log("run for the first time");
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);;
  }, [])
  //console.log("initalData" + products.length);
  //console.log("initalData" + data.length);
  ////console.log("invengtory page: products: "+products[0].name);
  
  */

  /** 
  
  //getInitialData(setInititalData);
  getInitialData().then(res=> {

    var [initialData,setInititalData] = React.useState(res);

});
  //let initialData = getInitialData()
  initialData.forEach(element => {
    Object.keys(element).forEach(el => {
      //console.log("innnerfunction:" + el + element[el])
    
  });
});
*/
  //const [initialData,setInititalData] = React.useState()
  //getInitialData(setInititalData);
  //const res = await axios.get(API_ENDPOINT + "/inventory" );
  ////console.log("initial data:" +res.value);
  /** 
const { response, loading, error }= useAxios({
  url: API_ENDPOINT + "/inventory" ,
  method: 'GET',
});

const [data, setData] = useState([]);
useEffect(() => {
  if (response !== null) {
      setData(response);
  }
}, [response]);

//console.log("initalData" + data.length);
*/

  /** 
//const [initialData,setInititalData] = React.useState(data);
Object.keys(initialData).forEach(e => {
  //console.log("inventory page initial data:" + initialData[e]);
  Object.keys(initialData[e]).forEach(ele => {
    //console.log("inventory page initial data inside:" + initialData[e][ele]);

  })
});

*/

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  /** 
  const groupBy = (objectArray, property) => {
    return objectArray.reduce(function (acc, obj) {
      let key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };

  /** 
    let arrayProducts = [];
        
        axios.get(API_ENDPOINT + "/inventory").then (res => {
        var newData = res.data;
        
        
        Object.keys(newData).forEach(element => {
          ////console.log("inventory page:" + [element])
          arrayProducts = [...arrayProducts,newData[element]]
          Object.keys(newData[element]).forEach(e => {
            ////console.log("inventory page inner:" + e+newData[element][e])
          
          });
          
        });
        //console.log ("typeof: " + typeof arrayProducts);
        
        //setInititalData(newData);
        //setGetLoad(false);
        
    } ).catch ((error)=> {
        //console.log(error);
    })

  arrayProducts.forEach(element => {
      Object.keys(element).forEach(el => {
        //console.log("innnerfunction:" + el + element[el])
      
    });
  });
  */
  /**
    const[products,setProducts] = useState();
      ////console.log ("typeof: " + typeof products);
    products.forEach(el=> {

        Object.keys(el).forEach(index => {
          //console.log("inventory page inner array:" + index + ": " + el[index] + " " + typeof el[index]);
        
        });

      })
       */

  /** 
    const[products,setProducts] = useState(
     [  {name:1,totalQuantity: 1, manufacturer: "qghappy",location:"EBB",catologNumber: 3, notes:""},
        {name:2,totalQuantity: 1, manufacturer: "qghappy",location:"COB",catologNumber: 3, notes:""},
        {name:3,totalQuantity: 1, manufacturer: "qghappy",location:"COC",catologNumber: 3, notes:""},
        {name:4,totalQuantity: 2, manufacturer: "qghappy",location:"COB",catologNumber: 5, notes:""},
        {name:5,totalQuantity: 2, manufacturer: "qghappy",location:"COC",catologNumber: 5, notes:""},
        {name:6,totalQuantity: 2, manufacturer: "qghappy",location:"COB",catologNumber: 5, notes:""},
        {name:7,totalQuantity: 2, manufacturer: "qghappy",location:"COC",catologNumber: 5, notes:""}]
    );
    */

  // //console.log("invengtory page: products: "+products[0].name);

  //log
  /** 
  const [edited, setEdited] = React.useState([
    {
      day: '2022-07-03',
      orderName: 5,
      people: 'Christine',
      totalQuantity: [0, 7],
    },
    {
      day: '2022-07-03',
      orderName: 5,
      people: 'Christine',
      totalQuantity: [0, 7],
    },
    {
      day: '2022-07-03',
      orderName: 5,
      people: 'Christine',
      totalQuantity: [0, 7],
    },
    {
      day: '2022-07-03',
      orderName: 5,
      people: 'Christine',
      totalQuantity: [0, 7],
    },
    {
      day: '2022-07-04',
      orderName: 5,
      people: 'Christine',
      totalQuantity: [0, 7],
    },
    {
      day: '2022-07-04',
      orderName: 5,
      people: 'Christine',
      totalQuantity: [0, 7],
    },
    {
      day: '2022-07-02',
      orderName: 5,
      people: 'Christine',
      totalQuantity: [0, 7],
    },
    {
      day: '2022-07-02',
      orderName: 5,
      people: 'Christine',
      totalQuantity: [0, 7],
    },
    {
      day: '2022-07-02',
      orderName: 5,
      people: 'Christine',
      totalQuantity: [0, 7],
    },
    {
      day: '2022-07-01',
      orderName: 5,
      people: 'Christine',
      totalQuantity: [0, 7],
    },
    {
      day: '2022-07-01',
      orderName: 5,
      people: 'Christine',
      totalQuantity: [0, 7],
    },
    {
      day: '2022-07-01',
      orderName: 5,
      people: 'Christine',
      totalQuantity: [0, 7],
    },
  ]);
  const [text, setText] = React.useState([]);

  let groupedText = edited.reduce(function (acc, obj) {
    let key = obj['day'];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  let totalGrouped = {};
  Object.keys(groupedText).forEach((dayattri) => {
    let onedayEdited = [];
    groupedText[dayattri].forEach((i) => {
      let thisEdit1 = [
        i['day'],
        `${i['day']}'s edit:`,
        `${i['people']} edited ${i['orderName']}`,
      ];

      Object.keys(i).forEach((key) => {
        if (key == 'totalQuantity') {
          thisEdit1 = [
            ...thisEdit1,
            `The total quantity was changed from ${i[key][0]} to ${i[key][1]}`,
          ];
          ////console.log(`The assignee for this step was changed from ${i[key][0]}  to  ${ i[key][1]}`)
        } else {
        }
      });
      if (thisEdit1.length > 3) {
        //setText([...text,thisEdit]);
        //temp = [...text,thisEdit];
        onedayEdited = [...onedayEdited, thisEdit1];
      }
      //onedayEdited = [...onedayEdited,thisEdit1];
    });
    totalGrouped = { ...totalGrouped, [dayattri]: onedayEdited };
  });
  const ordered1 = Object.keys(totalGrouped)
    .sort((a, b) => {
      if (a > b) {
        return -1;
      }
      if (a < b) {
        return 1;
      }
      return 0;
    })
    .reduce((obj, key) => {
      obj[key] = totalGrouped[key];
      return obj;
    }, {});
  const [groupByDate, setGroupByDate] = React.useState(ordered1);

  const updateProducts = (newProducts) => {
    setProducts(newProducts);
  };
*/
  return (
    <SocketContext.Provider value={socket}>
      <SnackbarProvider maxSnack={3}>
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={0}
          margin={0}
        >
          <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
            <PropagateLoader />
          </Backdrop>

          <Backdrop
            sx={{ color: '#fff', zIndex: 1 }}
            open={submitStatus}
            onClick={handleCloseSubmit}
          >
            {submitSucess ? (
              <Alert
                onClose={() => {
                  setSubmitStatus(false);
                }}
                icon={<CheckIcon fontSize="inherit" />}
                severity="success"
              >
                {submitResponse}
              </Alert>
            ) : (
              <Alert
                onClose={() => {
                  setSubmitStatus(false);
                }}
                severity="error"
              >
                {submitResponse}
              </Alert>
            )}
          </Backdrop>
          <br></br>
          <br></br>

          <Container>
            <Row>
              <Dashboard
                deliverOrderStatus={deliverOrderStatus}
                CatologFilter={CatologFilter}
              />
            </Row>

            <br></br>
            <br></br>

            <Row>
              <TableMUI
                products={products}
                filterList={filterList}
                filterListCato={filterListCato}
                deliverOrderStatus={deliverOrderStatus}
                CatologFilter={CatologFilter}
              />
            </Row>
          </Container>
        </Grid>
      </SnackbarProvider>
    </SocketContext.Provider>
  );
}
