import MUIDataTable from 'mui-datatables';
import SampleListView from 'reusable/List/SampleListView';

import React, { useState } from 'react';
import { ResearcherComponent } from './UploadedOrderComponents/ResearcherComponent';
import { DropLocationComponent } from './UploadedOrderComponents/DropLocationComponent';
import { StatusComponent } from './UploadedOrderComponents/StatusComponent';
import { BillingComponent } from './UploadedOrderComponents/BillingComponent';
import { SubmissionComponent } from './UploadedOrderComponents/SubmissionComponent';
import { DownloadFileComponent } from './UploadedOrderComponents/DownloadFileComponent';

const CurrentOrdersTable = ({ orders: data }) => {
  const [isChecked, setIsChecked] = useState(false);

  let researchers = [];
  data.forEach((item) => {
    if (item.researcherName === undefined) {
    } else {
      if (!researchers.includes(item.researcherName)) {
        researchers = [...researchers, item.researcherName];
      }
    }
  });

  const dropOffLocations = [
    'EBB',
    'Cherryemerson',
    'MoSE',
    'IBB',
    'ES&T',
    'Marcus',
  ];

  // retrieve data for orders from the backend
  // this ensures that even if the location is entered weirdly, we can still filter it out later
  data.forEach((order, index) => {
    if (
      order.dropLocation
        .toLowerCase()
        .replace(/\s+/g, '')
        .replace(/-/g, '')
        .includes(dropOffLocations[0].toLowerCase())
    ) {
      order.dropLocation = 'EBB';
    } else if (
      order.dropLocation
        .toLowerCase()
        .replace(/\s+/g, '')
        .replace(/-/g, '')
        .includes(dropOffLocations[1].toLowerCase())
    ) {
      order.dropLocation = 'Cherry Emerson';
      console.log(order.dropLocation);
    } else if (
      order.dropLocation
        .toLowerCase()
        .replace(/\s+/g, '')
        .replace(/-/g, '')
        .includes(dropOffLocations[2].toLowerCase())
    ) {
      order.dropLocation = 'MoSE';
    } else if (
      order.dropLocation
        .toLowerCase()
        .replace(/\s+/g, '')
        .replace(/-/g, '')
        .includes(dropOffLocations[3].toLowerCase())
    ) {
      order.dropLocation = 'IBB';
    } else if (
      order.dropLocation
        .toLowerCase()
        .replace(/\s+/g, '')
        .replace(/-/g, '')
        .includes(dropOffLocations[4].toLowerCase())
    ) {
      order.dropLocation = 'ES&T';
    } else if (
      order.dropLocation
        .toLowerCase()
        .replace(/\s+/g, '')
        .replace(/-/g, '')
        .includes(dropOffLocations[5].toLowerCase())
    ) {
      order.dropLocation = 'Marcus';
    }
  });

  const columns = [
    {
      label: 'Order Number',
      name: 'orderCode',
      options: {
        filter: false,
      },
    },
    {
      label: 'Researcher',
      name: 'researcher',
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (v) => `Researcher: ${v.map((l) => l.toUpperCase())}`,
          update: (filterList, filterPos, index) => {
            console.log('update');
            console.log(filterList, filterPos, index);
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          logic: (researcher, filters, row) => {
            if (filters.length) return !filters.includes(researcher);
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <ResearcherComponent
              researchers={researchers}
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
            />
          ),
        },
      },
    },
    {
      label: 'Drop-off Location',
      name: 'dropLocation',
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (v) => `Drop-off Location: ${v.map((l) => l.toUpperCase())}`,
          update: (filterList, filterPos, index) => {
            console.log('update');
            console.log(filterList, filterPos, index);
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          logic: (dropLocation, filters, row) => {
            if (filters.length) return !filters.includes(dropLocation);
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <DropLocationComponent
              filterList={filterList}
              index={index}
              column={column}
              onChange={onChange}
            />
          ),
        },
      },
    },
    {
      label: 'Status',
      name: 'status',
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (v) => `Status: ${v.map((l) => l.toUpperCase())}`,
          update: (filterList, filterPos, index) => {
            console.log('update');
            console.log(filterList, filterPos, index);
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          logic: (status, filters, row) => {
            if (filters.length) return !filters.includes(status);
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <StatusComponent
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
            />
          ),
        },
      },
    },
    {
      label: 'Billing Status',
      name: 'billingStatus',
      options: {
        filter: false,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            console.log(order);
            let val1;
            let val2;
            if (obj1.data === 'Free') {
              val1 = 3;
            } else if (obj1.data === 'Pending') {
              val1 = 2;
            } else if (obj1.data === 'Billed') {
              val1 = 1;
            }

            if (obj2.data === 'Free') {
              val2 = 3;
            } else if (obj2.data === 'Pending') {
              val2 = 2;
            } else if (obj2.data === 'Billed') {
              val2 = 1;
            }

            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        },
        customFilterListOptions: {
          render: (v) => `Billing Status: ${v.map((l) => l.toUpperCase())}`,
          update: (filterList, filterPos, index) => {
            console.log('update');
            console.log(filterList, filterPos, index);
            filterList[index].splice(filterPos, 1);
            return filterList;
          },
        },
        filterOptions: {
          logic: (billingStatus, filters, row) => {
            if (filters.length) return !filters.includes(billingStatus);
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <BillingComponent
              filterList={filterList}
              index={index}
              onChange={onChange}
              column={column}
            />
          ),
        },
      },
    },
    {
      label: 'Billing Cycle',
      name: 'billingCycle',
      options: {
        filter: true,
        display: 'true',
        filterType: 'dropdown',
        customFilterListOptions: {
          render: (v) => `Billing Cycle: ${v}`,
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let ayear = obj1.data.match(/\d+/g)[0];
            console.log('ayear:' + ayear);
            let byear = obj2.data.match(/\d+/g)[0];
            if (byear < ayear) {
              return -1 * (order === 'asc' ? 1 : -1);
            } else if (byear > ayear) {
              return 1 * (order === 'asc' ? 1 : -1);
            } else {
              let aterm = -1;
              if (obj1.data.toLowerCase().includes('fall')) {
                aterm = 3;
              } else if (obj1.data.toLowerCase().includes('summer')) {
                aterm = 2;
              } else if (obj1.data.toLowerCase().includes('spring')) {
                aterm = 1;
              }
              let bterm = -1;
              if (obj2.data.toLowerCase().includes('fall')) {
                bterm = 3;
              } else if (obj2.data.toLowerCase().includes('summer')) {
                bterm = 2;
              } else if (obj2.data.toLowerCase().includes('spring')) {
                bterm = 1;
              }
              if (bterm < aterm) {
                return -1 * (order === 'asc' ? 1 : -1);
              }
              if (bterm > aterm) {
                return 1 * (order === 'asc' ? 1 : -1);
              }
              return 0;
            }
          };
        },
      },
    },
    {
      label: 'Date of Submission',
      name: 'date',
      options: {
        filter: true,
        display: 'true',
        sortDirection: 'desc',
        customBodyRender: (value) => {
          return new Date(value).toLocaleDateString();
        },
        filterType: 'custom',
        customFilterListRender: (v) => {
          if (v[0] && v[1] && isChecked) {
            return [`Start Date: ${v[0]}`, `End Date: ${v[1]}`];
          } else if (v[0] && v[1] && !isChecked) {
            return `Start Date: ${v[0]}, End Date: ${v[1]}`;
          } else if (v[0]) {
            return `After this date: ${v[0]}`;
          } else if (v[1]) {
            return `Before this date: ${v[1]}`;
          }
          return false;
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            var check = new Date(date);
            var from = new Date(filters[0]);
            var to = new Date(filters[1]);
            from.setDate(from.getDate() + 1);
            to.setDate(to.getDate() + 1);
            if (filters[0] && filters[1]) {
              return check <= from || check >= to;
            } else if (filters[0]) {
              return check <= from;
            } else if (filters[1]) {
              return check >= to;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <SubmissionComponent
              filterList={filterList}
              onChange={onChange}
              index={index}
              column={column}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
            />
          ),
        },
        print: false,
      },
    },
    {
      label: 'View Samples',
      name: 'viewSamples',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const orderCodeForRow = tableMeta.rowData[0]; // Assuming orderCode is the first column in the data.
          return (
            <SampleListView orderCode={orderCodeForRow} buttonTitle="View" />
          );
        },
        print: false,
      },
    },
    {
      label: 'Download Files',
      name: 'downloadFiles',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <DownloadFileComponent
              orderCode={tableMeta.rowData[0]}
              completed={tableMeta.rowData[3] === 'Completed'}
            />
          );
        },
      },
    },
    {
      label: 'Active',
      name: 'active',
      options: {
        filter: false,
        display: 'excluded',
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'multiselect',
    print: false,
    viewColumns: false,
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'primary',
        variant: 'outlined',
        className: 'testClass123',
      };
    },
  };

  return (
    <MUIDataTable
      title={'Current Orders'}
      data={data.map((item) => {
        return [
          item.orderCode,
          item.researcherName,
          item.dropLocation,
          item.status,
          item.billingStatus,
          item.billingCycle,
          item.dateDroppedOff,
          item.active,
        ];
      })}
      columns={columns}
      options={options}
    />
  );
};

export default CurrentOrdersTable;
