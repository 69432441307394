import React, { useState, useRef, useContext } from 'react';
import Grid from '@mui/material/Grid';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import axios from 'axios';
import {
  PopupGridItem,
  PopupDownloadExcel,
  PopupBillingCycleSemester,
  PopupBillingCycleYear,
  PopupNotes,
  PopupGridItemWithComponent,
  PopupPCRBlock,
  PopupGridDateReceived,
  PopupCartridge,
  PopupSSTimer,
  PopupPcrMachineTimer,
  PopupSubmissionType,
  PopupNoSamples,
} from './PopupImports';
import AuthContext from '../../utils/auth/auth';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import RotateLoader from 'react-spinners/RotateLoader';
import Alert from '@mui/material/Alert';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import { socket, SocketContext } from '../../context/socket';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function PopupContent(props) {
  const user = useContext(AuthContext);
  const client = props.client;
  const setClientReceived = props.setClientReceived;
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = props.handleClose;
  const viewOnly = props.viewOnly;
  var object = new Object();
  object['username'] = client.username;

  return (
    <>
      <Grid container>
        <Grid item xs={11}>
          <h2 className="clientsPopupUsernameTitle">{client.username}</h2>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          container
          style={{ maxHeight: 650, overflowY: 'auto', overflowX: 'hidden' }}
          rowSpacing={0}
          spacing={0}
        >
          <Grid item xs={12}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                firstName: client.firstName ? client.firstName : '',
                lastName: client.lastName ? client.lastName : '',
                SUMSworktag: client.SUMSworktag ? client.SUMSworktag : '',
                email: client.email ? client.email : '',
                phoneNumber: client.phoneNumber ? client.phoneNumber : '',
                labName: client.labName ? client.labName : '',
                university: client.university ? client.university : '',
                universityTag: client.universityTag ? client.universityTag : '',
                noOfOrders: client.noOfOrders ? client.noOfOrders : '',
                orderTag: client.orderTag ? client.orderTag : '',
              }}
            >
              {(formikProps) => (
                <Form>
                  <PopupGridItem
                    attribute={'Name'}
                    data={
                      client.firstName
                        ? client.firstName + ' ' + client.lastName
                        : ''
                    }
                  />
                  <PopupGridItem attribute={'Lab name'} data={client.PIName} />
                  <PopupGridItem
                    attribute={'University'}
                    data={client.university}
                  />
                  <PopupGridItem
                    attribute={'University Tag'}
                    data={client.universityTag}
                  />
                  <PopupGridItem
                    attribute={'SUMS worktag'}
                    data={client.SUMSworktag}
                  />
                  <PopupGridItem attribute={'Email'} data={client.email} />
                  <PopupGridItem
                    attribute={'Phone'}
                    data={client.phoneNumber}
                  />
                  <PopupGridItem
                    attribute={'No. of Orders'}
                    data={client.noOfOrders}
                  />
                  <PopupGridItem
                    attribute={'Order tag'}
                    data={client.orderTag}
                  />
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

