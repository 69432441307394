import axios from 'axios';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

/**
 * Submit the sanger form
 *
 * @async
 * @param {Array} samples - samples to be submitted
 * @param {Map} primerMap - primers to be submitted
 * @param {string} clientNotes - client notes for the order submission
 * @throws {Error} - Axios errors
 * @returns {Promise<Object>} Response object
 */
export const submitSangerForm = async (samples, primerMap, clientNotes) => {
  try {
    const res = await axios({
      method: 'post',
      url: API_ENDPOINT + '/upload/clientExcel',
      data: {
        samples: samples,
        primers: Object.fromEntries(primerMap),
        clientNotes: clientNotes, // not validated with Yup
      },
      params: {
        orderType: 'sanger',
      },
    });
    return res;
  } catch (error) {
    console.error('Error submitting order:', error);
    throw error;
  }
};

/**
 * Update the form saved on the cloud
 *
 * @async
 * @param {Map} primerMap - primers to be saved
 * @param {Array} samples - samples to be saved
 * @returns {Promise<Object>} Response object
 */
export const updateSangerForm = async (primerMap, samples) => {
  try {
    await axios({
      url: API_ENDPOINT + '/orderForm/sanger',
      method: 'POST',
      data: { primers: Object.fromEntries(primerMap), samples: samples },
    });
  } catch (error) {
    console.error('Error saving Sanger form', error);
    throw error;
  }
};

/**
 * Get the form saved on the cloud
 *
 * @async
 * @returns {Promise<Object>} Response object
 */
export const getSangerForm = async () => {
  try {
    let res = await axios({
      url: API_ENDPOINT + '/orderForm/sanger',
      method: 'GET',
    });
    return res;
  } catch (error) {
    console.error('Error getting saved Sanger form:', error);
    throw error;
  }
};

/**
 * Upload an Excel file of the Sanger form contents
 *
 * @async
 * @param {Object} formData - Data of the form to be uploaded
 * @returns {Promise<Object>} Response object
 */
export const uploadSangerExcel = async (formData) => {
  try {
    let res = await axios({
      url: API_ENDPOINT + '/sampleList/clientSangerExcel',
      method: 'POST',
      data: formData,
    });
    return res;
  } catch (error) {
    console.error('Error uploading Excel file:', error);
    throw error;
  }
};
