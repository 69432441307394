import { CloudDownload, Error } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { Button, Grid, List, ListItem } from '@mui/material';
import { Box, Card, CardContent, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from 'reusable/Typography';
import { downloadFile, getDownloadInfo } from 'utils/api/File';

export const DownloadFile = () => {
  const { downloadId } = useParams();
  const [exists, setExists] = useState(null);
  const [filename, setFilename] = useState('');
  const [expires, setExpires] = useState(null);
  const [expired, setExpired] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const getDateString = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString('en-US')} ${date.toLocaleTimeString(
      'en-US'
    )}`;
  };

  useEffect(() => {
    const getInfo = async () => {
      const res = await getDownloadInfo(downloadId);
      if (res.status === 'success') {
        const doc = res.data.document;
        setExists(true);
        setFilename(doc.filename);
        if (!doc.expiresAt) setExpires('Never');
        else {
          setExpires(getDateString(doc.expiresAt));
          setExpired(new Date(doc.expiresAt) < Date.now());
        }
      } else if (res.status === 'not found') {
        setExists(false);
      }
    };

    getInfo();
  }, []);

  const download = async () => {
    try {
      const response = await downloadFile(downloadId);

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename ?? 'test.zip');
      document.body.appendChild(link);
      link.click();

      link.remove();
      window.URL.revokeObjectURL(url);
      setDownloading(true);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <Box
      item
      xs={12}
      sx={{
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Card variant="elevation" sx={{ width: 400 }}>
        <CardContent>
          {exists === true && (
            <List>
              <ListItem>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <CloudDownload fontSize="large" color="primary" />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="h6" textAlign="left">
                      Download Files
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{ fontSize: 12, color: '#888888' }}
                    >
                      Select one of the following files to download
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider variant="fullWidth" component="li" />
              <ListItem>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography variant="h6">{filename}</Typography>
                    <Typography
                      variant="p"
                      sx={{ fontSize: 12, color: '#888888' }}
                    >
                      Expires at: {expires}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: downloading ? 'start' : 'center',
                      paddingTop: 1,
                    }}
                  >
                    {downloading ? (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 2,
                          justifyContent: 'end',
                        }}
                      >
                        <Typography
                          variant="p"
                          sx={{ color: '#888888', fontSize: 12 }}
                        >
                          Downloading...
                        </Typography>
                        <CircularProgress size={20} />
                      </Box>
                    ) : (
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={download}
                        disabled={expired}
                        tooltip="This download link has expired"
                      >
                        Download
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          )}
          {exists === false && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Error color="primary" fontSize="large" />
              <Typography variant="h6">FILE NOT FOUND</Typography>
              <Typography variant="p" textAlign="center">
                If you think this is an error, please contact us at{' '}
                <a href="mailto:sanger@ibb.gatech.edu">sanger@ibb.gatech.edu</a>
              </Typography>
            </Box>
          )}
          {exists === null && <Typography variant="p5">Loading...</Typography>}
        </CardContent>
      </Card>
    </Box>
  );
};
