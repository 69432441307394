import React, { useState, useRef, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import axios from 'axios';
import { socket, SocketContext } from '../../context/socket';
import Grid from '@mui/material/Grid';
import RotateLoader from 'react-spinners/RotateLoader';
import { useFormik, yupToFormErrors } from 'formik';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import OrderAutocomplete from './OrdersDropDown';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV


function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const style = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  p: 4,
};

export default function CreateComboRun(props) {
  const [open, setOpen] = React.useState(false);
  const [ordersReceived, setOrderReceived] = useState(false);
  const [options, setOptions] = React.useState([]);
  const [success, setSuccess] = useState(false);
  const [showRes, setShowRes] = useState(false);
  const [submitLoad, setSubmitLoad] = useState(false);
  const handleClose = props.handleClose;
  const loading = open && options.length === 0;


  async function getActiveOrders() {
    if (ordersReceived === false) {
      const res = await axios({
        url: API_ENDPOINT + '/order/active',
        method: 'GET',
        params: {
          inComboRun: false,
        },
      });
      const data = res.data;
      if (data.length === 0) {
        data.push({ orderCode: 'No orders available', noOfSamples: 0 });
      }
      // Existing options
      const currentOrders = formik.values.orders;
      // New fetched options
      const newOptions = data.filter(
        d => !currentOrders.some(co => co.orderCode === d.orderCode)
      );
      setOptions([...currentOrders, ...newOptions]);

      setOrderReceived(true);
      return;
    } else {
      //DO Nothing
    }
  }

  const formik = useFormik({
    initialValues: {
      orders: [],
      totalCount: 0,
    },
    validationSchema: Yup.object({
      orders: Yup.array()
        .min(2, 'Must be at least 2 orders')
        .required('Required'),
      totalCount: Yup.number().max(96, 'Count cannot go over 96'),
    }),
    onSubmit: async (values) => {
      setShowRes(false);
      setSubmitLoad(true);
      var result = values.orders.map(({ orderCode }) => orderCode);
      const res = await axios({
        url: API_ENDPOINT + '/order/CCR',
        method: 'PUT',
        data: {
          orders: result,
        },
      });
      if (res.status === 200) {
        setSuccess(true);
        setShowRes(true);
        setSubmitLoad(true);
      } else {
        setSubmitLoad(false);
        setSuccess(false);
        setShowRes(true);
      }
    },
  });

  socket.on('updateAll', (update) => {
    getActiveOrders(setOptions, ordersReceived, setOrderReceived);
  });

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);
      await getActiveOrders(setOptions, ordersReceived, setOrderReceived);

      if (active) {
        setOptions([...options]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
      setOrderReceived(false);
    }
  }, [open]);

const handleChange = (event, newValues) => {
    formik.setFieldValue('orders', newValues);
    var totalCount = 0;
    for (let i = 0; i < newValues.length; i++) {
        totalCount += newValues[i].noOfSamples;
    }
    formik.setFieldValue('totalCount', totalCount);
};

  return (
    <>
      <Grid
        container
        spacing={0}
        justifyContent="center"
        alignItems="center"
        maxWidth={600}
        sx={style}
        style={{ minHeight: '80vh', margin: 'auto' }}
      >
        <Grid item xs={10}>
          <h2>Create a combo run</h2>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <h1>
            Please select all the orders you want to declare in this comborun
          </h1>
        </Grid>
        <Grid item xs={8}>
        <OrderAutocomplete
                      value={formik.values.orders}
                      onChange={(event, value) => handleChange(event, value)}
                      open={open}
                      setOpen={setOpen}  // This prop assumes that the OrderAutocomplete component accepts this prop to handle open state changes
                      submitLoad={submitLoad}
                      options={options}
                      loading={loading}
                      error={formik.touched.orders && Boolean(formik.errors.orders)}
                      helperText={formik.touched.orders && formik.errors.orders}
                    />
        </Grid>
        <Grid item xs={4}>
          Count space left
          <br></br>
          {96 - formik.values.totalCount}
          <br></br>
          <div className="formError">{formik.errors.totalCount}</div>
        </Grid>
        <Grid item>
          {success ? (
            <></>
          ) : (
            <LoadingButton
              size="medium"
              classes="CCRSubmit"
              variant="outlined"
              type="submit"
              onClick={formik.handleSubmit}
              loading={submitLoad}
              // loading={submitLoad}
              // disabled={saveLoad || adminOnly}
              loadingIndicator={
                <RotateLoader
                  cssOverride={{ left: '100%' }}
                  size={7}
                  margin={-15}
                />
              }
            >
              Create combo run
            </LoadingButton>
          )}
          {showRes ? (
            <>
              {success ? (
                <Alert
                  severity="success"
                  icon={<CheckIcon fontSize="inherit" />}
                >
                  {' '}
                  Combo Run Created!{' '}
                </Alert>
              ) : (
                <Alert severity="error">Failed to create Combo Run!</Alert>
              )}
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
}
