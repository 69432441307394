import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { socket, SocketContext } from '../../../context/socket';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import RotateLoader from 'react-spinners/RotateLoader';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';



import axios from 'axios';
import DeleteLogDateRange from './DeleteLogDateRange';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV


// uses the "dialog" component on the MUI website

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '650px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function DeleteLog(props) {
    const [open, setOpen] = useState(false);
    const [submitLoad, setSubmitLoad] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [submitResponse, setSubmitResponse] = useState(null);
    const [submitSucess, setSubmitSucess] = useState(true);
    
    
    ////console.log("props.whichLog: " + props.whichLog);
    ////console.log("props.products: " + props.products);
    let displayCatologNumber = props.whichLog === 'the Whole Inventory';
    let whichLog = props.whichLog;
    let inventoryItem;
    if (!displayCatologNumber) {
        inventoryItem = props.products._id;
    }

   
    

        
    async function handleDeleteLog(){
            setSubmitLoad(true);
            //console.log("handleDelete")
            var values = {from : new Date('August 19, 1975 23:15:30')}
            if (props.hideRange == "ALL LOGS") {
                values = {...values, to : new Date()};
              } else if (props.hideRange == "LOGS AFTER 30 DAYS") {
                let today = new Date();
                today.setDate(today.getDate() - 30);
                //console.log("today: " + today);
                values = {...values, to : today};
              } else if (props.hideRange == "LOGS AFTER 60 DAYS") {
                let today = new Date();
                today.setDate(today.getDate() - 60);
                //console.log("today: " + today);
                values = {...values, to : today};

              } else {
                let today = new Date();
                today.setDate(today.getDate() - 90);
                //console.log("today: " + today);
                values = {...values, to : today};

              }
              //console.log("values: " + values);
            try {
              var res
            if (displayCatologNumber) {
                
                res = await axios.put(API_ENDPOINT + '/Inventory/inventoryLog/hide/all', values);
            } else {
                //console.log("catolog");
                values = {...values,_id : inventoryItem}
                //console.log("values: " + values);
                res = await axios.put(API_ENDPOINT + '/Inventory/inventoryLog/hide/catolog', values);
            }
              //var res = await axios.put(API_ENDPOINT + '/Inventory/inventoryLog/hide/catolog', values);
              var status = res.status;
              if (status === 201) {
                socket.emit('deletelog', values._id,values.from,values.to);
                setSubmitSucess(true);
                setSubmitResponse('Archived Log Sucessfully!');
              } 
            } catch {
              setSubmitSucess(false);
              setSubmitResponse(
                'Unsucessful! Please try again later or contact adminsitrator if problem persists'
              );
            }
            //console.log('submit');
            //console.log(values);
            setSubmitStatus(true);
            setSubmitLoad(false);
          }
          
          



    const handleCloseS = () => {
        setSubmitStatus(false);
      };
  
    const handleCloseSubmit = () => {
      setSubmitStatus(false);
    };

    const handleClickOpen = () => {
        setOpen(true);

        if ((props.hideRange == "ALL LOGS" || props.hideRange == "LOGS AFTER 30 DAYS") || props.hideRange == "LOGS AFTER 90 DAYS" || props.hideRange == "LOGS AFTER 60 DAYS") {
            handleDeleteLog();
        }
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleCloseS}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>
           

            {(props.hideRange == "ALL LOGS" || props.hideRange == "LOGS AFTER 30 DAYS") || props.hideRange == "LOGS AFTER 60 DAYS" || props.hideRange == "LOGS AFTER 90 DAYS"? 
            <Button size = "large" variant="outlined" onClick={handleClickOpen}>{'ARCHIVE ' + props.hideRange}</Button>: 
            <div>
                
                    <DeleteLogDateRange hideRange = {props.hideRange} products={props.products} whichLog = {props.whichLog}/>
                    
                    
            </div>}
            </Grid>
        </div>
    );
}
