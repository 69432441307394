import Box from '@mui/material/Box';
import { Button } from 'reusable/Button';

export const ErrorVisButton = ({ showAllErrors, toggleShowAllErrors }) => {
  const ErrorVisMessage = showAllErrors ? 'Hide Errors' : 'Show All Errors';

  return (
    <Button variant="contained" color="secondary" onClick={toggleShowAllErrors}>
      {ErrorVisMessage}
    </Button>
  );
};

export const ClearFormButton = ({ clearForm }) => {
  return (
    <Button variant="contained" color="secondary" onClick={clearForm}>
      Clear Form
    </Button>
  );
};

export const SangerFormSubmit = ({ onUpdate, onSubmit }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'end', gap: 4 }}>
      {/* TODO: Add button back after form functionality is added */}
      {/*<IconButton>*/}
      {/*  <DownloadIcon />*/}
      {/*</IconButton>*/}
      <Button variant="accent" color="secondary" onClick={onUpdate}>
        Save Draft
      </Button>
      <Button variant="accent" onClick={onSubmit}>
        Submit
      </Button>
    </Box>
  );
};

