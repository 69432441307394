import RecipeReviewCard from './RecipeReviewCard';
import RecipeReviewCardRecent5 from './RecipeReviewCardRecent5';
import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Dashboard(props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 16 }}>
        
          <Grid item xs={2} sm={4} md={4} key={1}>
          <RecipeReviewCard deliverOrderStatus = {props.deliverOrderStatus} orderStatus = {'Need to Order'} CatologFilter = {props.CatologFilter}/>
          </Grid>
          <Grid item xs={2} sm={4} md={4} key={2}>
          <RecipeReviewCard deliverOrderStatus = {props.deliverOrderStatus} orderStatus = {'Running Low'} CatologFilter = {props.CatologFilter}/>
          </Grid>
          {/** 
          <Grid item xs={2} sm={4} md={4} key={3}>
          <RecipeReviewCard deliverOrderStatus = {props.deliverOrderStatus} orderStatus = {'In Stock'}/>
          </Grid>
          */}
          <Grid item xs={4} sm={8} md={8} key={4}>
          <RecipeReviewCardRecent5/>
          </Grid>
        
      </Grid>
    </Box>
  );
}