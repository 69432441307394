import React, {
  useState,
  useRef,
  useContext,
  Redirect,
  useEffect,
} from 'react';
import './Drop_off.css';
import './Popup.css';
import logo from '../../images/logo.png';
import axios from 'axios';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import RotateLoader from 'react-spinners/RotateLoader';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import AuthContext from '../../utils/auth/auth';
import { socket, SocketContext } from '../../context/socket';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

function ErorrPopup(props) {
  return <h1 className="error"> {props.error} </h1>;
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function Popup(props) {
  return (
    <div className="popup_outter">
      <div className="popup_inner">
        <h3 style={{ textAlign: 'center' }}>Upload successful!</h3>
        <h1 style={{ textAlign: 'center' }}>Your drop off code:</h1>
        <p className="code">{props.code}</p>
        <br></br>
        <h1 style={{ textAlign: 'center', fontSize: 30 }}>
          Please keep a copy of this code, you will also find it in your 'My
          Orders' tab. Please drop within 24 hrs or entry will be deleted.
        </h1>
      </div>
    </div>
  );
}

//KEEP THIS FOR POTENTIAL REVAMP OF DOWNLAOD BUTTON TO SHOW PROGRESS BAR
// function CircularProgressWithLabel(props) {
//   return (
//     <Box sx={{ position: 'relative', display: 'inline-flex' }}>
//       <CircularProgress variant="determinate" {...props} />
//       <Box
//         sx={{
//           top: 0,
//           left: 0,
//           bottom: 0,
//           right: 0,
//           position: 'absolute',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//         }}
//       >
//         <Typography variant="caption" component="div" color="text.secondary">
//           {`${Math.round(props.value)}%`}
//         </Typography>
//       </Box>
//     </Box>
//   );
// }

export async function DownloadFile(fileNo) {
  axios({
    url: API_ENDPOINT + '/upload/download',
    method: 'GET',
    responseType: 'blob', // important
    params: {
      fileType: fileNo,
    },
    onDownloadProgress: (progressEvent) => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      ); // you can use this to show user percentage of file downloaded
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    var fileName;
    if (fileNo === 1) {
      fileName = 'Sanger-Sequencing-form.xlsx';
    } else {
      fileName = 'Fragment-Analysis-form.xlsx';
    }
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  });
}

const Upload = () => {
  const navigate = useNavigate();
  axios
    .get(API_ENDPOINT + '/user/new')
    .then((res) => {
      if (res.status === 200) {
        //Do nothing
      }
    })
    .catch(function (error) {
      if (error.response.status === 302) {
        navigate('/profile', { replace: true });
      }
    });
  const user = useContext(AuthContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fileGeneratedCode, setFileGeneratedCode] = useState(null);
  const [loading, setloading] = useState(false);
  const [progressSanger, setProgressSanger] = useState();
  const [progressFragement, setProgressFragment] = useState();

  const changeHandler = (event) => {
    console.log('onchange');
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };
  return (
    <div
      style={{
        // position: 'absolute',
        margin: 50,
      }}
    >
      {showPopup ? (
        <Popup code={fileGeneratedCode} />
      ) : (
        <>
          <label>
            {' '}
            Step 1: Download Excel file based on submssion and follow
            instructions{' '}
          </label>
          <Stack spacing={2} direction="row">
            <Button
              style={{ maxHeight: '55px' }}
              size="small"
              variant="contained"
              onClick={() => DownloadFile(1)}
            >
              Download Sanger Sequencing form
            </Button>
            <Button
              style={{ maxHeight: '55px', fontSize: '11px' }}
              size="small"
              variant="contained"
              onClick={() => DownloadFile(0)}
            >
              Download Fragment Analysis form
            </Button>
          </Stack>
          <br></br>
          <label> Setp 2: Submit Excel file </label>
          <Formik
            initialValues={{
              submissionType: '',
              sangerNoOfSamples: '0',
              fragmentNoOfSamples: '0',
            }}
            validationSchema={Yup.object({
              submissionType: Yup.string().required('Required'),
              sangerNoOfSamples: Yup.number()
                .max(96, 'Max amount is 96')
                .min(0, 'Value cannot below 0')
                .required('Required')
                .typeError('Must be a number'),
              fragmentNoOfSamples: Yup.number()
                .max(96, 'Max amount is 96')
                .min(0, 'Value cannot below 0')
                .required('Required')
                .typeError('Must be a number'),
            })}
            enableReinitialize={false}
            onSubmit={async (values) => {
              console.log('onsubmit');
              console.log(values);
              setIsInvalid(false);
              setloading(true);
              await sleep(1e3);
              if (isFilePicked) {
                const formData = new FormData();
                formData.append('clientExcelUploadBody', selectedFile);
                formData.append('submissionType', values.submissionType);
                formData.append('sangerNoOfSamples', values.sangerNoOfSamples);
                formData.append(
                  'fragmentNoOfSamples',
                  values.fragmentNoOfSamples
                );
                console.log('user.casUser:' + user.casUser);
                axios
                  .get(API_ENDPOINT + '/clientOrders/uploadedOrders', {
                    params: { username: user.casUser },
                  })
                  .then((res) => {
                    if (res.status === 200) {
                      console.log('uploadedOrders');

                      axios({
                        method: 'post',
                        url: API_ENDPOINT + '/upload/clientExcel',
                        data: formData,
                      })
                        .then((res) => {
                          if (res.status === 200) {
                            setloading(false);
                            setShowPopup(true);
                            setIsInvalid(false);
                            setFileGeneratedCode(res.data.generatedCode);
                          }

                          return res.data;
                        })
                        .catch(function (error) {
                          setloading(false);
                          setShowPopup(false);
                          setIsInvalid(true);
                          // message if file type is not supported
                          if (error.response.status === 415) {
                            setErrorMessage('Please upload an excel file');
                          } else if (error.response.status === 413) {
                            setErrorMessage(
                              'File is too large! Max Size is 500 KB (0.5 MB)'
                            );
                          } else if (error.response.status === 403) {
                            setErrorMessage(
                              'Max amount of uploaded orders (5 at a time) reached! Please drop off or delete entries before requesting new ones'
                            );
                          } else {
                            setErrorMessage(
                              'Unknown error ocuurred, contact adminsitrator'
                            );
                            console.log(error);
                          }
                        });
                      socket.emit('updateUploadedOrders');
                    }
                  });
              } else {
                setloading(false);
                setShowPopup(false);
                setIsInvalid(true);
                setErrorMessage('Please select a file to upload');
              }
            }}
          >
            {(formikProps) => (
              <Form>
                <label style={{ display: 'block' }}> Submission Type *</label>
                <Box sx={{ minWidth: 120 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControl fullWidth error>
                        <Select
                          value={formikProps.values.submissionType}
                          onChange={(e) => {
                            formikProps.setFieldValue(
                              'submissionType',
                              e.target.value
                            );
                            formikProps.setFieldValue('sangerNoOfSamples', 0);
                            formikProps.setFieldValue('fragmentNoOfSamples', 0);
                          }}
                          placeholder={'Choose a submission type'}
                          error={
                            formikProps.touched.submissionType &&
                            Boolean(formikProps.errors.submissionType)
                          }
                          disabled={loading}
                        >
                          <MenuItem value={'Sanger Sequencing'}>
                            Sanger Sequencing
                          </MenuItem>
                          <MenuItem value={'Fragment Analysis'}>
                            Fragment Analysis
                          </MenuItem>
                          {/* <MenuItem value={'Mixed'}>Mixed</MenuItem> */}
                        </Select>
                        <FormHelperText>
                          {formikProps.touched.submissionType &&
                            formikProps.errors.submissionType}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    {formikProps.values.submissionType ===
                      'Sanger Sequencing' &&
                    formikProps.values.submissionType !== '' ? (
                      <>
                        <Grid item xs={12}>
                          <label style={{ display: 'block' }}>
                            {' '}
                            Reaction Count *
                          </label>
                          <TextField
                            id="sangerNoOfSamples"
                            value={formikProps.values.sangerNoOfSamples}
                            onChange={formikProps.handleChange}
                            error={
                              formikProps.touched.sangerNoOfSamples &&
                              Boolean(formikProps.errors.sangerNoOfSamples)
                            }
                            helperText={
                              formikProps.touched.sangerNoOfSamples &&
                              formikProps.errors.sangerNoOfSamples
                            }
                            disabled={loading}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {formikProps.values.sangerNoOfSamples !== '' &&
                          formikProps.values.sangerNoOfSamples !== '0' ? (
                            <div>
                              <label style={{ display: 'block' }}>
                                {' '}
                                Step 3: File Upload (Excel File) *
                              </label>
                              <input
                                type="file"
                                name="clientExcelUploadBody"
                                onChange={changeHandler}
                                disabled={loading}
                              />
                            </div>
                          ) : null}
                        </Grid>
                      </>
                    ) : null}
                    {formikProps.values.submissionType === 'Mixed' &&
                    formikProps.values.submissionType !== '' ? (
                      <>
                        <Grid item xs={12}>
                          <label style={{ display: 'block' }}>
                            {' '}
                            Reaction Count *
                          </label>
                          <TextField
                            id="sangerNoOfSamples"
                            value={formikProps.values.sangerNoOfSamples}
                            onChange={formikProps.handleChange}
                            error={
                              formikProps.touched.sangerNoOfSamples &&
                              Boolean(formikProps.errors.sangerNoOfSamples)
                            }
                            helperText={
                              formikProps.touched.sangerNoOfSamples &&
                              formikProps.errors.sangerNoOfSamples
                            }
                            disabled={loading}
                          />
                          <label style={{ display: 'block' }}>
                            {' '}
                            Sample Count *
                          </label>
                          <TextField
                            id="fragmentNoOfSamples"
                            value={formikProps.values.fragmentNoOfSamples}
                            onChange={formikProps.handleChange}
                            error={
                              formikProps.touched.fragmentNoOfSamples &&
                              Boolean(formikProps.errors.fragmentNoOfSamples)
                            }
                            helperText={
                              formikProps.touched.fragmentNoOfSamples &&
                              formikProps.errors.fragmentNoOfSamples
                            }
                            disabled={loading}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {formikProps.values.fragmentNoOfSamples !== '0' &&
                          formikProps.values.sangerNoOfSamples !== '0' &&
                          formikProps.values.fragmentNoOfSamples !== '' &&
                          formikProps.values.sangerNoOfSamples !== '' ? (
                            <div>
                              <label style={{ display: 'block' }}>
                                {' '}
                                Step 3: File Upload (Excel File) *
                              </label>
                              <input
                                type="file"
                                name="clientExcelUploadBody"
                                onChange={changeHandler}
                                disabled={loading}
                              />
                            </div>
                          ) : null}
                        </Grid>
                      </>
                    ) : null}

                    {formikProps.values.submissionType ===
                      'Fragment Analysis' &&
                    formikProps.values.submissionType !== '' ? (
                      <>
                        <Grid item xs={12}>
                          <label style={{ display: 'block' }}>
                            {' '}
                            Sample Count *
                          </label>
                          <TextField
                            id="fragmentNoOfSamples"
                            value={formikProps.values.fragmentNoOfSamples}
                            onChange={formikProps.handleChange}
                            error={
                              formikProps.touched.fragmentNoOfSamples &&
                              Boolean(formikProps.errors.fragmentNoOfSamples)
                            }
                            helperText={
                              formikProps.touched.fragmentNoOfSamples &&
                              formikProps.errors.fragmentNoOfSamples
                            }
                            disabled={loading}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {formikProps.values.fragmentNoOfSamples !== '0' &&
                          formikProps.values.fragmentNoOfSamples !== '' ? (
                            <div>
                              <label style={{ display: 'block' }}>
                                {' '}
                                Step 3: File Upload (Excel File) *
                              </label>
                              <input
                                type="file"
                                name="clientExcelUploadBody"
                                onChange={changeHandler}
                                disabled={loading}
                              />
                            </div>
                          ) : null}
                        </Grid>
                      </>
                    ) : null}

                    <Grid items xs={12}>
                      <div className="submit-btn">
                        <LoadingButton
                          size="large"
                          type="submit"
                          variant="outlined"
                          loading={loading}
                          loadingIndicator={
                            <RotateLoader size={7} margin={-15} />
                          }
                        >
                          Submit
                        </LoadingButton>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
          {isInvalid ? (
            <div className="center_text">
              <ErorrPopup error={errorMessage} />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Upload;
