import { Grid } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { EmptyTableRow } from 'reusable/EmptyTableRow';
import { Typography } from 'reusable/Typography';
import { PrimerTableHeading } from './PrimerTableComponents/PrimerTableHeading';
import { PrimerTableRow } from './PrimerTableComponents/PrimerTableRow';

export const PrimerTable = ({ primers, setPrimers }) => {
  const widthRef = useRef(null);
  const [tableWidth, setTableWidth] = useState(0);

  const movePrimer = (startIdx, endIdx) => {
    setPrimers((primers) => {
      const newPrimers = [...primers];
      const movedPrimer = newPrimers[startIdx];
      newPrimers.splice(startIdx, 1);
      newPrimers.splice(endIdx, 0, movedPrimer);
      return newPrimers;
    });
  };

  useEffect(() => {
    if (widthRef.current) {
      setTableWidth(widthRef.current.offsetWidth);
    }
  }, [widthRef.current]);

  return (
    <Grid container>
      <Grid item xs={12} sx={{ pl: 2 }}>
        <Typography variant="h2" sx={{ textAlign: 'left' }}>
          Primer Table
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <table
          style={{ position: 'relative', left: 40, width: 'calc(100% - 80px)' }}
        >
          <PrimerTableHeading />
          <tbody>
            {primers.length === 0 ? (
              <EmptyTableRow cols={5} />
            ) : (
              primers.map((primer, i) => (
                <PrimerTableRow
                  primer={primer}
                  movePrimer={movePrimer}
                  index={i}
                  tableWidth={tableWidth}
                />
              ))
            )}
          </tbody>
        </table>
        <div style={{ width: '100%' }} ref={widthRef}></div>
      </Grid>
    </Grid>
  );
};
