import { memo, useContext } from 'react';
import { Box } from '@material-ui/core';
import HexagonIcon from '@mui/icons-material/Hexagon';
import { PrimerContext } from '../../../contexts/DataContext';

const SangerPrimerRowColor = memo(
  ({ data, primerKey, primerColors }) => {
    return (
      <Box
        sx={{
          width: 30,
          height: 30,
          margin: 5,
          backgroundColor: primerColors
            ? primerColors.get(primerKey)
            : '#FFFFFF',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {data.labPrimer ? <HexagonIcon /> : <></>}
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.id === nextProps.id;
  }
);

export default SangerPrimerRowColor;
