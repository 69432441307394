import axios from 'axios';

export const useControls = () => {
  const fetchControls = async () => {
    try {
      const res = await axios.get(`/api/controls/`);
      return {
        controls: res.data.filter((control) => !control.archived),
        archived: res.data.filter((control) => control.archived),
      };
    } catch (error) {
      console.error('Error getting controls: ', error);
      throw error;
    }
  };

  const newControl = () => {};

  const updateControl = async (control) => {
    try {
      const res = await axios.patch(`/api/controls/${control._id}`, control);
      return res.data;
    } catch (error) {
      console.error('Error getting controls: ', error);
      throw error;
    }
  };

  const archiveControl = async (control) => {
    try {
      const res = await axios.patch(`/api/controls/${control._id}`, {
        archived: true,
      });
      return res.data;
    } catch (error) {
      console.error('Error getting controls: ', error);
      throw error;
    }
  };

  const unarchiveControl = async (control) => {
    try {
      const res = await axios.patch(`/api/controls/${control._id}`, {
        archived: false,
      });
      return res.data;
    } catch (error) {
      console.error('Error getting controls: ', error);
      throw error;
    }
  };

  return {
    fetchControls,
    newControl,
    updateControl,
    archiveControl,
    unarchiveControl,
  };
};
