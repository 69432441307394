import React, { useState, useRef, useContext, Redirect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import PendingTable from '../Billing_Updated/PendingTable';
import BillingTable from '../Billing_Updated/BillingTable';

function Billing(props) {
  return (
    <Container>
      <Row>
        <PendingTable />
      </Row>
      <br></br>
      <br></br>
      <br></br>
      <Row>
        <BillingTable />
      </Row>
    </Container>
  );
}

export default Billing;
