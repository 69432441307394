import { Backdrop, Button, Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { PropagateLoader } from 'react-spinners';
import { ControlsTable } from './ControlsTableComponents/ControlsTable';
import { ArchivedTable } from './ControlsTableComponents/ArchivedTable';
import './ControlsTable.css';
import { useControls } from './hooks/useControls';
import { NewControlPopup } from 'reusable/ControlTablePopups';

export const ControlsPage = () => {
  const [loading, setLoading] = useState(true);
  const [controls, setControls] = useState([]);
  const [archived, setArchived] = useState([]);
  const [open, setOpen] = useState(false);

  const { fetchControls } = useControls();

  const getControls = async () => {
    setLoading(true);
    try {
      const { controls, archived } = await fetchControls();
      setControls(controls);
      setArchived(archived);
    } catch (err) {
      console.error('Error getting controls: ', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getControls();
  }, []);

  return (
    <>
      <Grid container spacing={4}>
        <Backdrop sx={{ color: '#FFFFFF', zIndex: 1 }}>
          <PropagateLoader />
        </Backdrop>
        {!loading && (
          <>
            <Grid item xs={12}>
              <Button variant="contained" onClick={() => setOpen(true)}>
                New Control
              </Button>
            </Grid>
            <Grid item xs={12}>
              <ControlsTable
                controls={controls}
                refreshControls={getControls}
              />
            </Grid>
            <Grid item xs={12}>
              <ArchivedTable
                archived={archived}
                refreshControls={getControls}
              />
            </Grid>
            <NewControlPopup
              open={open}
              setOpen={setOpen}
              refreshControls={getControls}
            />
          </>
        )}
      </Grid>
    </>
  );
};
