import axios from 'axios';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

export const getCartridgesByLetter = async () => {
  try {
    const res = await axios.get(API_ENDPOINT + '/cartridge/byLetter');
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
