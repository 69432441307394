import React, { useState, useRef, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Formik, Form, useField } from 'formik';
import { ClassNames } from '@emotion/react';
import './PopupStyles.css';
import axios from 'axios';
import { PopupGridItem, PopupNullOrder } from './PopupImports';
import PopupContent from './PopupContent';
import PopupCRContent from './PopupCRContent';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Box from '@mui/material/Box';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { socket, SocketContext } from '../../context/socket';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const getOrder = async (orderCode) => {
  const res = await axios({
    url: API_ENDPOINT + '/order',
    method: 'GET',
    params: {
      orderCode: orderCode,
    },
  });
  return res.data;
};

const getComboRun = async (comboRunNo) => {
  const res = await axios({
    url: API_ENDPOINT + '/order/GCR',
    method: 'GET',
    params: {
      comboRunNo: comboRunNo,
    },
  });
  return res.data;
};

const getComboRunOrders = async (comboRunOrders) => {
  let result = [];
  for (let i = 0; i < comboRunOrders.length; i++) {
    var res = await axios({
      url: API_ENDPOINT + '/order',
      method: 'GET',
      params: {
        orderCode: comboRunOrders[i],
      },
    });
    console.log(res.data);
    result[i] = res.data;
  }
  return result;
};

const style = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  p: 4,
};

const loadingStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export default function Popup(props) {
  const [order, setOrder] = useState(PopupNullOrder);
  const [comboRun, setComboRun] = useState(PopupNullOrder);
  const [ordersInCR, setOrdersInCR] = useState([]);
  const [orderReceived, setOrderReceived] = useState(false);
  const [loading, setLoading] = useState(true);
  const orderCode = props.currentOrder.orderCode;
  const handleClose = props.handleClose;
  const viewOnly = props.viewOnly ? props.viewOnly : false;
  const workflow = props.workflow ? props.workflow : false;
  const { enqueueSnackbar } = useSnackbar();
  console.log(order);

  useEffect(() => {
    getOrder(orderCode).then((data) => {
      setLoading(false);
      setOrder(data);
      setOrderReceived(true);
    });
  }, [orderReceived]);

  async function getOrderinCR() {
    if (order.isComboRun) {
      const res = await axios({
        method: 'GET',
        url: API_ENDPOINT + "/order/mergedInPlate",
        params: {
          orderCode: order.orderCode
        }
      })
      if (res.status == 200) {
        setOrdersInCR(res.data);
      }
    }
  }

  useEffect(() => {
    getOrderinCR();
  }, [order, orderReceived])

  useEffect(() => {
    socket.on('updateOrder', (updatedOrderCode) => {
      if (updatedOrderCode === orderCode) {
        setOrderReceived(false);
        enqueueSnackbar(
          'Another person has updated ' + orderCode + '. Changes loaded',
          { variant: 'info' }
        );
      }
    });
  }, []);
  useEffect(() => {
    socket.on('saveCR', (updatedCR) => {
      if (updatedCR === props.currentOrder.comboRunNo) {
        setOrderReceived(false);
        enqueueSnackbar(
          'Another person has updated CR' +
            props.currentOrder.comboRunNo +
            '. Changes loaded',
          { variant: 'info' }
        );
      }
    });
  }, []);

  useEffect(() => {
    socket.on('updateSaveCR', (updatedCR) => {
      if (updatedCR === props.currentOrder.comboRunNo) {
        setOrderReceived(false);
      }
    });
  }, []);

  useEffect(() => {
    socket.once('orderSubmitted', (updatedOrderCode) => {
      if (viewOnly) {
        setOrderReceived(false);
        enqueueSnackbar(
          'Another person has updated CR' +
            props.currentOrder.comboRunNo +
            '. Changes loaded',
          { variant: 'info' }
        );
      } else if (updatedOrderCode === orderCode && props.modalOpen === true) {
        handleClose();
        enqueueSnackbar(orderCode + ' has been moved', { variant: 'info' });
      }
    });
  }, []);

  useEffect(() => {
    socket.once('submitCR', (comboRunNo) => {
      if (viewOnly) {
        setOrderReceived(false);
        enqueueSnackbar(
          'Another person has updated CR' +
            props.currentOrder.comboRunNo +
            '. Changes loaded',
          { variant: 'info' }
        );
      } else if (
        comboRunNo === props.currentOrder.comboRunNo &&
        props.modalOpen === true
      ) {
        handleClose();
        enqueueSnackbar(orderCode + ' has been moved', { variant: 'info' });
      }
    });
  }, []);

  if (loading) {
    return (
      <Box sx={loadingStyle}>
        <PropagateLoader cssOverride={{ margin: '0' }} />
      </Box>
    );
  } 
  // else if (props.currentOrder.isComboRun === true) {
  //   return (
  //     <Grid
  //       container
  //       rowSpacing={0}
  //       spacing={0}
  //       style={{ maxWidth: 850, minHeight: '60vh', margin: 'auto' }}
  //       sx={style}
  //     >
  //      <PopupCRContent
  //         order={order}
  //         handleClose={handleClose}
  //         viewOnly={viewOnly}
  //         setOrderReceived={setOrderReceived}
  //       />
  //     </Grid>
  //   );
  // }
   else if (order.stage !== '') {
    return (
      <Grid
        container
        rowSpacing={0}
        spacing={0}
        style={{ maxWidth: '85%', minHeight: '60vh', margin: 'auto', width: '580px', maxHeight: '680px' }}
        sx={style}
      >
        <PopupContent
          order={order}
          ordersInCR={ordersInCR}
          handleClose={handleClose}
          viewOnly={viewOnly}
          setOrderReceived={setOrderReceived}
          workflow={workflow}
        />
      </Grid>
    );
  } else {
    return <></>;
  }
}
