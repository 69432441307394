import React, { useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import './HomePage.css';
import { InView } from 'react-intersection-observer';

// const blueTextColor = 'rgb(0, 48, 87)';
// const lightBlueTextColor = 'rgb(0, 138, 252)';

const HomeInstruct = (props) => {
  // const [width, height] = useWindowSize();
  const refs = useRef([]);

  // const isPhoneSize = width < props.phoneWidth;
  // const delayAnimation = isPhoneSize ? 0 : 0.5;
  // const minimumSize = isPhoneSize ? 250 : 250;
  // const marginTop = isPhoneSize ? '7%' : '35%';
  // const titleSize = isPhoneSize ? 27 : 25;

  const [inViews, setInViews] = useState([]);

  const handleInView = (inView, index, id) => {
    const newInViews = [...inViews];
    newInViews[index] = { ...newInViews[index], [id]: inView };
    setInViews(newInViews);
  };

  return (
    <Grid container alignItems={'center'} justifyContent={'center'}
          marginBottom={10} className="instruct__container" wrap={'wrap'}>
      <>
        {props.items.map((item, index) => {
          refs.current[index] = [React.createRef(), React.createRef()];
          return (
            <Grid
              item
              xs
              key={item.id}
              className={`
                ${inViews[index] && inViews[index].first
                ? 'instruct__animation--fade-in'
                : 'instruct__animation--fade-out'}
                instruct__animation-delay--${index}
              `}
            >
              <div className="instruct__block">
                <div
                  className="instruct__header"
                  ref={refs.current[index][0]}
                >
                  <InView
                    threshold={1}
                    triggerOnce={true}
                    onChange={(inView) => handleInView(inView, index, 'first')}
                  >
                    <div>
                      <text>
                        0{index + 1} /
                      </text>
                      {' '}
                      {item.title}
                    </div>
                  </InView>
                </div>
                <div
                  className="instruct__text"
                  ref={refs.current[index][1]}
                >
                  <InView
                    threshold={0.5}
                    triggerOnce={true}
                    onChange={(inView) => handleInView(inView, index, 'second')}
                  >
                    <div>
                      {item.text}
                    </div>
                  </InView>
                </div>
                <InView
                  threshold={0.5}
                  triggerOnce={true}
                  onChange={(inView) => handleInView(inView, index, 'third')}
                >
                  <div>
                    <div className="instruct__footer">
                      {item.footer}
                    </div>
                  </div>
                </InView>
              </div>
            </Grid>
          );
        })}
      </>
    </Grid>
  );
};

export default HomeInstruct;
