import React, {useState, useRef} from "react";
import { Formik, useFormik, yupToFormErrors, Form, useField } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';

import * as Yup from 'yup';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import PropagateLoader from "react-spinners/PropagateLoader";
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import RotateLoader from "react-spinners/RotateLoader";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

import { universities } from '../../context/universities'

import axios from "axios";

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV

function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
}

const Item = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
    float: 'left'
}));

const TextInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
    <>
        <label htmlFor={props.id || props.name}>{label}</label>
        <input className="text-input" {...field} {...props} />
        {meta.touched && meta.error ? (
            <div className="formError">{meta.error}</div>
        ) : null}
        </>
    );
};

export default function PopupUpdate(props) {
    const [getLoad, setGetLoad] = useState(false);
    const [submitLoad, setSubmitLoad] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(false);
    const [submitResponse, setSubmitResponse] = useState(null);
    const [submitSucess, setSubmitSucess] = useState(true);
    const [PIexists, setPIexists] = useState(false);
    // const [usernameExists, setUsernameExists] = useState(false);
    const initialData = props.pis;
    initialData.piFirst = props.pis.PIName.split(" ")[0];
    initialData.piMiddle = props.pis.PIName.split(" ").length === 3 ? 
        (props.pis.PIName.split(" ")[1].replace('.', '')) : ('');
    initialData.piLast = props.pis.PIName.split(" ").length === 3 ? 
        (props.pis.PIName.split(" ")[2]) : (props.pis.PIName.split(" ")[1]);
    initialData.noMiddleInitial = props.pis.PIName.split(" ").length === 3 ? false : true;

    const handleClose = () => {
        setSubmitStatus(false);
    };

    const validationSchema= Yup.object({
        noMiddleInitial: Yup.boolean(),
        piFirst: Yup.string("ur first name")
        .max(20,"No more than 20 characters")
        .min(1, "Must be at least one character")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
        .required('Required'),
        piMiddle: Yup.string().when("noMiddleInitial" ,{
            is: (noMiddleInitial) => noMiddleInitial === false,
            then: Yup.string().required("Required")
            .max(1,"No more than 1 character")
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
        }),
        piLast: Yup.string()
        .max(20,"No more than 20 characters")
        .min(1, "Must be at least one character")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
        .required('Required'),
    });  

    return (
        <Grid container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}> 
            <Backdrop
                sx={{ color: '#fff', zIndex: 1 }}
                open={getLoad}
            >
                <PropagateLoader/>
            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: 1 }}
                open={submitStatus}
                onClick={handleClose}
            >
                {submitSucess ? (
                <Alert onClose={() => {setSubmitStatus(false); window.location.reload(false);}} icon={<CheckIcon fontSize="inherit" />} severity="success">
                {submitResponse}
                </Alert>
                ) : (
                <Alert onClose={() => {setSubmitStatus(false)}} severity="error">
                {submitResponse}
                </Alert>
                )}
            </Backdrop>               
            <Formik 
            initialValues={initialData} 
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={ async (values) => {
                setSubmitLoad(true);
                try {
                    var res = await axios.put(API_ENDPOINT + '/pi/edit', values);
                    var status = res.status
                    await sleep(1e3);
                    if (status === 201) {
                        setSubmitSucess(true);
                        setSubmitResponse("Changes sucessfully submitted!")
                    }
                } catch {
                    setSubmitSucess(false);
                    setSubmitResponse("Unsucessful! Please try again later or contact adminsitrator if problem persists")
                }
                setSubmitStatus(true);
                setSubmitLoad(false);
            }}>
                {(props) => (
                    <Form>
                        <Grid container spacing={0} width={600}>
                            <Grid item xs={12}>
                                <Item>
                                    <label> University</label>
                                    <Autocomplete
                                        disabled={submitLoad || PIexists}
                                        value={props.values.university}
                                        onChange={(e, value) => {
                                        props.setFieldValue('university', value.title);
                                        props.setFieldValue('universityTag', value.abbr);
                                        props.setFieldValue('PIName', '');
                                        }}
                                        disablePortal
                                        id="university"
                                        name="university"
                                        label="university"
                                        options={universities}
                                        getOptionLabel={(option) => {
                                        // e.g value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return option.title;
                                        }}
                                        sx={{ width: 281 }}
                                        renderInput={(params) => (
                                        <TextField
                                            type="universityProfile"
                                            {...params}
                                            placeholder="Search or select university"
                                            error={
                                            props.touched.university &&
                                            Boolean(props.errors.university)
                                            }
                                            helperText={
                                            props.touched.university &&
                                            props.errors.university
                                            }
                                        />
                                        )}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Item>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="piFirst"
                                        name="piFirst"
                                        value={props.values.piFirst}
                                        onChange={props.handleChange}
                                        label="First Name"
                                        type="dialogText"
                                        variant="standard"
                                        error={props.touched.piFirst && Boolean(props.errors.piFirst)}
                                        helperText={props.touched.piFirst && props.errors.piFirst}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Item>
                                    <TextField
                                        margin="dense"
                                        id="piMiddle"
                                        value={props.values.piMiddle}
                                        onChange={props.handleChange}
                                        disabled={props.values.noMiddleInitial}
                                        label="Midde Initial"
                                        type="dialogText"
                                        variant="standard"
                                        error={props.touched.piMiddle && Boolean(props.errors.piMiddle)}
                                        helperText={props.touched.piMiddle && props.errors.piMiddle}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Item>
                                    <TextField
                                        margin="dense"
                                        id="piLast"
                                        value={props.values.piLast}
                                        onChange={props.handleChange}
                                        label="Last Name"
                                        type="dialogText"
                                        variant="standard"
                                        error={props.touched.piLast && Boolean(props.errors.piLast)}
                                        helperText={props.touched.piLast && props.errors.piLast}
                                    />
                                </Item>
                            </Grid>
                            <Grid>
                                <Item>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            value={props.values.noMiddleInitial}
                                            checked={props.values.noMiddleInitial}
                                            disabled={submitLoad}
                                            onChange={(event) => {
                                            props.setFieldValue('noMiddleInitial', event.target.checked);
                                            props.setFieldValue('piMiddle', '');
                                            }}
                                        />
                                        }
                                        sx={{ paddingLeft: 2.5 }}
                                        label="This PI has no middle initial"
                                        labelPlacement="end"
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Item>
                                    <Box>
                                        <LoadingButton
                                            size="large"
                                            classes="profileSubmit"
                                            type="submit"
                                            variant="outlined"
                                            loading={submitLoad}
                                            loadingIndicator={<RotateLoader cssOverride={{left: "100%"}} size={7} margin={-15}/>}
                                            >
                                                Submit
                                        </LoadingButton>
                                    </Box>
                                </Item>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    );
}
