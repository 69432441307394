import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { withFieldFormik } from './withFieldFormik';
import AuthContext from 'utils/auth/auth';
import CheckIcon from '@mui/icons-material/Check';
import Countdown from 'react-countdown';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import BillingPopup from '../Billing_Updated/BillingPopup';
import SampleListView from '../../reusable/List/SampleListView';
import UnMergeCR from './UnMergeCRButton';

export function PopupGridItem(props) {
  return (
    <>
      <Grid container rowSpacing={0} spacing={0}>
        <Grid item xs={6}>
          <h3 className="PopupGridItem">{props.attribute}</h3>
        </Grid>
        <Grid item xs={6}>
          <h6 className="PopupGridItem" style={{ float: 'right' }}>
            {props.data}
          </h6>
        </Grid>
        <Grid item xs={12}>
          <hr
            style={{
              height: '0px',
              margin: 7,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export function PopupGridItemLine(props) {
  return (
    <>
      <Grid container rowSpacing={0} spacing={0}>
        <Grid item xs={6}>
          <h3 className="PopupGridItem">{props.attribute}</h3>
        </Grid>
        <Grid item xs={6}>
          <h6 className="PopupGridItem" style={{ float: 'right' }}>
            {props.data}
          </h6>
        </Grid>
        <Grid item xs={12}>
          <hr
            style={{
              background: 'black',
              height: '2px',
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export function PopupGridTwoItems(props) {
  return (
    <>
      <Grid container rowSpacing={0} spacing={0}>
        <Grid item xs={6}>
          <h3 className="PopupGridItem">{props.attribute}</h3>
        </Grid>
        <Grid item xs={3}>
          <h6 className="PopupGridItem" style={{ float: 'right' }}>
            {props.dataA}
          </h6>
        </Grid>
        <Grid item xs={3}>
          <h6 className="PopupGridItem" style={{ float: 'right' }}>
            {props.dataB}
          </h6>
        </Grid>
        <Grid item xs={12}>
          <hr
            style={{
              background: 'black',
              height: '2px',
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export function PopupGridItemWithComponent(props) {
  return (
    <>
      <Grid item sm={props.columnA}>
        <h3 className="PopupGridItem">{props.attribute}</h3>
      </Grid>
      <Grid item sm={props.columnB}>
        {props.data}
      </Grid>
      <Grid item xs={12}>
        <hr
          style={{
            background: 'black',
            height: '2px',
          }}
        />
      </Grid>
    </>
  );
}

export function PopupNullOrder() {
  return {
    active: '',
    completed: '',
    username: '',
    orderCode: '',
    submissionType: '',
    noOfSamples: '',
    dropOffCode: '',
    dateUploaded: '',
    dateDroppedOff: '',
    dropLocation: '',
    pickedUpStatus: '',
    stage: '',
    labName: '',
    researcherName: '',
    clientExcel: '',
    pcrBlock: '',
    cartridge: '',
    ssTimer: '',
    comboRun: '',
    inComboRun: '',
    billingCycle: '',
    billingStatus: '',
    SUMSBilling: '',
    notes: '',
    logs: '',
  };
}

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

export function PopupDownloadExcel(props) {
  const orderCode = props.orderCode;
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccess, setShowSucess] = useState(false);

  const downLoadFile = (orderCode) => {
    axios({
      url: API_ENDPOINT + '/order/download',
      method: 'GET',
      responseType: 'blob', // important
      params: {
        fileNo: orderCode,
      },
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        ); // you can use this to show user percentage of file downloaded
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setSuccessMessage('File downloaded sucessfully');
          setShowSucess(true);
          setShowError(false);
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        var fileName;
        fileName = orderCode + '.xlsx';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          setErrorMessage('File no longer exists!');
          setShowError(true);
          setShowSucess(false);
        }
      });
  };
  return (
    <>
      <Grid item xs={6}>
        <Button
          startIcon={<DownloadIcon />}
          className="PopupGridItem"
          variant="text"
          onClick={() => downLoadFile(orderCode)}
        >
          Download Excel File
        </Button>
      </Grid>
      {showError ? (
        <Grid item xs={6}>
          <Alert severity="error">{errorMessage}</Alert>
        </Grid>
      ) : null}
      {showSuccess ? (
        <Grid item xs={6}>
          <Alert severity="success" icon={<CheckIcon fontSize="inherit" />}>
            {successMessage}
          </Alert>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <hr
          style={{
            background: 'black',
            height: '2px',
          }}
        />
      </Grid>
    </>
  );
}

export function PopupUploadResults({
  orderCode,
  formikProps,
  noEmail,
  setNoEmail,
}) {
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [noFile, setNoFile] = useState(false);
  const disableFileInput = noFile || formikProps.values.hasResultFile;

  const handleFileCheckboxChange = (event) => {
    setNoFile(event.target.checked);
    if (event.target.checked && formikProps.values.hasResultFile) {
      removeFile();
    }
  };

  useEffect(() => {
    axios.get(API_ENDPOINT + `/resultsUpload/${orderCode}`).then((res) => {
      if (res.status === 200) {
        formikProps.setFieldValue('hasResultFile', true);
      }
    });
  }, []);

  const changeHandler = (event) => {
    const formData = new FormData();
    formData.append('resultsUploadBody', event.target.files[0]);
    axios
      .post(API_ENDPOINT + '/resultsUpload/' + orderCode, formData)
      .then((res) => {
        if (res.status === 200) {
          setIsError(false);
          setMessage('File uploaded successfully');
          formikProps.setFieldValue('hasResultFile', true);
        }
        return res.data;
      })
      .catch(function (error) {
        setIsError(true);
        if (error.response.status === 415) {
          setMessage('Please upload a zip file');
        } else if (error.response.status === 413) {
          setMessage('File is too large! Max size is 25 MB');
        } else {
          setMessage('Unknown error ocuurred, contact adminsitrator');
          console.log(error);
        }
      });
  };
  const removeFile = () => {
    axios
      .delete(API_ENDPOINT + '/resultsUpload/' + orderCode)
      .then((res) => {
        if (res.status === 200) {
          setIsError(false);
          setMessage('File removed succesfully');
          formikProps.setFieldValue('hasResultFile', false);
        }
      })
      .catch(function (error) {
        setIsError(true);
        setMessage('Unknown error ocuurred, contact adminsitrator');
        console.log(error);
      });
  };

  return (
    <>
      <Grid item xs={10}>
        <label style={{ display: 'block' }}>
          {formikProps.values.hasResultFile ? (
            <>
              <p>
                <b>You have already uploaded a file.</b>
                <br></br>
                Click Undo & Upload a new zip file to replace it.
              </p>
            </>
          ) : (
            <>
              <p style={{ textAlign: 'center' }}>Upload results zip file</p>
            </>
          )}
        </label>
      </Grid>
      <Grid item xs={2}>
        {formikProps.values.hasResultFile ? (
          <button
            type="button"
            onClick={removeFile}
            style={{
              display: 'inline-block',
              padding: '10px',
              border: '1px solid #ccc',
              backgroundColor: '#f0f0f0',
              cursor: 'pointer',
            }}
          >
            Undo
          </button>
        ) : (
          ''
        )}
      </Grid>
      <Grid item xs={6} paddingBottom={'10px'} paddingTop={'10px'}>
        <input
          type="file"
          name="resultsUploadBody"
          onChange={changeHandler}
          disabled={disableFileInput}
        />
      </Grid>
      {message && (
        <Grid item xs={6}>
          <Alert severity={isError ? 'error' : 'success'}>{message}</Alert>
        </Grid>
      )}
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <FormControlLabel
          control={
            <Checkbox
              value={noFile}
              checked={noFile}
              onChange={(event) => {
                formikProps.setFieldValue('noFile', event.target.checked);
                handleFileCheckboxChange(event);
                if (!event.target.checked) {
                  // always set to false to avoid confusion
                  setNoEmail(false);
                }
              }}
            />
          }
          label="I affirm that I do not intend to upload a zip file."
          labelPlacement="end"
        />
      </Grid>
      {formikProps.values.noFile && (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <FormControlLabel
            control={
              <Checkbox
                value={noEmail}
                checked={noEmail}
                onChange={(event) => {
                  setNoEmail(event.target.checked);
                }}
              />
            }
            label="Don't send an email to the client"
            labelPlacement="end"
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <hr
          style={{
            background: 'black',
            height: '2px',
          }}
        />
      </Grid>
    </>
  );
}

let PopupBillingCycleSemester = ({ name, value, setFieldValue, disabled }) => {
  const user = useContext(AuthContext);
  return (
    <>
      <Box style={{ float: 'right' }} sx={{ maxWidth: 120, minWidth: 100 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Semester</InputLabel>
          <Select
            value={value}
            label="Semester"
            onChange={(event) => setFieldValue(name, event.target.value)}
            disabled={user.admin === false || disabled}
          >
            <MenuItem value={'Fall'}>Fall</MenuItem>
            <MenuItem value={'Spring'}>Spring</MenuItem>
            <MenuItem value={'Summer'}>Summer</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
};
PopupBillingCycleSemester = withFieldFormik(PopupBillingCycleSemester);
export { PopupBillingCycleSemester };

let PopupBillingCycleYear = ({ name, value, setFieldValue, disabled }) => {
  const user = useContext(AuthContext);
  return (
    <Box style={{ float: 'right' }} sx={{ maxWidth: 120, minWidth: 80 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          views={['year']}
          label="Year"
          value={value}
          disabled={user.admin === false || disabled}
          onChange={(value) => {
            if (value === null) {
              setFieldValue(name, '1901');
            } else {
              setFieldValue(
                name,
                (parseInt(value.toString().substring(11, 15)) + 1).toString()
              );
            }
          }}
          renderInput={(params) => <TextField {...params} helperText={null} />}
        />
      </LocalizationProvider>
    </Box>
  );
};
PopupBillingCycleYear = withFieldFormik(PopupBillingCycleYear);
export { PopupBillingCycleYear };

let PopupNotes = ({ name, value, setFieldValue, disabled }) => {
  return (
    <TextField
      fullWidth={true}
      multiline
      value={value}
      style={{ float: 'right' }}
      onChange={(event) => setFieldValue(name, event.target.value)}
      disabled={disabled}
    />
  );
};
PopupNotes = withFieldFormik(PopupNotes);
export { PopupNotes };

let PopupPCRBlock = ({
  name,
  errors,
  touched,
  value,
  setFieldValue,
  disabled,
  showSelect,
}) => {
  if (value === '') {
    value = 'Not set';
  }
  return (
    <Grid container rowSpacing={0} spacing={0}>
      <Grid item xs={12} sm={4}>
        <h3 className="PopupGridItem">{'PCR Block'}</h3>
      </Grid>
      <Grid item xs={12} sm={4}>
        {touched.pcrBlock && Boolean(errors.pcrBlock) ? (
          <Alert severity="error">Required</Alert>
        ) : null}
      </Grid>
      {showSelect ? (
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <Select
              value={value}
              onChange={(event) => setFieldValue(name, event.target.value)}
              error={touched.pcrBlock && Boolean(errors.pcrBlock)}
              placeholder={'Select PCR Block'}
              disabled={disabled}
            >
              <MenuItem value={'Block 1'}>Block 1</MenuItem>
              <MenuItem value={'Block 2'}>Block 2</MenuItem>
              <MenuItem value={'Block 3'}>Block 3</MenuItem>
              <MenuItem value={'Plate PCR'}>Plate PCR</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      ) : (
        <Grid item xs={12} sm={4}>
          <h3 className="PopupGridItem" style={{ float: 'right' }}>
            {value}
          </h3>
        </Grid>
      )}
      <Grid item xs={12}>
        <hr
          style={{
            background: 'black',
            height: '2px',
          }}
        />
      </Grid>
    </Grid>
  );
};

PopupPCRBlock = withFieldFormik(PopupPCRBlock);
export { PopupPCRBlock };

export function PopupGridDateReceived(props) {
  const orderAliveRenderer = ({ days, hours, minutes, seconds }) => {
    // Render a countdown
    return (
      <span>
        {days > 3
          ? `${days} Days`
          : `${days} Days ${hours} hrs ${minutes} mins`}
      </span>
    );
  };
  return (
    <>
      <Grid container rowSpacing={0} spacing={0}>
        <Grid item xs={6}>
          <h3 className="PopupGridItem">Date Received</h3>
        </Grid>
        <Grid item xs={6}>
          <h6 className="PopupGridItem" style={{ float: 'right' }}>
            {props.order.dateDroppedOff.substring(0, 10)}
          </h6>
        </Grid>
        <Grid item xs={12}>
          <hr
            style={{
              height: '0px',
              margin: 7,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <h3 className="PopupGridItem">Time Since</h3>
        </Grid>
        <Grid item xs={6}>
          <div className="PopupGridItem" style={{ float: 'right' }}>
            <Countdown
              date={new Date(props.order.dateDroppedOff).getTime()}
              renderer={orderAliveRenderer}
              overtime={true}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <hr
            style={{
              background: 'black',
              height: '2px',
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

let PopupCartridge = ({
  name,
  errors,
  touched,
  value,
  setFieldValue,
  disabled,
  showSelect,
}) => {
  if (value === '') {
    value = 'Not set';
  }
  return (
    <Grid container rowSpacing={0} spacing={0}>
      <Grid item xs={12} sm={4}>
        <h3 className="PopupGridItem">Cartridge</h3>
      </Grid>
      <Grid item xs={12} sm={4}>
        {touched.cartridge && Boolean(errors.cartridge) ? (
          <Alert severity="error">Required</Alert>
        ) : null}
      </Grid>
      {showSelect ? (
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <Select
              value={value}
              onChange={(event) => setFieldValue(name, event.target.value)}
              error={touched.cartridge && Boolean(errors.cartridge)}
              placeholder={'Select Cartridge'}
              disabled={disabled}
            >
              <MenuItem value={'A'}>A</MenuItem>
              <MenuItem value={'B'}>B</MenuItem>
              <MenuItem value={'C(faulty)'}>C(faulty)</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      ) : (
        <Grid item xs={12} sm={4}>
          <h3 className="PopupGridItem" style={{ float: 'right' }}>
            {value}
          </h3>
        </Grid>
      )}
      <Grid item xs={12}>
        <hr
          style={{
            background: 'black',
            height: '2px',
          }}
        />
      </Grid>
    </Grid>
  );
};
PopupCartridge = withFieldFormik(PopupCartridge);
export { PopupCartridge };

let PopupSSTimer = ({
  name,
  errors,
  touched,
  value,
  setFieldValue,
  disabled,
  showSelect,
  date,
  formikProps,
  stage,
}) => {
  const timerRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <Alert
          icon={<CheckIcon fontSize="inherit" />}
          severity="success"
          sx={{
            pt: 0,
            pb: 0,
            my: 0,
            height: 35,
          }}
        >
          Completed!
        </Alert>
      );
    } else {
      // Render a countdown
      return (
        <span>
          {days}:{hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  return (
    <Grid container rowSpacing={0} spacing={0}>
      <Grid item xs={12} sm={4}>
        <h3 className="PopupGridItem">SS Timer</h3>
      </Grid>
      {showSelect ? (
        <>
          <Grid item xs={12} sm={4}>
            {touched.ssTimer && Boolean(errors.ssTimer) ? (
              <Alert severity="error">{errors.ssTimer}</Alert>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="PopupGridItem" style={{ float: 'right' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  value={value}
                  onChange={(newValue) => {
                    setFieldValue(name, newValue);
                  }}
                  disabled={disabled}
                  ampm={false}
                  ampmInClock={false}
                />
              </LocalizationProvider>
            </div>
          </Grid>
          {formikProps.values.stage != 'Ready to load' ? (
            <>
              <Grid item xs={12} sm={4}>
                <h3 className="PopupGridItem">Time Left</h3>
              </Grid>
              <Grid sm={8}>
                <div className="PopupGridItem" style={{ float: 'right' }}>
                  <Countdown date={date} renderer={timerRenderer} />
                </div>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <Grid sm={8}>
          <div className="PopupGridItem" style={{ float: 'right' }}>
            <Countdown date={date} renderer={timerRenderer} />
          </div>
        </Grid>
      )}
      <Grid item xs={12}>
        <hr
          style={{
            background: 'black',
            height: '2px',
          }}
        />
      </Grid>
    </Grid>
  );
};
PopupSSTimer = withFieldFormik(PopupSSTimer);
export { PopupSSTimer };

let PopupPcrMachineTimer = ({
  name,
  errors,
  touched,
  value,
  setFieldValue,
  disabled,
  showSelect,
  date,
  formikProps,
}) => {
  const timerRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <Alert
          icon={<CheckIcon fontSize="inherit" />}
          severity="success"
          sx={{
            pt: 0,
            pb: 0,
            my: 0,
            height: 35,
          }}
        >
          Completed!
        </Alert>
      );
    } else {
      // Render a countdown
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={4}>
        <h3 className="PopupGridItem">PCR Machine Timer</h3>
      </Grid>
      {showSelect ? (
        <>
          <Grid item xs={12} sm={8}>
            <div className="PopupGridItem" style={{ float: 'right' }}>
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    value={formikProps.values.manualPcrMachineTimer}
                    checked={formikProps.values.manualPcrMachineTimer}
                    disabled={disabled}
                    onChange={(event) => {
                      setFieldValue(
                        'manualPcrMachineTimer',
                        event.target.checked
                      );
                    }}
                  />
                }
                label="Manually set PCR Machine Timer?"
                labelPlacement="start"
              />
            </div>
          </Grid>
          {formikProps.values.manualPcrMachineTimer ? (
            <>
              <Grid item xs={12} sm={4}>
                <h3 className="PopupGridItem">
                  What time did you start the PCR Machine?
                </h3>
              </Grid>
              <Grid item xs={12} sm={4}>
                {touched.pcrMachineTimer && Boolean(errors.pcrMachineTimer) ? (
                  <Alert severity="error">{errors.pcrMachineTimer}</Alert>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="PopupGridItem" style={{ float: 'right' }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      renderInput={(props) => <TextField {...props} />}
                      value={value}
                      onChange={(newValue) => {
                        setFieldValue(name, newValue);
                      }}
                      disabled={disabled}
                      ampm={false}
                      ampmInClock={false}
                      maxDateTime={new Date()}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
            </>
          ) : (
            <></>
          )}
          {formikProps.values.stage !== 'PCR Prep' ? (
            <>
              <Grid item xs={12} sm={4}>
                <h3 className="PopupGridItem">Time Left</h3>
              </Grid>
              <Grid sm={8}>
                <div className="PopupGridItem" style={{ float: 'right' }}>
                  <Countdown date={date} renderer={timerRenderer} />
                </div>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <Grid sm={8}>
          <div className="PopupGridItem" style={{ float: 'right' }}>
            <Countdown date={date} renderer={timerRenderer} />
          </div>
        </Grid>
      )}
      <Grid item xs={12}>
        <hr
          style={{
            background: 'black',
            height: '2px',
          }}
        />
      </Grid>
    </Grid>
  );
};
PopupPcrMachineTimer = withFieldFormik(PopupPcrMachineTimer);
export { PopupPcrMachineTimer };

let PopupSubmissionType = ({
  name,
  errors,
  touched,
  value,
  setFieldValue,
  disabled,
  showSelect,
}) => {
  if (value === '') {
    value = 'Not set';
  }
  return (
    <Grid container rowSpacing={0} spacing={0}>
      <Grid item xs={12} sm={4}>
        <h3 className="PopupGridItem">{'Submission type'}</h3>
      </Grid>
      <Grid item xs={12} sm={4}>
        {touched.submissionType && Boolean(errors.submissionType) ? (
          <Alert severity="error">Required</Alert>
        ) : null}
      </Grid>
      {showSelect ? (
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <Select
              value={value}
              onChange={(event) => {
                setFieldValue(name, event.target.value);
                if (event.target.value === 'fragment') {
                  setFieldValue('sangerNoOfSamples', 0);
                } else if (event.target.value === 'sanger') {
                  setFieldValue('fragmentNoOfSamples', 0);
                } else {
                  //Keep values as they are
                }
              }}
              error={touched.submissionType && Boolean(errors.submissionType)}
              placeholder={'Submission type'}
              disabled={disabled}
            >
              <MenuItem value={'sanger'}>Sanger</MenuItem>
              <MenuItem value={'fragment'}>Fragment</MenuItem>
              <MenuItem value={'Mixed'}>Mixed</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      ) : (
        <Grid item xs={12} sm={4}>
          <h3 className="PopupGridItem" style={{ float: 'right' }}>
            {value}
          </h3>
        </Grid>
      )}
      <Grid item xs={12}>
        <hr
          style={{
            background: 'black',
            height: '2px',
          }}
        />
      </Grid>
    </Grid>
  );
};

PopupSubmissionType = withFieldFormik(PopupSubmissionType);
export { PopupSubmissionType };

let PopupNoSamples = ({
  name,
  value,
  setFieldValue,
  errors,
  touched,
  disabled,
  formikProps,
}) => {
  // <TextField
  // fullWidth={true}
  //   multiline
  //   value={value}
  //   style={{float: "right"}}
  //   onChange={(event) => setFieldValue(name, event.target.value)}
  //   disabled={disabled}
  // />
  return (
    <Grid container rowSpacing={0} spacing={0}>
      {formikProps.values.submissionType !== 'fragment' ? (
        <>
          <Grid item xs={12} sm={4}>
            <h3 className="PopupGridItem">No. of Sanger Samples</h3>
          </Grid>
          <Grid item xs={12} sm={4}>
            {touched.sangerNoOfSamples && Boolean(errors.sangerNoOfSamples) ? (
              <Alert severity="error">Required</Alert>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={4}>
            <h3 className="PopupGridItem">
              {formikProps.values.sangerNoOfSamples}
            </h3>
          </Grid>
        </>
      ) : (
        <></>
      )}
      {formikProps.values.submissionType != 'sanger' ? (
        <>
          <Grid item xs={12} sm={4}>
            <h3 className="PopupGridItem">No. Of Fragment Samples</h3>
          </Grid>
          <Grid item xs={12} sm={4}>
            {touched.fragmentNoOfSamples &&
            Boolean(errors.fragmentNoOfSamples) ? (
              <Alert severity="error">Required</Alert>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={4}>
            <h3 className="PopupGridItem">
              {formikProps.values.fragmentNoOfSamples}
            </h3>
          </Grid>
        </>
      ) : (
        <></>
      )}
      <Grid item xs={12}>
        <hr
          style={{
            background: 'black',
            height: '2px',
          }}
        />
      </Grid>
    </Grid>
  );
};
PopupNoSamples = withFieldFormik(PopupNoSamples);
export { PopupNoSamples };

let PopupOrdersInPlate = ({ orders, handleClose }) => {
  return (
    <>
      <Grid container rowSpacing={0} spacing={0}>
        <Grid xs={12} style={{ marginBottom: 20 }}>
          <h3 className="PopupGridItem">Orders In This Plate</h3>
        </Grid>
        {orders.map((order, i) => {
          return (
            <>
              <Grid container alignItems={'center'}>
                <Grid item xs={3}>
                  <h3 className="PopupGridItem">{order.orderCode}</h3>
                </Grid>
                <Grid item xs={3}>
                  <BillingPopup currentOrder={order} viewOnly={true} />
                </Grid>
                <Grid xs={3}>
                  <UnMergeCR
                    orderCode={order.orderCode}
                    onUnmergeCompleted={handleClose}
                  />
                </Grid>
                <Grid xs={3}>
                  <SampleListView orderCode={order.orderCode} />
                </Grid>
                <Grid item xs={12}>
                  <hr
                    style={{
                      height: '0px',
                      margin: 7,
                    }}
                  />
                </Grid>
              </Grid>
            </>
          );
        })}
      </Grid>
      <Grid item xs={12}>
        <hr
          style={{
            background: 'black',
            height: '2px',
          }}
        />
      </Grid>
    </>
  );
};

export { PopupOrdersInPlate };
