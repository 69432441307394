import axios from 'axios';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

export const getLabPrimers = async () => {
  var res = await axios({
    url: API_ENDPOINT + '/labPrimer/all',
    method: 'GET',
  });
  var data = res.data;
  for (var i = 0; i < data.length; i++) {
    data[i]['primerName'] = data[i].primerID + ' ' + data[i].name;
    data[i]['labPrimerId'] = data[i].primerID;
  }
  return data;
};
