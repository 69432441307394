import React, { useState } from 'react';
import './FaqComponent.css';
import FaqOverview from './FaqOverview';
import FaqCategory from './FaqCategory';

// import { Button } from '@material-ui/core';
// import Faq from 'react-faq-component';
// import pricingImage from '../../../images/faqPricing.png';
// import primerTableImage from '../../../images/faqPrimerTable.png';
// import Grid from '@mui/material/Grid';
// import requestMECLserviceOnSums
//   from '../../../files/Requesting a Molecular Evolution Core service on SUMS.pdf';

// const techGold = 'rgb(179, 163, 105)';
// const priceColor = 'rgb(125, 170, 185)';
// const blueTextColor = 'rgb(0, 48, 87)';
// const lightBlueTextColor = 'rgb(0, 138, 252)';
// const titleBackgroundColor = 'rgb(233, 235, 230)';
// const detailBackgroundColor = 'rgb(246, 249, 245)';

// const styles = {
//   titleTextSize: '3.09rem',
//   rowTitleTextSize: '18pt',
//   rowContentTextSize: '12pt',
//   rowTitleColor: blueTextColor,
//   titleTextColor: blueTextColor,
//   rowContentColor: blueTextColor,
// };

// function useWindowSize() {
//   const [size, setSize] = useState([0, 0]);
//   useLayoutEffect(() => {
//     function updateSize() {
//       setSize([window.innerWidth, window.innerHeight]);
//     }
//
//     window.addEventListener('resize', updateSize);
//     updateSize();
//     return () => window.removeEventListener('resize', updateSize);
//   }, []);
//   return size;
// }

// const API_ENDPOINT =
//   process.env.NODE_ENV === 'production'
//     ? process.env.REACT_APP_API_ENDPOINT_PROD
//     : process.env.REACT_APP_API_ENDPOINT_DEV;

export default function FaqComponent() {
  const [faqCategory, setFaqCategory] = useState(0);
  const [categoryVis, setCategoryVis] = useState(false);
  console.log(faqCategory);

  const updateFaqCategory = (index) => {
    // console.log(`Show ${index}`);
    setCategoryVis(true);
    setFaqCategory(index);
  };

  const testSetCategoryVis = (vis) => {
    // console.log(`Set categoryVis to ${vis}`);
    setCategoryVis(vis);
  };

  const setCategory = (category) => {
    console.log(`Setting FAQ category to ${category}`);
    setFaqCategory(category);
  };

  return (
    <div className="faq__container">
      <div className="faq__title">FAQ</div>
      <div className="faq__content">
        <FaqOverview
          setFaqCategory={updateFaqCategory}
          visibility={categoryVis}
        />
        <FaqCategory
          idx={faqCategory}
          vis={categoryVis}
          setVis={testSetCategoryVis}
          setCategory={setCategory}
        />
      </div>
    </div>
  );
}
