import { Grid } from '@mui/material';
import { Typography } from 'reusable/Typography';
import { ControlTableHeading } from './ControlTableComponents/ControlTableHeading';
import { ControlTableRow } from './ControlTableComponents/ControlTableRow';
import useControlsApi from 'hooks/useControlsApi';
import { EmptyTableRow } from 'reusable/EmptyTableRow';
import { ControlTableButtons } from './ControlTableComponents/ControlTableButtons';
import { useEffect, useRef, useState } from 'react';

export const ControlTable = ({ samples, setSamples, sampleMapId }) => {
  const [controls, setControls] = useState([]);
  const [availableControls, setAvailableControls] = useState([]);
  const [allControls, setAllControls] = useState([]);
  const { createControl, deleteControl, getControls } = useControlsApi(
    sampleMapId,
    availableControls,
    setSamples
  );

  /**
   * Move a control from startIdx to endIdx
   */
  const moveControl = (startIdx, endIdx) => {
    setControls((controls) => {
      const newControls = [...controls];
      const movedControl = newControls[startIdx];
      newControls.splice(startIdx, 1);
      newControls.splice(endIdx, 0, movedControl);
      return newControls;
    });
  };

  /**
   * Delete the control at the given index
   */
  const removeControl = (index) => () => {
    deleteControl(controls[index]._id);
  };

  /**
   * Get available controls
   */
  const getAvailableControls = async () => {
    const all = await getControls();
    setAllControls(all);
    setAvailableControls(all.filter((control) => control.archived !== true));
  };

  useEffect(() => {
    getAvailableControls();
  }, []);

  useEffect(() => {
    // set currently used controls
    const existingControlIds = controls
      .map((control) => control._id)
      .filter((control, index, arr) => arr.indexOf(control) === index); // remove duplicates
    const newControlIds = samples
      .map((sample) => sample.controlId)
      .filter((controlId) => controlId !== undefined)
      .filter((control, index, arr) => arr.indexOf(control) === index); // remove duplicates
    const newControls = [...controls].filter(
      (control) => control._id in newControlIds
    );
    newControlIds
      .filter((controlId) => !(controlId in existingControlIds))
      .forEach((controlId) => {
        let control = allControls.find((control) => control._id === controlId);
        if (!control) return;
        newControls.push(control);
      });
    setControls(newControls);
  }, [availableControls, samples]);

  const widthRef = useRef(null);
  const [tableWidth, setTableWidth] = useState(0);

  useEffect(() => {
    if (widthRef.current) {
      setTableWidth(widthRef.current.offsetWidth);
    }
  }, [widthRef.current]);

  return (
    <Grid container sx={{ width: '100%' }}>
      <Grid item xs={12} sx={{ pl: 2 }}>
        <Typography variant="h2" sx={{ textAlign: 'left' }}>
          Control Table
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <table
          style={{
            position: 'relative',
            width: 'calc(100% - 80px)',
            left: 40,
          }}
        >
          <ControlTableHeading />
          <tbody>
            {controls.length === 0 ? (
              <EmptyTableRow cols={5} />
            ) : (
              controls.map((control, i) => {
                if (!control) {
                  console.warn('control should not be undefined');
                  return <></>;
                }
                return (
                  <ControlTableRow
                    control={control}
                    moveControl={moveControl}
                    deleteControl={removeControl(i)}
                    key={i}
                    index={i}
                    tableWidth={tableWidth}
                  />
                );
              })
            )}
          </tbody>
        </table>
        <div style={{ width: '100%' }} ref={widthRef}></div>
      </Grid>
      <Grid item xs={12}>
        <ControlTableButtons
          controls={availableControls}
          createControl={createControl}
          refreshControls={getAvailableControls}
        />
      </Grid>
    </Grid>
  );
};
