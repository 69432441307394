import React, {useState, useRef, useEffect} from 'react';
import PropagateLoader from "react-spinners/PropagateLoader";
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { socket, SocketContext } from '../../context/socket';
import Grid from '@mui/material/Grid';
import RotateLoader from "react-spinners/RotateLoader";
import { Formik, useFormik, yupToFormErrors, Form, useField } from 'formik';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import "./styles.css"
import IconButton from '@mui/material/IconButton';
import PiSelector from "../pages/profile/piSelector"
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop'

import { universities } from '../../context/universities'

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV

function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
}

const Item = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
    float: 'left'
}));

export default function AddPI(props) {
    const [getLoad, setGetLoad] = useState(false);
    const [submitLoad, setSubmitLoad] = useState(false);
    const initialData = {
        university: '', universityTag: '', 
        piFirst: '',
        piMiddle: '',
        piLast: '',
        noMiddleInitial: false,
    }
    const [submitStatus, setSubmitStatus] = useState(false);
    const [submitResponse, setSubmitResponse] = useState(null);
    const [submitSucess, setSubmitSucess] = useState(true);
    const [PIexists, setPIexists] = useState(false);

    const handleClose = () => {
        setSubmitStatus(false);
    };

    const validationSchema = Yup.object({
        university: Yup.string().required('Required').nullable('Required'),
        universityTag: Yup.string(),
        noMiddleInitial: Yup.boolean(),
        piFirst: Yup.string('ur first name')
            .max(20, 'No more than 20 characters')
            .min(1, 'Must be at least one character')
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed')
            .required('Required'),
        piMiddle: Yup.string().when('noMiddleInitial', {
            is: (noMiddleInitial) => noMiddleInitial === false,
            then: Yup.string()
                .required('Required')
                .max(1, 'No more than 1 character')
                .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed'),
        }),
        piLast: Yup.string()
            .max(20, 'No more than 20 characters')
            .min(1, 'Must be at least one character')
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed')
            .required('Required'),
    })

    const capitalizeFirstLowercaseRest = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return (
        <Grid container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
        > 
            <Backdrop
                sx={{ color: '#fff', zIndex: 1 }}
                open={getLoad}
            >
                <PropagateLoader/>
            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: 1 }}
                open={submitStatus}
                onClick={handleClose}
            >
                {submitSucess ? (
                <Alert onClose={() => {setSubmitStatus(false); window.location.reload(false);}} icon={<CheckIcon fontSize="inherit" />} severity="success">
                {submitResponse}
                </Alert>
                ) : (
                <Alert onClose={() => {setSubmitStatus(false)}} severity="error">
                {submitResponse}
                </Alert>
                )}
            </Backdrop>               
            <Formik 
            initialValues={initialData} 
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={ async (values) => {
                setSubmitLoad(true);
                try {
                    var res = await axios.post(API_ENDPOINT + '/pi/add', values);
                    var status = res.status
                    console.log(status)
                    await sleep(1e3);
                    if (status === 201) {
                        setSubmitSucess(true);
                        setPIexists(true);
                        setSubmitResponse("PI sucessfully added!")
                    }
                } catch {
                    setSubmitSucess(false);
                    setSubmitResponse("Unsucessful! Please try again later or contact adminsitrator if problem persists")
                }
                setSubmitStatus(true);
                setSubmitLoad(false);
            }}>
                {(props) => (
                    <Form>
                        <Grid container spacing={0} width={600}>
                            <Grid item xs={12}>
                                <Item>
                                    <label> University</label>
                                    <Autocomplete
                                        disabled={submitLoad || PIexists}
                                        value={props.values.university}
                                        onChange={(e, value) => {
                                        props.setFieldValue('university', value.title);
                                        props.setFieldValue('universityTag', value.abbr);
                                        props.setFieldValue('PIName', '');
                                        }}
                                        disablePortal
                                        id="university"
                                        name="university"
                                        label="university"
                                        options={universities}
                                        getOptionLabel={(option) => {
                                        // e.g value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return option.title;
                                        }}
                                        sx={{ width: 281 }}
                                        renderInput={(params) => (
                                        <TextField
                                            type="universityProfile"
                                            {...params}
                                            placeholder="Search or select university"
                                            error={
                                            props.touched.university &&
                                            Boolean(props.errors.university)
                                            }
                                            helperText={
                                            props.touched.university &&
                                            props.errors.university
                                            }
                                        />
                                        )}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Item>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="piFirst"
                                        name="piFirst"
                                        value={props.values.piFirst}
                                        onChange={props.handleChange}
                                        label="First Name"
                                        type="dialogText"
                                        variant="standard"
                                        error={props.touched.piFirst && Boolean(props.errors.piFirst)}
                                        helperText={props.touched.piFirst && props.errors.piFirst}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Item>
                                    <TextField
                                        margin="dense"
                                        id="piMiddle"
                                        value={props.values.piMiddle}
                                        onChange={props.handleChange}
                                        disabled={props.values.noMiddleInitial}
                                        label="Midde Initial"
                                        type="dialogText"
                                        variant="standard"
                                        error={props.touched.piMiddle && Boolean(props.errors.piMiddle)}
                                        helperText={props.touched.piMiddle && props.errors.piMiddle}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Item>
                                    <TextField
                                        margin="dense"
                                        id="piLast"
                                        value={props.values.piLast}
                                        onChange={props.handleChange}
                                        label="Last Name"
                                        type="dialogText"
                                        variant="standard"
                                        error={props.touched.piLast && Boolean(props.errors.piLast)}
                                        helperText={props.touched.piLast && props.errors.piLast}
                                    />
                                </Item>
                            </Grid>
                            <Grid>
                                <Item>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            value={props.values.noMiddleInitial}
                                            checked={props.values.noMiddleInitial}
                                            disabled={submitLoad}
                                            onChange={(event) => {
                                            props.setFieldValue('noMiddleInitial', event.target.checked);
                                            props.setFieldValue('piMiddle', '');
                                            }}
                                        />
                                        }
                                        sx={{ paddingLeft: 2.5 }}
                                        label="I affirm to the best of my knowledge that my PI has no middle initial"
                                        labelPlacement="end"
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Item>
                                    <Box>
                                        <LoadingButton
                                            size="large"
                                            classes="profileSubmit"
                                            type="submit"
                                            variant="outlined"
                                            loading={submitLoad}
                                            loadingIndicator={<RotateLoader cssOverride={{left: "100%"}} size={7} margin={-15}/>}
                                            >
                                                Submit
                                        </LoadingButton>
                                    </Box>
                                </Item>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}
