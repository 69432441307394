import React, { useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Admin.css';
import PropTypes from 'prop-types';
import { socket, SocketContext } from '../../context/socket';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SnackbarProvider } from 'notistack';
import AuthContext from 'utils/auth/auth';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CurrentClientsTable from '../Clients/CurrentClientsTable';
import CurrentEmployeesTable from '../Employees/CurrentEmployeesTable';
import CurrentPIsTable from '../PIs/CurrentPIsTable';
import CurrentPrimersTable from '../Lab_Primers/CurrentPrimersTable';
import CurrentMachinesTable from '../Cartridges/TableMUI';
import AssignedLetters from '../Cartridges/AssignedLetters/AssignedLetters';
import AddPopup from 'reusable/AddPopup';
import ReassignPopup from './ReassignPopup';
import RemovePIPopup from '../PIs/RemovePIPopup';
import { ControlsPage } from '../Controls/ControlsTable';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AdminTab = styled(Tab)(`
    text-transform: none;
    font-size: 16px
`);

export default function ExecutiveCommands(props) {
  const user = useContext(AuthContext);
  const [value, setValue] = React.useState(0);
  const { tabIndex } = useParams();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    navigate(`/ExecutiveCommands/${newValue}`);
  };

  useEffect(() => {
    setValue(parseInt(tabIndex));
  }, [tabIndex]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <SocketContext.Provider value={socket}>
        <SnackbarProvider maxSnack={3}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <AdminTab label="Clients" {...a11yProps(0)} />
                <AdminTab label="PIs" {...a11yProps(1)} />
                <AdminTab label="Employees" {...a11yProps(2)} />
                <AdminTab label="Machines" {...a11yProps(3)} />
                <AdminTab label="Lab Primers" {...a11yProps(4)} />
                <AdminTab label="Controls" {...a11yProps(5)} />
                <AdminTab label="Email" {...a11yProps(6)} />
                <div style={{ marginLeft: 'auto' }}></div>
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Container>
                <Row xs="auto">
                  <Col>
                    <AddPopup type="client" />
                  </Col>
                  {/* <Col><ReassignPopup type="client"/></Col> */}
                </Row>
                <br></br>
                <Row>
                  <CurrentClientsTable />
                </Row>
              </Container>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Container>
                <Row xs="auto">
                  <Col>
                    <AddPopup type="pi" />
                  </Col>
                  <Col>
                    <ReassignPopup type="pi" />
                  </Col>
                  <Col>
                    <RemovePIPopup />
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <CurrentPIsTable />
                </Row>
              </Container>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Container>
                <Row xs="auto">
                  <Col>
                    <AddPopup type="employee" />
                  </Col>
                  <Col>
                    <AddPopup type="admin" />
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <CurrentEmployeesTable admin={false} id={0} />
                </Row>
                <br></br>
                <br></br>
                <Row>
                  <CurrentEmployeesTable admin={true} id={1} />
                </Row>
              </Container>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Container>
                <Row>
                  <AddPopup type="cartridge" />
                </Row>
                <br></br>
                <Row>
                  <AssignedLetters />
                </Row>
                <br></br>
                <Row>
                  <CurrentMachinesTable />
                </Row>
              </Container>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Container>
                <Row>
                  <AddPopup type="primer" />
                </Row>
                <br></br>
                <Row>
                  <CurrentPrimersTable />
                </Row>
              </Container>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Container>
                <Row>
                  <AddPopup type="control" />
                </Row>
                <br></br>
                <Row>
                  <ControlsPage />
                </Row>
              </Container>
            </TabPanel>
          </Box>
        </SnackbarProvider>
      </SocketContext.Provider>
    </Grid>
  );
}
