import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ProcessingOrder from './ProcessingOrder';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import { socket, SocketContext } from '../../../context/socket';
import { useSnackbar } from 'notistack';
import { SnackbarProvider } from 'notistack';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV
// uses the "dialog" component on the MUI website

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function BillingPopup(props) {
  //const [getLoad, setGetLoad] = useState(false);
  const [open, setOpen] = useState(false);
  //const[processingOrders,setProcessingOrders] = useState([])
  //const [ordersReceived, setOrderReceived] = useState(false);
  const catologNumberthis = props.products.catologNumber;
  //const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  /** 
      useEffect(()=>{
        socket.on('updateOthersProcessingOrder',(msg)=>{
          ////console.log("run");
          setOrderReceived(false);
          enqueueSnackbar(msg, { variant: 'info' });
        })
      },[])
      
      useEffect(()=>{
        socket.on('updateSelfProcessingOrder',()=>{
          //console.log("run");
          setOrderReceived(false);
          
        })
      },[])
      
      
       useEffect(()=>{
        socket.on('connect', () => //console.log(socket.id))
        socket.on('connect_error', ()=>{
          setTimeout(()=>socket.connect(),5000)
        })
        socket.on('disconnect',()=> //console.log("Server disconnected"))
      },[])
      
      
      
        useEffect(() => {
          getInitialData({setGetLoad,setProducts,setData,setOrderReceived,ordersReceived,products,enqueueSnackbar}).catch(console.error);
        }, [ordersReceived])

*/
  const handleClickOpen = () => {
    setOpen(true);
    //setGetLoad(true);
    //getInitialData({setGetLoad,setProcessingOrders,catologNumberthis}).catch(console.error);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SocketContext.Provider value={socket}>
      <SnackbarProvider maxSnack={3}>
        <div>
          <IconButton onClick={handleClickOpen}>
            <OpenInFullIcon />
          </IconButton>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              Processing Orders for {props.products.catologNumber}
            </BootstrapDialogTitle>
            <DialogContent>
              <ProcessingOrder products={props.products} />
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}></Button>
            </DialogActions>
          </BootstrapDialog>
        </div>
      </SnackbarProvider>
    </SocketContext.Provider>
  );
}

{
  /**<ProcessingOrder processingOrders = {processingOrders} products = {props.products}/> */
}
