import MUIDataTable from 'mui-datatables';
import CustomToolbar from './CustomToolbar';
import React, { useState, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import TotalQuantityRender from './TotalQuantityRender';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import LogButtonPopup from './Log/LogButtonPopup';
import BillingPopup from './ProcessingOrder/BillingPopup';
import EditButtonPopup from './Edit/EditButtonPopup';
import { socket } from 'context/socket';
import AuthContext from 'utils/auth/auth';
import DeleteConfirmation from 'reusable/DeleteConfirmation';
import axios from 'axios';
const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

export default function TableMUI(props) {
  const data = props.products;

  let dataToBeUsed = [];
  data.forEach((element) => {
    let oneRow = {};
    Object.keys(element).forEach((el) => {
      if (el === '_id' || el === 'unit') {
      } else if (el === 'totalQuantity' || el === 'triggerAmount') {
        let content = element[el] + ' ' + element.unit;
        oneRow = { ...oneRow, [el]: content };
      } else {
        ////console.log("key: " + el+": inital Data inside: " + element[el]);
        oneRow = { ...oneRow, [el]: element[el] };
      }
    });
    dataToBeUsed = [...dataToBeUsed, oneRow];
  });

  let initialData = {};
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [deleteInventoryItem, setdeleteInventoryItem] = useState(null);
  const [displayQSaveButton, setDisplayQSaveButton] = useState(false);
  let filterCato = props.filterListCato;
  let fitlerOrder = props.filterList;
  //const [open,setOpen] = useState()
  let isInEditingMode = false;

  const showDeleteModal = (deleteInventoryItem) => {
    setdeleteInventoryItem(deleteInventoryItem);
    setDeleteMessage(
      `Are you sure you want to delete ${deleteInventoryItem.catologNumber} ?`
    );
    setDisplayConfirmationModal(true);
  };
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleClose = () => {
    setSubmitStatus(false);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleOnBlur = (thisinventory, attri) => {
    //console.log("handleOnBlur")
    return async (event) => {
      setSubmitLoad(true);

      let newValue;
      if (attri == 'ordered') {
        newValue = event.target.checked;
        //console.log('attri:' + attri);
      } else {
        newValue = event.target.value;
      }

      if (attri == 'totalQuantity' || attri == 'triggerAmount') {
        newValue = Number(newValue);
      }
      let newInventory = { ...thisinventory, [attri]: newValue };
      try {
        var res = await axios.put(
          API_ENDPOINT + '/inventory/update',
          newInventory
        );
        var status = res.status;
        if (status === 201) {
          //setSubmitSucess(true);
          //setSubmitResponse("Update sucessfully!")
        } else if (status === 202) {
          setSubmitStatus(true);
          setSubmitSucess(false);
          setSubmitResponse(
            'catolog number already exist, please input another one'
          );
        }
      } catch {
        setSubmitStatus(true);
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      ////console.log('submit');
      ////console.log(thisinventory);
      ////console.log(JSON.stringify(newInventory));

      setSubmitLoad(false);
    };
  };

  const columns = [
    {
      name: 'catologNumber',
      label: 'Catolog Number',
      options: {
        filter: true,
        sort: true,
        filterList: props.filterListCato,
        setCellProps: () => ({ style: { minWidth: '60px', maxWidth: '60px' } }),
        //filterList:[]
      },
    },

    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { minWidth: '60px', maxWidth: '60px' } }),
      },
    },
    /** 
    {
      name: 'totalQuantity',
      label: 'Total Quantity',
      options: {
        filter: true,
        sort: true,
      },
    },
    */
    {
      name: 'totalQuantity',
      label: 'Total Quantity',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({ style: { minWidth: '60px', maxWidth: '60px' } }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          //let displayQSaveButton = false;
          return (
            <TotalQuantityRender
              thisinventory={data[dataIndex]}
              style={{ width: '70%' }}
              attri="totalQuantity"
            />
          );
        },
      },
    },

    {
      name: 'unit',
      label: 'Unit',
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'triggerAmount',
      label: 'Trigger Amount',

      options: {
        filter: true,
        sort: true,
        display: 'excluded',
      },
    },

    {
      name: 'orderStatus',
      label: 'Order Status',
      options: {
        filter: true,
        sort: true,
        filterList: props.filterList,

        setCellProps: () => ({ style: { minWidth: '50px', maxWidth: '50px' } }),
        customBodyRender: (value) => {
          return value === 'Need to Order' ? (
            <Alert
              variant="filled"
              severity="error"
              sx={{ width: '50px', height: '50px' }}
            ></Alert>
          ) : value === 'In Stock' ? (
            <Alert
              variant="filled"
              severity="success"
              sx={{ width: '50px', height: '50px' }}
            ></Alert>
          ) : (
            <Alert
              variant="filled"
              severity="warning"
              sx={{ width: '50px', height: '50px' }}
            ></Alert>
          );
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            //console.log(order);
            let val1;
            let val2;
            if (obj1.data === 'In Stock') {
              val1 = 3;
            } else if (obj1.data === 'Running Low') {
              val1 = 2;
            } else if (obj1.data === 'Need to Order') {
              val1 = 1;
            }

            if (obj2.data === 'In Stock') {
              val2 = 3;
            } else if (obj2.data === 'Running Low') {
              val2 = 2;
            } else if (obj2.data === 'Need to Order') {
              val2 = 1;
            }

            ////console.log('val1: ' + val1);
            ////console.log('val2: ' + val2);
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        },
      },
    },

    {
      name: 'manufacturer',
      label: 'Supplier',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { minWidth: '70px', maxWidth: '70px' } }),
      },
    },
    {
      name: 'location',
      label: 'Location',
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'notes',
      label: 'Notes',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: '170px', maxWidth: '170px' },
        }),
      },
    },
    {
      name: 'Processing Orders',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: '80px', maxWidth: '80px' } }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <IconButton>
              <BillingPopup products={data[rowIndex]} />
            </IconButton>
          );
        },
      },
    },
    {
      name: 'Operation',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <>
              <EditButtonPopup products={data[dataIndex]} />
              <br></br>
              <Button
                variant="outlined"
                onClick={() => showDeleteModal(data[dataIndex])}
                size="small"
              >
                {' '}
                Delete
              </Button>
              {/**<Button variant="outlined" onClick={handleDelete(data[dataIndex])} size = "small"> Delete</Button>*/}
            </>
          );
        },
      },
    },

    {
      name: 'Log',
      options: {
        filter: false,
        sort: false,

        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <>
              <LogButtonPopup
                products={data[dataIndex]}
                whichLog={data[dataIndex].catologNumber}
              />
            </>
          );
        },
      },
    },
  ];

  let user = useContext(AuthContext);
  const handleDelete = (thisinventory) => {
    return async (event) => {
      let contenttype;
      setSubmitLoad(true);
      try {
        var res = await axios.delete(API_ENDPOINT + '/inventory/delete', {
          data: thisinventory,
        });

        var status = res.status;

        ////console.log('status' + status);
        if (status === 201) {
          socket.emit(
            'deleteInventory',
            thisinventory.catologNumber,
            user.firstName,
            user.lastName
          );
          ////console.log('status == 201');
          setSubmitSucess(true);
          setSubmitResponse('Delete sucessfully!');
        }
      } catch {
        ////console.log('error');
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      ////console.log('content-type:' + contenttype);
      ////console.log('submit');
      ////console.log(thisinventory);
      setDisplayConfirmationModal(false);
      setSubmitStatus(true);
      setSubmitLoad(false);
    };
  };

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    pagination: false,
    onFilterChange: (columnChanged, filterList) => {
      //console.log(`onFilterChange columnChanged: ${columnChanged}`);
      if (columnChanged == 'catologNumber') {
        filterCato = filterList[0];
        ////console.log(typeof filterList[0])
        ////console.log(typeof filterList[1])

        props.CatologFilter(filterList[0]);
      }
      if (columnChanged == 'orderStatus') {
        fitlerOrder = filterList[0];

        props.deliverOrderStatus(filterList[0]);
      }
      //console.log(`filterList[0] without string: ${filterList[0]}`);
      //console.log(`filterList[0]: ${JSON.stringify(filterList[0])}`);

      //this.setState({ columns: getColumns(filterList[0]) });
    },

    customToolbar: () => {
      return <CustomToolbar products={data} />;
    },
  };

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={0}
        margin={0}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: 1 }}
          open={submitStatus}
          onClick={handleClose}
        >
          {submitSucess ? (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
            >
              {submitResponse}
            </Alert>
          ) : (
            <Alert
              onClose={() => {
                setSubmitStatus(false);
              }}
              severity="error"
            >
              {submitResponse}
            </Alert>
          )}
        </Backdrop>
      </Grid>
      <MUIDataTable
        title={'Inventory'}
        data={data}
        columns={columns}
        options={options}
      />
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleDelete}
        hideModal={hideConfirmationModal}
        id={deleteInventoryItem}
        message={deleteMessage}
      />
    </>
  );
}
