import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import RotateLoader from 'react-spinners/RotateLoader';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV

const filter = createFilterOptions();

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

async function getPI(setOptions, setPIsreceived, PIsreceived, universityName) {
  console.log(universityName);
  if (PIsreceived === false) {
    const res = await axios({
      url: API_ENDPOINT + '/pi/university',
      method: 'GET',
      params: {
        university: universityName,
      },
    });
    const data = res.data;
    if (data.length === 0) {
      data.push(['No options', 0]);
    }
    console.log(data);
    console.log('DATA');

    var names = data;
    var piNames = names.map((name) => ({ fullName: name[0], piid: name[1] }));
    var PIS = piNames.map((option) => {
      const fullName = option.fullName.split(' ');
      const firstLetter = fullName[fullName.length - 1][0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...option,
      };
    });
    console.log(PIS);
    setOptions(PIS);
    setPIsreceived(true);
    return;
  } else {
    //DO Nothing
  }
}

export default function PiSelector({
  formikProps,
  setFieldValue,
  disabled,
  PIexists,
  selectOnly,
}) {
  const { values } = formikProps;
  const { touched } = formikProps;
  const { errors } = formikProps;
  const [value, setValue] = React.useState(values.PIName);
  const [PIsreceived, setPIsreceived] = useState(false);
  const [open, toggleOpen] = React.useState(false);
  const [loaderOpen, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = loaderOpen && options.length === 0;
  
  if (!selectOnly) {
    selectOnly = false;
  }

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.
      await getPI(
        setOptions,
        setPIsreceived,
        PIsreceived,
        formikProps.values.university
      );

      if (active) {
        setOptions([...options]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
      setPIsreceived(false);
    }
  }, [loaderOpen]);

  const validationSchema = Yup.object({
    noMiddleInitial: Yup.boolean(),
    piFirst: Yup.string('ur first name')
      .max(20, 'No more than 20 characters')
      .min(1, 'Must be at least one character')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed')
      .required('Required'),
    piMiddle: Yup.string().when('noMiddleInitial', {
      is: (noMiddleInitial) => noMiddleInitial === false,
      then: Yup.string()
        .required('Required')
        .max(1, 'No more than 1 character')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed'),
    }),
    piLast: Yup.string()
      .max(20, 'No more than 20 characters')
      .min(1, 'Must be at least one character')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed')
      .required('Required'),
  });

  const capitalizeFirstLowercaseRest = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const formik = useFormik({
    initialValues: {
      piFirst: '',
      piMiddle: '',
      piLast: '',
      noMiddleInitial: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //DO NOT EDIT THIS FORMAT AT ALL. DUPLICATE PI'S WILL EXIST IF YOU DO
      setFieldValue(
        'PIName',
        capitalizeFirstLowercaseRest(formik.values.piFirst) +
          ' ' +
          (formik.values.piMiddle === ''
            ? ''
            : capitalizeFirstLowercaseRest(formik.values.piMiddle) + '. ') +
          capitalizeFirstLowercaseRest(formik.values.piLast)
      );
      setValue(
        formik.values.piFirst +
          ' ' +
          (formik.values.piMiddle === '' ? '' : formik.values.piMiddle + '. ') +
          formik.values.piLast
      );

      handleClose();
    },
  });
  const handleClose = () => {
    formik.values.piFirst = '';
    formik.values.piMiddle = '';
    formik.values.piLast = '';

    toggleOpen(false);
  };

  return (
    <React.Fragment>
      <Autocomplete
        disabled={disabled || PIexists === true}
        open={loaderOpen}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
          formik.setFieldValue('noMiddleInitial', false);
        }}
        value={values.PIName}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
          } else {
            if (newValue === null) {
              setFieldValue('PIName', '');
              setValue('');
              setFieldValue('piid', 0);
            } else {
              setValue(newValue);
              setFieldValue('PIName', newValue.fullName);
              setFieldValue('piid', newValue.piid);
            }
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '' && !selectOnly) {
            filtered.push({
              inputValue: params.inputValue,
              fullName: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="PIName"
        options={options.sort(
          (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
        )}
        loading={loading}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.fullName;
        }}
        getOptionDisabled={(option) => option.fullName === 'No options'}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        groupBy={(option) => option.firstLetter}
        renderOption={(props, option) => 
        <>
        <li {...props}>{option.fullName}
        <div style={{ marginLeft: 'auto', color: 'gray' }}>
        ID:{option.piid}
                </div></li>
        </>}
        sx={{ width: 280 }}
        freeSolo
        renderInput={(params) => (
          <TextField
            // value={values.PIName}
            error={touched.PIName && Boolean(errors.PIName)}
            helperText={touched.PIName && errors.PIName}
            type="textPI"
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <RotateLoader color="black" size={5} margin={-19} />
                  ) : <>
                                    ID:{formikProps.values.piid}
                                    </>}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            placeholder={selectOnly ? ("Search your PI") : ("Search or add your PI")}
          />
        )}
      />
      {!selectOnly ? (
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>Add a new PI</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Is your PI not included in the dropdown list? Please add them.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="piFirst"
              name="piFirst"
              value={formik.values.piFirst}
              onChange={formik.handleChange}
              label="First Name"
              type="dialogText"
              variant="standard"
              error={formik.touched.piFirst && Boolean(formik.errors.piFirst)}
              helperText={formik.touched.piFirst && formik.errors.piFirst}
            />
            <TextField
              margin="dense"
              id="piMiddle"
              value={formik.values.piMiddle}
              onChange={formik.handleChange}
              disabled={formik.values.noMiddleInitial}
              label="Midde Initial"
              type="dialogText"
              variant="standard"
              error={formik.touched.piMiddle && Boolean(formik.errors.piMiddle)}
              helperText={formik.touched.piMiddle && formik.errors.piMiddle}
            />
            <TextField
              margin="dense"
              id="piLast"
              value={formik.values.piLast}
              onChange={formik.handleChange}
              label="Last Name"
              type="dialogText"
              variant="standard"
              error={formik.touched.piLast && Boolean(formik.errors.piLast)}
              helperText={formik.touched.piLast && formik.errors.piLast}
            />
          </DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                value={formik.values.noMiddleInitial}
                checked={formik.values.noMiddleInitial}
                disabled={disabled}
                onChange={(event) => {
                  formik.setFieldValue('noMiddleInitial', event.target.checked);
                  formik.setFieldValue('piMiddle', '');
                }}
              />
            }
            sx={{ paddingLeft: 2.5 }}
            label="I affirm to the best of my knowledge that my PI has no middle initial"
            labelPlacement="end"
          />
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
      ) : ( <>
      </>)}
    </React.Fragment>
  );
}
