import React, {useState, useRef, useContext} from "react";
import Popup from "./Popup";
import { socket, SocketContext } from '../../context/socket';
import Modal from '@mui/material/Modal';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

// uses the "dialog" component on the MUI website

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function ClientsPopup(props) {
    const [open, setOpen] = useState(false);

    // BACKEND CALL TO RETRIEVE CLIENT INFORMATION

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        // socket.off('updateSaveCR');
        // socket.off('updateOrder');
        // socket.off('saveCR');
        // socket.off('orderSubmitted');
        // socket.off('submitCR')
    };

    return (
        <div>
            <IconButton onClick={handleClickOpen}>
                <OpenInFullIcon />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                style={{display:'flex'}}
            >
                <Popup currentClient = {props.currentClient} handleClose={handleClose} viewOnly={true}/>
            </Modal>
        </div>
    );
}