import React, { useState, useRef, useEffect } from 'react';
import OrderButton from './OrderButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { useDrop } from 'react-dnd';
import DndDropOrder from './DragDropOrder';

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

export default function ActiveOrders(props) {
  let currentOrders = props.currentOrders;
  console.log(currentOrders)
  // let mergeCR = props.mergeCR;
  // let setMergeCR = props.setMergeCR;

  let activeOrders = [];
  for (let i = 0; i < 7; i++) {
    const attri = 'stage';

    let temp = currentOrders.filter((currentOrder) => {
      let contain = false;
      let title;
      // let inComboRun = false;
      if (i === 0) {
        title = 'arrived';
      } else if (i === 1) {
        title = 'pcrprep';
      } else if (i === 2) {
        title = 'pcrmachine';
      } else if (i === 3) {
        title = 'purification';
      } else if (i == 4) {
        title = 'readytoload';
        // inComboRun = true;
      } else if (i == 5) {
        title = 'ssmachine';
      } else {
        title = 'resulting';
        // inComboRun = false;
      }
      if (
        String(currentOrder[attri])
          .toLowerCase()
          .replace(/\s+/g, '')
          .replace(/-/g, '')
          .includes(title)
      ) {
        contain = true;
      }
      return contain;
    });
    // if ((i === 4 || i === 5) && mergeCR) {
    //   var unique = [...new Set(temp.map((item) => item.comboRunNo))];
    //   console.log(unique);
    //   temp = temp.filter((order) => order.inComboRun !== true);
    //   unique = unique.filter((cr) => cr !== undefined);
    //   for (let j = 0; j < unique.length; j++) {
    //     if (i === 4) {
    //       temp.push({
    //         orderCode: 'CR' + unique[j],
    //         isComboRun: true,
    //         comboRunNo: unique[j],
    //         stage: 'Ready to load',
    //       });
    //     } else {
    //       temp.push({
    //         orderCode: 'CR' + unique[j],
    //         isComboRun: true,
    //         comboRunNo: unique[j],
    //         stage: 'SS Machine',
    //       });
    //     }
    //   }
    // }
    temp.push({ orderCode: '.', temp: true });
    temp.push({ orderCode: '.', temp: true });
    activeOrders.push(temp);
  }
  console.log(activeOrders[1]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container align="center" justify="center">
          {Array.from(Array(7)).map((_, index) => (
            <>
              <Grid item lg={12 / 7.2} xs={5.95} md={3.95}>
                <Box sx={{ flexGrow: 1 }}>
                  {index === 0 ? (
                    <Div>{'Arrived'}</Div>
                  ) : index === 1 ? (
                    <Div>{'PCR PREP'}</Div>
                  ) : index === 2 ? (
                    <Div>{'PCR Machine'}</Div>
                  ) : index === 3 ? (
                    <Div>{'Purification'}</Div>
                  ) : index == 4 ? (
                    <Div>{'READY TO LOAD'}</Div>
                  ) : index === 5 ? (
                    <Div>{'SS MACHINE'}</Div>
                  ) : (
                    <Div>{'Resulting'}</Div>
                  )}
                  <DndDropOrder
                    key={index}
                    stage={index}
                    activeOrders={activeOrders}
                    index={index}
                  ></DndDropOrder>
                </Box>
              </Grid>
              <Grid item xs={0.05} key={2 * index + 1}>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ height: '100%', width: '2px' }}
                />
              </Grid>
            </>
          ))}
        </Grid>
      </Box>
    </>
  );
}
