import { Button } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { useControls } from '../hooks/useControls';

export const ArchivedTable = ({ archived, refreshControls }) => {
  const { unarchiveControl } = useControls();

  const doUnarchiveControl = (index) => async () => {
    const control = archived[index];
    await unarchiveControl(control);
    await refreshControls();
  };

  const columns = [
    {
      label: 'Name',
      name: 'name',
    },
    {
      label: 'Type',
      name: 'type',
    },
    {
      label: 'Prep Date',
      name: 'prepDat',
    },
    {
      label: 'Template Vol (µL)',
      name: 'templateVol',
    },
    {
      label: 'Primer Vol (µL)',
      name: 'primerVol',
    },
    {
      label: 'Operations',
      name: 'operations',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 8,
              }}
            >
              <Button
                size="small"
                variant="outlined"
                onClick={doUnarchiveControl(dataIndex)}
              >
                Unarchive
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'multiselect',
    print: false,
    viewColumns: false,
    responsive: 'standard',
    selectableRowsHideCheckboxes: true,
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'primary',
        variant: 'outlined',
        className: 'testClass123',
      };
    },
  };

  return (
    <MUIDataTable
      title="Archived Controls"
      columns={columns}
      options={options}
      className="ControlsTable"
      data={archived.map((control) => [
        control.name,
        control.type,
        control.prepDate
          ? new Date(control.prepDate).toLocaleDateString('en-us', {
              timeZone: 'UTC',
            })
          : '',
        control.templateVol,
        control.primerVol,
      ])}
    />
  );
};
