import { React, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ReassignLetter from './ReassignLetter';
// uses the "dialog" component on the MUI website

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, close, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {close ? (
                <IconButton
                    aria-label="close"
                    onClick={() => close()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    close: PropTypes.func.isRequired,
};

export default function ReassignLetterPopup(props) {
    const [openPopup, setOpenPopup] = useState(false);

    // BACKEND CALL TO RETRIEVE ORDER INFORMATION
    return (
        <div>
            <div 
                onClick={() => setOpenPopup(true)}
                style={{ cursor: 'pointer'}}
            > 
                <h1>{props.element.letter}</h1>
            </div>
            
            <BootstrapDialog
                close={()=>setOpenPopup(false)}
                aria-labelledby="customized-dialog-title"
                open={openPopup}
            >
                <BootstrapDialogTitle id="customized-dialog-title" close={()=>setOpenPopup(false)}>
                    Reassign Letter {props.element.letter}
                </BootstrapDialogTitle>
                <DialogContent>
                    <ReassignLetter element = {props.element} />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setOpenPopup(false)}>
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
