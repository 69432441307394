import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { socket, SocketContext } from '../../../context/socket';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import RotateLoader from 'react-spinners/RotateLoader';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import DeleteLog from './DeleteLog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';



import axios from 'axios';
import DeleteLogDateRange from './DeleteLogDateRange';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV


// uses the "dialog" component on the MUI website

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '650px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function ArchiveLogFatherButton(props) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('ALL LOGS');
    const handleRadioChange = (event) => {
        setValue(event.target.value);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
           
            <Button size = "small" variant="outlined" onClick={handleClickOpen}>{'ARCHIVE LOGS'}</Button>
             
            <div>
                <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
               
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                {'Archive Logs For ' + props.whichLog}
                </BootstrapDialogTitle>
                <DialogContent>
                    <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
                        <RadioGroup
                       
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={handleRadioChange}
                        >
                        
                        <FormControlLabel value="ALL LOGS" control={<Radio />} label="ARCHIVE ALL LOGS" />
                        <FormControlLabel value="LOGS AFTER 30 DAYS" control={<Radio />} label="ARCHIVE LOGS AFTER 30 DAYS" />
                        {/** 
                        <FormControlLabel value="LOGS AFTER 60 DAYS" control={<Radio />} label="ARCHIVE LOGS AFTER 60 DAYS" />
                        <FormControlLabel value="LOGS AFTER 90 DAYS" control={<Radio />} label="ARCHIVE LOGS AFTER 90 DAYS" />
                        */}

                        <FormControlLabel value="LOGS BEFORE A DATE" control={<Radio />} label="ARCHIVE LOGS BEFORE A DATE" />
                        <FormControlLabel value="LOGS AFTER A DATE" control={<Radio />} label="ARCHIVE LOGS AFTER A DATE" />
                        <FormControlLabel value="LOGS IN A CERTAIN RANGE" control={<Radio />} label="ARCHIVE LOGS IN A CERTAIN RANGE" />
                    </RadioGroup>
                    </FormControl>

                    <br></br>
                    <br></br>
                    {value == "ALL LOGS" ? <DeleteLog hideRange = {"ALL LOGS"} products={props.products} whichLog = {props.whichLog}/> : 
                    value == "LOGS AFTER 30 DAYS" ? <DeleteLog hideRange = {"LOGS AFTER 30 DAYS"} products={props.products} whichLog = {props.whichLog}/> :
                    value == "LOGS AFTER 60 DAYS" ? <DeleteLog hideRange = {"LOGS AFTER 60 DAYS"} products={props.products} whichLog = {props.whichLog}/> :
                    value == "LOGS AFTER 90 DAYS" ? <DeleteLog hideRange = {"LOGS AFTER 90 DAYS"} products={props.products} whichLog = {props.whichLog}/> :  
                    value == "LOGS BEFORE A DATE" ? <DeleteLog hideRange = {"LOGS BEFORE A DATE"} products={props.products} whichLog = {props.whichLog}/> :
                    value == "LOGS AFTER A DATE" ? <DeleteLog hideRange = {"LOGS AFTER A DATE"} products={props.products} whichLog = {props.whichLog}/> :
                    <DeleteLog hideRange = {"LOGS IN A CERTAIN RANGE"} products={props.products} whichLog = {props.whichLog}/>
                    }

                    {/**<DeleteLogDateRange hideRange = {props.hideRange} products={props.products} whichLog = {props.whichLog}/>*/}
                    {/** <PopupUpdateCopy hideRange = {props.hideRange} products={props.products} whichLog = {props.whichLog}/>*/}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                    </Button>
                </DialogActions>
            </BootstrapDialog>
                    
            </div>

        </div>
    );
}
