import { Button, TextField, Divider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UserAutoComplete from './UsersDropDown'; 
import OrderStageAutocomplete from './OrdersStagesDropDown';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

const NewEventForm = ({ startDate, endDate, setDisplayModalNew }) => {
    const [title, setTitle] = useState();
    const [description, setDescription] = useState(); 
    const [startDateTime, setStartDateTime] = useState(startDate);
    const [endDateTime, setEndDateTime] = useState(endDate);
    // Those useEffect functions help initialize startDateTime and endDateTime based on the chosen slot
    useEffect(() => { setStartDateTime(startDate) }, [startDate] ); 
    useEffect(() => { setEndDateTime(endDate) }, [endDate] ); 

    const [ordersForCalendar, setOrdersForCalendar] = useState([]); 
    const [usersForCalendar, setUsersForCalendar] = useState([]); 
    const [loadingOrders, setLoadingOrders] = useState(true);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const testArray = ["One", "Two", "Three"];
    useEffect(() => {
        fetchOrdersForCalendar(); 
        fetchUsersForCalendar(); 
    }, []); 

    const fetchOrdersForCalendar = async () => {
        const res = await axios.get(API_ENDPOINT + '/order/ordersForCalendar'); 
        if (res.status === 200) {
            setOrdersForCalendar(res.data); 
            setLoadingOrders(false);
        } else {
            console.log('Error in getting Orders for calendar...')
        }
    }; 

    const fetchUsersForCalendar = async () => {
        const res = await axios.get(API_ENDPOINT + '/user/all'); 
        if (res.status === 200) {
            setUsersForCalendar(res.data); 
            setLoadingUsers(false);
        } else {
            console.log('Error in getting Users for calendar...')
        }
    }; 

    const [openUsers, setOpenUsers] = React.useState(false);
    const [openOrder, setOpenOrder] = React.useState(false); 
    const [submitLoadUsers, setSubmitLoadUsers] = useState(false);
    const [submitLoadOrder, setSubmitLoadOrder] = useState(false); 
    const loadingUsersDropdown = openUsers && usersForCalendar.length === 0;  
    const loadingOrderDropdown = openOrder && ordersForCalendar.length === 0;  
    const [selectedUsers, setSelectedUsers] = useState();
    const [selectedOrder, setSelectedOrder] = useState(); 
    const formik = useFormik({
        initialValues: {
          users: [], 
          order: []
        },
        validationSchema: Yup.object({
          users: Yup.array()
            .min(1, 'Must choose at least 1 user/technician')
            .required('Required'), 
          order: Yup.array()
            .min(1, 'Must choose only ONE order')
            .max(1, 'Must choose only ONE order')
            .required('Required')
        }), 
        onSubmit: async (values) => {
            let stageOfOrder = ""
            let orderChosenCode = ""
            let orderDropLocation = ""
            let orderNoOfSamples = null; 
            for (let i = 0; i < ordersForCalendar.length; ++i) {
                if (selectedOrder[0]._id === ordersForCalendar[i]._id) {
                    stageOfOrder = ordersForCalendar[i].stage; 
                    orderChosenCode = ordersForCalendar[i].orderCode; 
                    orderDropLocation = ordersForCalendar[i].dropLocation ? ordersForCalendar[i].dropLocation : "N/A"; 
                    orderNoOfSamples = ordersForCalendar[i].noOfSamples; 
                    break; 
                }
            }
    
            let finalDescription = ""
            if (description === undefined) {
                finalDescription = "" + "Order: " + orderChosenCode + "\nSample Count: " + orderNoOfSamples + "\nDrop Location: " + orderDropLocation + "\nWork format: In person, at EBB, Room B160."; 
            } else {
                finalDescription = description + "\nOrder: " + orderChosenCode + "\nSample Count: " + orderNoOfSamples + "\nDrop Location: " + orderDropLocation + "\nWork format: In person, at EBB, Room B160."; 
            }
    
            let selectedUsersIds = []; 
            for (let i = 0; i < selectedUsers.length; ++i) {
                selectedUsersIds.push(selectedUsers[i]._id); 
            }
            
            // console.log(startDateTime); 
            // console.log(endDateTime);  
            
            const event = {
                orderId: selectedOrder[0]._id,
                receivingUserId: selectedUsersIds, 
                start: startDateTime,
                end: endDateTime,
                title: title,
                stage: stageOfOrder, 
                description: finalDescription
            };
            const res = await axios.post(API_ENDPOINT + '/calendar', event);
    
    
            if (res.status === 200) {
                alert('Create New Event Successfully!');
                setDisplayModalNew(false);
                setSubmitLoadUsers(true); 
                setSubmitLoadOrder(true); 
            } else {
                alert('Error in creating new event!');
                setSubmitLoadUsers(false); 
                setSubmitLoadOrder(false); 
            }
        }
    });
    
    const handleChangeUsers = (event, newValues) => {
        formik.setFieldValue('users', newValues);
        setSelectedUsers(newValues); 
    };

    const handleChangeOrder = (event, newValue) => {
        formik.setFieldValue('order', newValue); 
        setSelectedOrder(newValue); 
    }

    if (loadingOrders || loadingUsers) {
        return (
            <h2>Loading Data for Calendar ...</h2>
        )
    }

    return ( 
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <h2>New Event</h2>
            <TextField
                label="Event Name"
                onChange={(e) => setTitle(e.target.value)}
                required
                variant="outlined"
                color="secondary"
                type="Title"
                sx={{ mb: 3 }}
                fullWidth
                value={title}
            />
            <Divider component="li" style={{marginBottom: 15, borderBottomWidth: 3, borderBottomColor: 'gray'}}/>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DateTimePicker
                label="Start"
                value={startDateTime}
                onChange={(newValue) => setStartDateTime(newValue)}
                renderInput={(params) => <TextField {...params} sx={{ mb: 3 }} />}
                />
                <DateTimePicker
                label="End"
                value={endDateTime}
                onChange={(newValue) => setEndDateTime(newValue)}
                renderInput={(params) => <TextField {...params} sx={{ mb: 3 }} />}
                />
            </LocalizationProvider>
            <Divider component="li" style={{marginBottom: 15, borderBottomWidth: 3, borderBottomColor: 'gray'}}/>
            <div style={{marginBottom: 15}} >
                <h6>Choose an order to assign:</h6>
                {/* <select id="select-order-calendar" required>
                    {ordersForCalendar.map((eachOrder) => {
                        return <option value={eachOrder._id}>{eachOrder.orderCode} - Stage: {eachOrder.stage}</option>
                    })}
                </select> */}
                <OrderStageAutocomplete
                    value={formik.values.order}
                    onChange={(event, value) => handleChangeOrder(event, value)}
                    open={openOrder}
                    setOpen={setOpenOrder}  // This prop assumes that the OrderAutocomplete component accepts this prop to handle open state changes
                    submitLoad={submitLoadOrder}
                    options={ordersForCalendar}
                    loading={loadingOrderDropdown}
                    error={formik.touched.order && Boolean(formik.errors.order)}
                    helperText={formik.touched.order && formik.errors.order}
                />
            </div>
            <div style={{marginBottom: 15}} >
                <h6>Assign to technicians:</h6>
                <UserAutoComplete
                    value={formik.values.users}
                    onChange={(event, value) => handleChangeUsers(event, value)}
                    open={openUsers}
                    setOpen={setOpenUsers}  // This prop assumes that the OrderAutocomplete component accepts this prop to handle open state changes
                    submitLoad={submitLoadUsers}
                    options={usersForCalendar}
                    loading={loadingUsersDropdown}
                    error={formik.touched.users && Boolean(formik.errors.users)}
                    helperText={formik.touched.users && formik.errors.users}
                />
            </div>
            <Divider component="li" style={{marginBottom: 15, borderBottomWidth: 3, borderBottomColor: 'gray'}}/>
            <TextField
                label="Notes"
                onChange={(e) => setDescription(e.target.value)}
                variant="outlined"
                color="secondary"
                sx={{ mb: 3 }}
                type="Title"
                fullWidth
                value={description}
                multiline={true}
                rows={5}
            />
            <Button 
                variant="contained"
                type="submit" 
                style={{backgroundColor: "#008AFC", color: 'white'}} 
            >
                Create Event
            </Button>
        </form>
    );
    
};

export default NewEventForm;