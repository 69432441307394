import Box from '@mui/material/Box';
import { Typography } from 'reusable/Typography';
import { TextField } from 'reusable/TextField';

export const SangerClientNotes = ({ clientNotes, setClientNotes }) => {
  const textFieldStyles = {
    '& .MuiInputBase-input': {
      minHeight: 100,
    },
    '& :after': {
      border: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  };

  return (
    <Box sx={{ paddingX: 4 }}>
      <Typography variant="h2" textAlign="left">
        Submission Notes
      </Typography>
      <TextField
        multiline
        size="large"
        placeholder="Add any submission comments here."
        value={clientNotes}
        sx={textFieldStyles}
        onChange={(e) => setClientNotes(e.target.value)}
      />
    </Box>
  );
};
