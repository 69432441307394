import { Box, Tab, Tabs } from '@mui/material';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { Typography } from 'reusable/Typography';

export const Notes = ({ notes }) => {
  const [tab, setTab] = useState(0);

  const handleChange = (_, newTab) => {
    setTab(newTab);
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ pl: 2 }}>
        <Typography variant="h2" sx={{ textAlign: 'left' }}>
          Notes
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ m: 2, border: '1px solid #0030579E', borderRadius: 2 }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={handleChange}>
            {notes &&
              notes.map((order, i) => (
                <Tab label={order.orderCode} value={i} key={i} />
              ))}
          </Tabs>
        </Box>
        {notes &&
          notes.map((order, i) => (
            <TabPanel tab={tab} index={i} key={i}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Typography variant="h3" sx={{ textAlign: 'left' }}>
                    Notes
                  </Typography>
                  <Typography variant="p" sx={{ pl: 1 }}>
                    {order.notes ? order.notes : 'None'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Typography variant="h3" sx={{ textAlign: 'left' }}>
                    Client Notes
                  </Typography>
                  <Typography variant="p" sx={{ pl: 1 }}>
                    {order.clientNotes ? order.clientNotes : 'None'}
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
          ))}
      </Grid>
    </Grid>
  );
};

const TabPanel = ({ tab, index, children }) => {
  return (
    <div role="tabpanel" hidden={tab !== index}>
      {tab === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};
