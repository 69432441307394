import React, { useState } from 'react';
import axios from 'axios';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import RotateLoader from 'react-spinners/RotateLoader';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV

const UnMergeCR = ({ orderCode, onUnmergeCompleted }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleUnmerge = async () => {
        setLoading(true);
        try {
            const res = await axios({
                url: API_ENDPOINT + '/order/unmergeCR', // Update this endpoint to match your API
                method: 'PUT',
                params: {
                    orderCode: orderCode
                }
            });
            if (res.status === 200) {
                // You can handle response data or set states as required
                onUnmergeCompleted && onUnmergeCompleted(res.data);
            }
        } catch (error) {
            console.error("Error unmerging:", error);
            // Handle errors as needed
        } finally {
            setLoading(false);
            handleClose();
        }
    };

    return (
        <div>
            <Button color="primary" variant='contained' onClick={handleOpen}>
                Unmerge
            </Button>
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle>Unmerge Order</DialogTitle>
                <DialogContent>
                    Are you sure you want to unmerge order code {orderCode} from the combo run?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={loading}>
                        Cancel
                    </Button>
                    <Button onClick={handleUnmerge} disabled={loading} color="primary">
                        {loading ? <RotateLoader size={5} margin={-20}/> : 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default UnMergeCR;
