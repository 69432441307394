import { Box } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';
import { useRef, useState } from 'react';
import {
  ColorLuminance,
  deterministicallyRearrange,
  stringToColour,
} from 'reusable/Color';

export const Well = ({
  sample,
  index,
  move,
  broken,
  usedSections,
  dragging: globalDragging,
  setDragging: setGlobalDragging,
  canDrag,
  width,
}) => {
  const [hovering, setHovering] = useState(false);
  const [dragging, setDragging] = useState(false);
  const contentRef = useRef(null);
  const containerRef = useRef(null);

  const draggable = sample !== undefined;
  const wellColor = (() => {
    if (!sample) {
      return 'transparent';
    } else if (sample.type === 'control') {
      return 'red';
    } else if (sample.primer) {
      return ColorLuminance(
        stringToColour(deterministicallyRearrange(sample.primer)),
        0.5
      );
    } else {
      return 'white';
    }
  })();

  const error = (() => {
    const err = [];
    if (broken) err.push('Broken capillary');
    if (
      sample === undefined &&
      !broken &&
      usedSections &&
      usedSections[Math.floor(index / 4)]
    )
      err.push('Missing sample/control');
    return err;
  })();

  const onDragStart = (e) => {
    e.dataTransfer.setData('dragIndex', String(index));
    setDragging(true);
    setGlobalDragging(true);
  };

  const onDragOver = (e) => {
    e.preventDefault();
    setHovering(true);
  };

  const onDrop = (e) => {
    e.preventDefault();
    const movedIndex = Number(e.dataTransfer.getData('dragIndex'));
    move(movedIndex, index);
    setDragging(false);
    setHovering(false);
  };

  const onDragEnd = (e) => {
    e.preventDefault();
    setDragging(false);
    setHovering(false);
    setGlobalDragging(false);
  };

  const hoverable = (() => {
    if (contentRef.current && containerRef.current) {
      return contentRef.current.offsetWidth > containerRef.current.offsetWidth;
    }
    return false;
  })();

  return (
    <Box
      draggable={draggable && canDrag}
      style={{
        backgroundColor: wellColor,
        width: width ? width : '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: hoverable && hovering ? 'center' : 'left',
        position: 'relative',
        overflow:
          hoverable && hovering && !dragging && !globalDragging
            ? 'visible'
            : 'clip',
        opacity: dragging ? 0.3 : 1,
        border:
          hovering && globalDragging && !dragging ? 'solid 2px black' : 'none',
        zIndex: hoverable && hovering && !dragging ? 50 : 0,
      }}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragLeave={() => setHovering(false)}
      onDrop={onDrop}
      onDragEnd={onDragEnd}
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      ref={containerRef}
    >
      <div
        style={{
          padding: 5,
          zIndex: 1,
          backgroundColor: wellColor,
          boxShadow:
            sample && hoverable && hovering ? '#00000020 2px 2px 10px' : 'none',
        }}
        ref={contentRef}
      >
        {sample ? sample.name : ''}
      </div>
      {error.length > 0 && (
        <Tooltip
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            opacity: 0.7,
            zIndex: 10000,
          }}
          title={
            <div>
              {error.map((err) => (
                <div>{err}</div>
              ))}
            </div>
          }
        >
          <ErrorIcon fontSize="8px" />
        </Tooltip>
      )}
    </Box>
  );
};
