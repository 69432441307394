import {
    FormGroup,
    Box,
    FormLabel,
    FormControl,
    ListItemText,
    TextField,
    Checkbox,
    FormControlLabel,
    Select,
    InputLabel,
    MenuItem,
    Button,
    ButtonGroup,
    IconButton,
  } from '@mui/material';
import Grid from '@mui/material/Grid';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import MUIDataTable from 'mui-datatables';

import React, {
useState,
useRef,
useContext,
Redirect,
useEffect,
} from 'react';
const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV

function BillingSummaryTable(props) {
    const data = props.orders;
    const columns = [
        {
            label: 'Billing Cycle',
            name: 'billingCycle',
            options: {
                filter: false,
            },
        },
        {
            label: 'Sanger Samples',
            name: 'sangerNoOfSamples',
            options: {
                filter: false,
                setCellHeaderProps: value => ({ style: { backgroundColor: '#e2cd74' } }),
            },
        },
        {
            label: 'Fragment Samples',
            name: 'fragmentNoOfSamples',
            options: {
                filter: false,
                setCellHeaderProps: value => ({ style: { backgroundColor: '#95baa5' } }),
            },
        },
        {
            label: 'Total Number of Samples',
            name: 'totalNoOfSamples',
            options: {
                filter: false,
            },
        },
        {
            label: 'Pending Cost ($)',
            name: 'pCost',
            options: {
                filter: false,
                setCellHeaderProps: value => ({ style: { backgroundColor: '#cc9987' } }),
            },
        },
        {
            label: 'Billed Cost ($)',
            name: 'bCost',
            options: {
                filter: false,
                setCellHeaderProps: value => ({ style: { backgroundColor: '#8db3b7' } }),
            },
        },
        {
            label: 'Total Cost ($)',
            name: 'cost',
            options: {
                filter: false,
                setCellHeaderProps: value => ({ style: { backgroundColor: '#7080a0' } }),

            },
        },
        {
            label: 'Average Price per Sample ($)',
            name: 'pricePerSample',
            options: {
                filter: false,  
            },
        },
    ];
    const options = {
        filter: true,
        filterType: 'multiselect',
        print: false,
        viewColumns: true,
        responsive: 'standard',
        selectableRowsHideCheckboxes: true,
        setFilterChipProps: (colIndex, colName, data) => {
            return {
                color: 'primary',
                variant: 'outlined',
                className: 'testClass123',
            };
        },
    };

    return (
        <div>
            <Grid container
                direction="column"
                alignItems="center"
                spacing={0}
                margin={0}>
            </Grid>
            <MUIDataTable title={'Billing Summary Table'}
                data={data.map(item => {
                    return [
                        item.billingCycle,
                        item.sangerNoOfSamples,
                        item.fragmentNoOfSamples,
                        item.sangerNoOfSamples + item.fragmentNoOfSamples,
                        item.pCost.toFixed(2),
                        item.bCost.toFixed(2),
                        item.cost.toFixed(2),
                        (item.cost / (item.sangerNoOfSamples + item.fragmentNoOfSamples)).toFixed(2),
                    ]
                })}
                columns={columns}
                options={options}
            />
        </div>
    );
};

export default BillingSummaryTable;


