import React, { useState, useRef } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListSubheader from '@mui/material/ListSubheader';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Switch from '@mui/material/Switch';

//import { Formik, Form, useField } from 'formik';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import RotateLoader from 'react-spinners/RotateLoader';
import { socket, SocketContext } from '../../context/socket';
import axios from 'axios';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV

export default function Popup(props) {
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  let initialData = props.products[0];
  /** 
  initialData.forEach((element) => {


    Object.keys(element).forEach(el => {
  
        //console.log("key: " + el+": inital Data inside: " + element[el]);
  
  
      
    })
  
  } )
  */

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(100, "Can't be larger than 100 characters")
      .min(1, 'Must be at least 1 character'),
    totalQuantity: Yup.number()
      .positive("Must be more than 0")
      .integer('Must be a integer'),
    manufacturer: Yup.string()
      .max(20, "Can't be larger than 20 characters")
      .min(1, 'Must be at least 1 character'),
    location: Yup.string()
      .max(200, "Can't be larger than 200 characters")
      .min(1, 'Must be at least 1 character'),
    catologNumber: Yup.string()
      .max(20, "Can't be larger than 20 characters")
      .min(1, 'Must be at least 1 character')
      .required('Required'),
    orderStatus: Yup.string()
      .max(20, "Can't be larger than 20 characters")
      .min(1, 'Must be at least 1 character'),
    ordered: Yup.boolean(),
    triggerAmount: Yup.number()
      .integer('Must be a integer')
      .required('Required'),
    unit: Yup.string()
      .max(20, "Can't be larger than 20 characters")
      .min(1, 'Must be at least 1 character'),
    notes: Yup.string()
      .max(500, "Can't be larger than 500 characters")
      .min(1, 'Must be at least 1 character'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      totalQuantity: 0,
      manufacturer: '',
      location: '',
      catologNumber: '',
      orderStatus: '',
      ordered: false,
      triggerAmount: 0,
      unit: '',
      notes: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmitLoad(true);
      try {
        var res = await axios.post(API_ENDPOINT + '/inventory/add', values);
        var status = res.status;
        if (status === 201) {
          socket.emit('addInventory', values.catologNumber);
          setSubmitSucess(true);
          setSubmitResponse('Add sucessfully !');
        } else if (status === 202) {
          setSubmitSucess(false);
          setSubmitResponse(
            'catolog number already exist, please input another one'
          );
        }
      } catch {
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      //console.log('submit');
      //console.log(values);
      setSubmitStatus(true);
      setSubmitLoad(false);
    },
  });

  const handleClose = () => {
    setSubmitStatus(false);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleClose}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>

      <form onSubmit={formik.handleSubmit}>
        <List
          sx={{
            width: '100%',
            maxWidth: 800,
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 600,
          }}
        >
          <ListItem alignItems="flex-start">
            <ListItemText primary={`Catalog#:`} />
            <TextField
              id="catologNumber"
              name="catologNumber"
              label="Catolog Number"
              value={formik.values.catologNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.catologNumber &&
                Boolean(formik.errors.catologNumber)
              }
              helperText={
                formik.touched.catologNumber && formik.errors.catologNumber
              }
            />
          </ListItem>

          <Divider component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText primary={`Product Name:`} />
            <TextField
              id="name"
              name="name"
              label="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </ListItem>

          <Divider component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText primary={`Total Quantity:`} />

            <TextField
              id="totalQuantity"
              name="totalQuantity"
              label="Total Quantity"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.totalQuantity}
              onChange={formik.handleChange}
              error={
                formik.touched.totalQuantity &&
                Boolean(formik.errors.totalQuantity)
              }
              helperText={
                formik.touched.totalQuantity && formik.errors.totalQuantity
              }
            />
          </ListItem>

          <Divider component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText primary={`Trigger Amount:  `} />
            <TextField
              id="triggerAmount"
              name="triggerAmount"
              label="Trigger Amount"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.triggerAmount}
              onChange={formik.handleChange}
              error={
                formik.touched.triggerAmount &&
                Boolean(formik.errors.triggerAmount)
              }
              helperText={
                formik.touched.triggerAmount && formik.errors.triggerAmount
              }
            />
          </ListItem>

          <Divider component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText primary={`Unit:`} />
            <TextField
              id="unit"
              name="unit"
              label="Unit"
              value={formik.values.unit}
              onChange={formik.handleChange}
              error={formik.touched.unit && Boolean(formik.errors.unit)}
              helperText={formik.touched.unit && formik.errors.unit}
            />
          </ListItem>

          <Divider component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText primary={`Manufacturer:`} />
            <TextField
              id="manufacturer"
              name="manufacturer"
              label="Manufacturer"
              defaultValue={formik.values.manufacturer}
              onChange={formik.handleChange}
              error={
                formik.touched.manufacturer &&
                Boolean(formik.errors.manufacturer)
              }
              helperText={
                formik.touched.manufacturer && formik.errors.manufacturer
              }
            />
          </ListItem>

          <Divider component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText primary={`Location:`} />
            <TextField
              id="location"
              name="location"
              label="Location"
              value={formik.values.location}
              onChange={formik.handleChange}
              error={formik.touched.location && Boolean(formik.errors.location)}
              helperText={formik.touched.location && formik.errors.location}
            />
          </ListItem>

          <Divider component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText primary={`Notes:`} />
            <TextField
              id="notes"
              name="notes"
              label="Notes"
              multiline
              value={formik.values.notes}
              onChange={formik.handleChange}
              error={formik.touched.notes && Boolean(formik.errors.notes)}
              helperText={formik.touched.notes && formik.errors.notes}
            />
          </ListItem>

          <Divider component="li" />
          <ListItem alignItems="flex-start">
            <LoadingButton
              size="large"
              classes="profileSubmit"
              type="submit"
              variant="outlined"
              // onClick={handleClick}
              loading={submitLoad}
              loadingIndicator={
                <RotateLoader
                  cssOverride={{ left: '100%' }}
                  size={7}
                  margin={-15}
                />
              }
            >
              Submit
            </LoadingButton>
          </ListItem>
        </List>
      </form>
    </Grid>
  );
}

{
  /**
<Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="title"
                color="text.primary"
                fontSize="30px"
              >
                {`Order ${currentOrder.name}`}
              </Typography>
            
*/
}

{
  /** 
    <Divider  component="li" />
      <ListItem alignItems="flex-start">
        <ListItemText
          primary="Combo Run?"
        />
        <ComboRun currentOrder={currentOrder} UpdateCurrentOrderAttribute={UpdateCurrentOrderAttribute}/>
      </ListItem>
      */
}

{
  /**
   * <Formik
        //Initial vlaues of the form that are also updated via tha backend per the logged in user
        initialValues={
            initialData
        }
        enableReinitialize={true}
        //form validation for each field
        validationSchema={Yup.object({
            name: Yup.string()
                .max(20, "Can't be larger than 20 characters")
                .min(1, "Must be at least 1 character"),
            totalQuantity: Yup.number()
            .positive("Must be more than 0")
            .integer("Must be more than 0"),
            manufacturer: Yup.string()
            .max(20, "Can't be larger than 20 characters")
            .min(1, "Must be at least 1 character"),
            location: Yup.string()
            .max(40, "Can't be larger than 20 characters")
            .min(1, "Must be at least 1 character"),
            catologNumber: Yup.string()
            .max(20, "Can't be larger than 40 characters")
            .min(1, "Must be at least 1 character"),
            notes: Yup.string()
            .max(50, "Can't be larger than 20 characters")
            .min(1, "Must be at least 1 character"),
            
        })}
        //On Submit action fo the form upadting the values int he backend for the logged in user.
        onSubmit={ async (values) => {
            setSubmitLoad(true);
            try {
                var res = await axios.post(API_ENDPOINT + '/inventory/add', values);
                var status = res.status
                if (status === 201) {
                    setSubmitSucess(true);
                    setSubmitResponse("Add sucessfully submitted!")
                }
            } catch {
                setSubmitSucess(false);
                setSubmitResponse("Unsucessful! Please try again later or contact adminsitrator if problem persists")
            }
        //console.log('submit');
        //console.log(values);
        setSubmitStatus(true);
        setSubmitLoad(false);
        }}>
   */
}
