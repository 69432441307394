import React from 'react';

export default function Home() {
  return (
    <div>
      <h1 style={{ textAlign: 'center', padding: '4em' }}>
        {' '}
        404 Page Not Found
      </h1>
    </div>
  );
}
