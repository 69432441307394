import React, { useState, useRef, useEffect, useContext } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import RotateLoader from 'react-spinners/RotateLoader'
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ExpandMoreForOneStatus from './ExpandMoreForOneStatus';
import axios from 'axios';
import ExpandMoreRecent5 from './ExpandMoreRecent5';
import { socket, SocketContext } from '../../../context/socket';
import { useSnackbar } from 'notistack';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV
async function getInitialData({
  setGetLoad,
  setInitialData,
  setOrderReceived,
  ordersReceived,
}) {
  if (ordersReceived === false) {
    try {
      const res = await axios.get(API_ENDPOINT + '/Inventory/inventoryLog/all/5', {
      });
      let dataToBeUsed = [];
      res.data.forEach((element) => {
        let oneRow = {};
        Object.keys(element).forEach((el) => {
          if (el == '__v') {
          } else {
            //////console.log("key: " + el+": inital Data inside: " + element[el]);
            oneRow = { ...oneRow, [el]: element[el] };
          }
        });
        dataToBeUsed = [...dataToBeUsed, oneRow];
      });
      ////console.log('dataToBeUsed:' + dataToBeUsed.length);
      setInitialData(dataToBeUsed);
      setGetLoad(false);
      setOrderReceived(true);
    } catch (error) {
      ////console.log(error);
    }
  } else {
    //DO Nothing
  }
}

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCardRecent5(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [getLoad, setGetLoad] = useState(true);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [initialData, setInitialData] = useState([]);
  const [ordersReceived, setOrderReceived] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    socket.on('updateOthersProcessingOrder', (msg) => {
      //////console.log("run");
      setOrderReceived(false);
      //enqueueSnackbar(msg, { variant: 'info' });
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelfProcessingOrder', () => {
      ////console.log('run');
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateOthers', (msg) => {
      //////console.log("run");
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelf', () => {
      //////console.log("run");
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateOtherslog', () => {
      //////console.log("run");
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelflog', () => {
      //////console.log("run");
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('updateAll', () => {
      //////console.log("run");
      setOrderReceived(false);
    });
  }, []);


  useEffect(() => {
    socket.on('connect', () => console.log(socket.id));
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on('disconnect', () => console.log('Server disconnected'));
  }, []);

  useEffect(() => {
    getInitialData({
      setGetLoad,
      setInitialData,
      setOrderReceived,
      ordersReceived,
    }).catch(console.error);
  }, [ordersReceived]);

  const handleClose = () => {
    setSubmitStatus(false);
  };

  return (
    <Grid
    container
    direction="column"
    alignItems="center"
    spacing={0}
    margin={0}
  >
    <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
      <PropagateLoader />
    </Backdrop>

    <Backdrop
      sx={{ color: '#fff', zIndex: 1 }}
      open={submitStatus}
      onClick={handleClose}
    >
      {submitSucess ? (
        <Alert
          onClose={() => {
            setSubmitStatus(false);
          }}
          icon={<CheckIcon fontSize="inherit" />}
          severity="success"
        >
          {submitResponse}
        </Alert>
      ) : (
        <Alert
          onClose={() => {
            setSubmitStatus(false);
          }}
          severity="error"
        >
          {submitResponse}
        </Alert>
      )}
    </Backdrop>
    <Card style={{ height: 500 }} sx={{ width: 600 }}>
      <CardContent>

            <Alert variant="filled" severity="info">
              {'Recent 5 Actions'}
            </Alert>
          
      </CardContent>
      {/** 
      <CardActions disableSpacing>
        
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
*/}
{
     // <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
        <ExpandMoreRecent5 inventoryItems = {initialData}/>
        </CardContent>
     // </Collapse>
}
    </Card>
    </Grid>
  );
}