import React, { useState } from 'react';
import { Button } from 'reusable/Button';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Grid,
} from '@mui/material';

function FragmentInfoForm() {
  const [openSection, setOpenSection] = useState(null);

  const handleOpen = (section) => () => {
    setOpenSection(section);
  };

  const handleClose = () => {
    setOpenSection(null);
  };

  return (
    <div style={{ marginTop: 10 }}>
      <Typography variant="h4" align="center">
        Before submitting any data, please view all the sections by pressing
        each button.
      </Typography>
      <Box display="flex" justifyContent="center" m={4}>
        <Grid container spacing={3} justify="center">
          {[
            '5 Easy Steps',
            'Packaging Tips',
            'Sample Guidelines',
            'Sample Storage Policy',
            'Questions',
          ].map((title) => (
            <Grid item key={title}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpen(title)}
              >
                {title}
              </Button>
            </Grid>
          ))}

          <Dialog
            open={openSection !== null}
            onClose={handleClose}
            maxWidth="md"
          >
            <DialogTitle>{openSection}</DialogTitle>
            <DialogContent>
              {openSection === '5 Easy Steps' && (
                <div>
                  <Typography>
                    1) Please fill out fragment details in the form. Your data
                    will be saved across different sessions
                  </Typography>
                  <Typography>
                    2) If you would like to instead upload an excel file, please
                    click the "UPLOAD EXCEL" button and follow the instructions.
                  </Typography>
                  <Typography>
                    3) Submit a request for Fragment Analysis through SUMS
                    (www.sums.gatech.edu) IF on a per request basis.
                  </Typography>
                  <Typography>
                    4) Include a paper copy of the drop off code with your
                    submission.
                  </Typography>
                  <Typography>
                    5) Drop off your samples by 8:00 AM for Next-Day service*, 2
                    to 3-Day service applies otherwise during weekdays.
                  </Typography>
                </div>
              )}
              {openSection === 'Packaging Tips' && (
                <div>
                  <Typography>
                    Protect samples by carefully sealing plate and wrapping
                    tubes.
                  </Typography>
                  <Typography>
                    Shipment of samples on ice is recommended (not applicable
                    for dropbox submissions).
                  </Typography>
                  <Typography>
                    Do not place plate loosely inside the transport container.
                  </Typography>
                </div>
              )}
              {openSection === 'Sample Storage Policy' && (
                <Typography>
                  Samples will not be returned. All unused samples will be
                  discarded 28 days after the data is released.
                </Typography>
              )}
              {openSection === 'Questions' && (
                <Typography>
                  If you still need help, reach out to sanger@ibb.gatech.edu
                </Typography>
              )}
              {openSection === 'Sample Guidelines' && (
                <div>
                  <ul
                    style={{
                      listStyleType: 'disc',
                      paddingLeft: '0px',
                      display: 'list-item',
                    }}
                  >
                    <li>
                      Please input each sample with its specifications at
                      submission.
                    </li>
                    <li>
                      Specify the location of each sample on the submission
                      plate.
                    </li>
                    <li>
                      Sample type, size standard, dye set, and run module should
                      be selected from drop-down menus.
                    </li>
                    <li>Please name samples to your liking.</li>
                    <li>
                      *Note: Sample Volume should be above 20µL (likely between
                      20 and 40µL).
                    </li>
                  </ul>
                </div>
              )}
            </DialogContent>
          </Dialog>
        </Grid>
      </Box>
    </div>
  );
}

export default FragmentInfoForm;
