import React, { useState, useRef, useEffect, useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListSubheader from '@mui/material/ListSubheader';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Switch from '@mui/material/Switch';
import InputAdornment from '@mui/material/InputAdornment';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import NotesRender from './NotesRender';
import EditButtonPopup from './Edit/EditButtonPopup';

//import { Formik, Form, useField } from 'formik';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import PropagateLoader from 'react-spinners/PropagateLoader';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import RotateLoader from 'react-spinners/RotateLoader';
import { socket, SocketContext } from '../../../context/socket';
import { useSnackbar } from 'notistack';
import { SnackbarProvider } from 'notistack';
import DeleteConfirmation from '../../../reusable/DeleteConfirmation';
import axios from 'axios';

const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_ENDPOINT_PROD
    : process.env.REACT_APP_API_ENDPOINT_DEV;

async function getInitialData({
  setGetLoad,
  setInitialData,
  inventoryidthis,
  setOrderReceived,
  ordersReceived,
}) {
  if (ordersReceived === false) {
    try {
      const res = await axios.get(API_ENDPOINT + '/Inventory/processingOrder', {
        params: { inventoryItem: inventoryidthis },
      });
      let dataToBeUsed = [];
      res.data.forEach((element) => {
        let oneRow = {};
        Object.keys(element).forEach((el) => {
          if (el == '__v') {
          } else {
            ////console.log("key: " + el+": inital Data inside: " + element[el]);
            oneRow = { ...oneRow, [el]: element[el] };
          }
        });
        dataToBeUsed = [...dataToBeUsed, oneRow];
      });
      //console.log('dataToBeUsed:' + dataToBeUsed.length);
      setInitialData(dataToBeUsed);
      setGetLoad(false);
      setOrderReceived(true);
    } catch (error) {
      //console.log(error);
    }
  } else {
    //DO Nothing
  }
}

export default function ProcessingOrder(props) {
  const [getLoad, setGetLoad] = useState(true);
  const [submitLoad, setSubmitLoad] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [submitSucess, setSubmitSucess] = useState(true);
  const [initialData, setInitialData] = useState([]);
  const [ordersReceived, setOrderReceived] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [deleteInventoryItem, setdeleteInventoryItem] = useState(null);

  //let initialData = processingOrders;
  let item = props.products;
  let catologNumberthis = item.catologNumber;
  let inventoryidthis = item._id;

  const showDeleteModal = (deleteInventoryItem) => {
    setdeleteInventoryItem(deleteInventoryItem);
    setDeleteMessage(
      `Are you sure you want to cancel the order: ${deleteInventoryItem.orderID} ?`
    );
    setDisplayConfirmationModal(true);
  };
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };
  useEffect(() => {
    socket.on('updateOthersProcessingOrder', (msg) => {
      ////console.log("run");
      setOrderReceived(false);
      //enqueueSnackbar(msg, { variant: 'info' });
    });
  }, []);

  useEffect(() => {
    socket.on('updateSelfProcessingOrder', () => {
      //console.log('run');
      setOrderReceived(false);
    });
  }, []);

  useEffect(() => {
    socket.on('connect', () => console.log(socket.id));
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on('disconnect', () => console.log('Server disconnected'));
  }, []);

  useEffect(() => {
    getInitialData({
      setGetLoad,
      setInitialData,
      inventoryidthis,
      setOrderReceived,
      ordersReceived,
    }).catch(console.error);
  }, [ordersReceived]);

  const handleDelete = (thisinventory) => {
    return async () => {
      setSubmitLoad(true);
      try {
        var res = await axios.delete(
          API_ENDPOINT + '/Inventory/processingOrder/delete',
          { data: thisinventory }
        );

        var status = res.status;

        //console.log('status' + status);
        if (status === 201) {
          socket.emit('deleteProcessingOrder', thisinventory.orderID);
          socket.emit(
            'deleteProcessingOrderstoInventory',
            thisinventory.orderID,
            item.catologNumber
          );
          //console.log('status == 201');
          setSubmitSucess(true);
          setSubmitResponse('Cancel sucessfully!');
        }
      } catch {
        //console.log('error');
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      //console.log('submit');
      //console.log(thisinventory);
      setSubmitStatus(true);
      setDisplayConfirmationModal(false);
      setSubmitLoad(false);
    };
  };

  const handleToggle = (values) => {
    return async (event) => {
      setSubmitLoad(true);
      try {
        let updated = { ...values, delivered: event.target.checked };
        var res = await axios.put(
          API_ENDPOINT + '/Inventory/processingOrder/update',
          updated
        );
        var status = res.status;
        if (status === 201) {
          socket.emit('updateProcessingOrder', updated.orderID);
          socket.emit(
            'updateProcessingOrderstoInventory',
            updated.orderID,
            item.catologNumber
          );
          setSubmitSucess(true);

          setSubmitResponse('mark the order as delivered sucessfully!');
        }
      } catch {
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      //console.log('submit');
      //console.log(values);
      setSubmitStatus(true);
      setSubmitLoad(false);
    };
  };

  const validationSchema = Yup.object({
    quantity: Yup.number()
      .positive('Must be more than 0')
      .integer('Must be a integer'),
    delivered: Yup.boolean(),
    //catologNumber: Yup.string(),
    trackingNumber: Yup.string()
      .max(20, "Can't be larger than 20 characters")
      .min(1, 'Must be at least 1 character'),
    orderID: Yup.string()
      .max(20, "Can't be larger than 20 characters")
      .min(1, 'Must be at least 1 character'),
    notes: Yup.string()
      .max(500, "Can't be larger than 500 characters")
      .min(1, 'Must be at least 1 character'),
  });

  const formik = useFormik({
    initialValues: {
      quantity: 0,
      delivered: false,
      notes: '',

      //catologNumber: item.catologNumber,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //console.log("submit");
      setSubmitLoad(true);
      try {
        let updated = { ...values, inventoryItem: inventoryidthis };
        var res = await axios.post(
          API_ENDPOINT + '/Inventory/processingOrder/add',
          updated
        );
        var status = res.status;
        if (status === 201) {
          socket.emit('addProcessingOrder', values.orderID);
          socket.emit(
            'addProcessingOrderstoInventory',
            values.orderID,
            item.catologNumber
          );
          setSubmitSucess(true);
          setSubmitResponse('Add order sucessfully!');
        } else if (status === 202) {
          setSubmitSucess(false);
          setSubmitResponse('Order ID already exist, please input another one');
        }
      } catch {
        setSubmitSucess(false);
        setSubmitResponse(
          'Unsucessful! Please try again later or contact adminsitrator if problem persists'
        );
      }
      ////console.log('submit');
      //console.log(values);
      setSubmitStatus(true);
      setSubmitLoad(false);
    },
  });

  const handleClose = () => {
    setSubmitStatus(false);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={0}
      margin={0}
    >
      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={getLoad}>
        <PropagateLoader />
      </Backdrop>

      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={submitStatus}
        onClick={handleClose}
      >
        {submitSucess ? (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
          >
            {submitResponse}
          </Alert>
        ) : (
          <Alert
            onClose={() => {
              setSubmitStatus(false);
            }}
            severity="error"
          >
            {submitResponse}
          </Alert>
        )}
      </Backdrop>

      <List
        sx={{
          width: '100%',
          maxWidth: 2000,
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 600,
        }}
      >
        {/** <ListItem alignItems="flex-start">
              <ListItemText primary={'Current Total Processing Quantity: ' + item.processingQuantity} />
          </ListItem>*/}

        {initialData.length === 0 ? (
          <></>
        ) : (
          initialData.map((item1) => (
            <>
              <ListItem key={item1._id} alignItems="flex-start">
                <ListItemText
                  primary={
                    <>
                      {' '}
                      <span>{'Order ID: ' + item1.orderID}</span>
                      <br></br>
                      <span>{'Tracking Number: ' + item1.trackingNumber}</span>
                      <br></br>
                      <span>{'Quantity: ' + item1.quantity}</span>
                      <br></br>
                      <div>
                        <span>{'Notes: '}</span>
                        <NotesRender thisinventory={item1} attri="notes" />
                      </div>
                    </>
                  }
                  primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                  secondary={`Ordered on ${new Date(
                    item1.orderDate
                  ).toDateString()}`}
                />
                <div>
                  <FormControlLabel
                    style={{ marginLeft: 15, marginRight: 15 }}
                    control={
                      <Switch edge="end" onChange={handleToggle(item1)} />
                    }
                    label="Delivered"
                  />
                </div>
                <br></br>
                <div>
                  <Button
                    variant="outlined"
                    //onClick={() => showDeleteModal(item1)}
                    onClick={handleDelete(item1)}
                    size="small"
                  >
                    Cancel Order
                  </Button>

                  <EditButtonPopup products={item1} />
                </div>
              </ListItem>
            </>
          ))
        )}
        <form onSubmit={formik.handleSubmit}>
          <Divider component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText primary={` Order ID:`} />
            <TextField
              id="orderID"
              name="orderID"
              label="Order ID"
              style={{ marginLeft: 15, marginRight: 15 }}
              value={formik.values.orderID}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.orderID)}
              helperText={formik.touched.name && formik.errors.orderID}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText primary={`Tracking Number:`} />
            <TextField
              id="trackingNumber"
              name="trackingNumber"
              label="Tracking Number"
              style={{ marginLeft: 15, marginRight: 15 }}
              value={formik.values.trackingNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.name && Boolean(formik.errors.trackingNumber)
              }
              helperText={formik.touched.name && formik.errors.trackingNumber}
            />
          </ListItem>
          <ListItem
            alignItems="flex-start"
            style={{ justifyContent: 'space-between' }}
          >
            <ListItemText primary={`Quantity:`} />

            <TextField
              style={{ marginLeft: 15, marginRight: 15 }}
              name="quantity"
              id="quantity"
              label="Quantity"
              type="number"
              size="medium"
              InputLabelProps={{
                shrink: true,
                startAdornment: (
                  <InputAdornment position="start">kg</InputAdornment>
                ),
              }}
              value={formik.values.quantity}
              onChange={formik.handleChange}
              error={formik.touched.quantity && Boolean(formik.errors.quantity)}
              helperText={formik.touched.quantity && formik.errors.quantity}
            />
          </ListItem>

          <ListItem alignItems="flex-start">
            <ListItemText primary={`Notes:`} />
            <TextField
              id="notes"
              name="notes"
              label="Notes"
              multiline
              value={formik.values.notes}
              onChange={formik.handleChange}
              error={formik.touched.notes && Boolean(formik.errors.notes)}
              helperText={formik.touched.notes && formik.errors.notes}
            />
          </ListItem>

          <ListItem
            alignItems="flex-start"
            style={{ justifyContent: 'space-between' }}
          >
            <LoadingButton
              size="medium"
              classes="profileSubmit"
              type="submit"
              variant="outlined"
              // onClick={handleClick}
              loading={submitLoad}
              loadingIndicator={
                <RotateLoader
                  cssOverride={{ left: '100%' }}
                  size={7}
                  margin={-15}
                />
              }
            >
              Make a new Order
            </LoadingButton>
          </ListItem>
        </form>
      </List>
    </Grid>
  );
}

{
  /**
         <Divider  component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={` Quantity:`}
            />
    
              <TextField
              style={{ marginLeft: 15,marginRight: 15,}}
              id="quantity"
              name="quantity"
              
              label="quantity"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              
              value={formik.values.quantity}
              onChange={formik.handleChange}
              error={formik.touched.totalQuantity && Boolean(formik.errors.quantity)}
              helperText={formik.touched.totalQuantity && formik.errors.quantity}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
        primary={` Order ID:`}
        />
        <TextField
                id="orderID"
                name="orderID"
                label="Order ID"
                style={{ marginLeft: 15,marginRight: 15,}}
          value={formik.values.orderID}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.orderID)}
          helperText={formik.touched.name && formik.errors.orderID}
        />
   
   </ListItem>

   <ListItem alignItems="flex-start">
          <LoadingButton
          
                        size = "small"
                        classes="profileSubmit"
                        type="submit"
                        variant="outlined"
                        // onClick={handleClick}
                        loading={submitLoad}
                        loadingIndicator={<RotateLoader cssOverride={{left: "100%"}} size={7} margin={-15}/>}
                        >
                            Make a new Order
                    </LoadingButton>
      </ListItem>
<Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="title"
                color="text.primary"
                fontSize="30px"
              >
                {`Order ${currentOrder.name}`}
              </Typography>
            
*/
}

{
  /** 
    <Divider  component="li" />
      <ListItem alignItems="flex-start">
        <ListItemText
          primary="Combo Run?"
        />
        <ComboRun currentOrder={currentOrder} UpdateCurrentOrderAttribute={UpdateCurrentOrderAttribute}/>
      </ListItem>
      */
}

{
  /**
   * <Formik
        //Initial vlaues of the form that are also updated via tha backend per the logged in user
        initialValues={
            initialData
        }
        enableReinitialize={true}
        //form validation for each field
        validationSchema={Yup.object({
            name: Yup.string()
                .max(20, "Can't be larger than 20 characters")
                .min(1, "Must be at least 1 character"),
            totalQuantity: Yup.number()
            .positive("Must be more than 0")
            .integer("Must be more than 0"),
            manufacturer: Yup.string()
            .max(20, "Can't be larger than 20 characters")
            .min(1, "Must be at least 1 character"),
            location: Yup.string()
            .max(40, "Can't be larger than 20 characters")
            .min(1, "Must be at least 1 character"),
            catologNumber: Yup.string()
            .max(20, "Can't be larger than 40 characters")
            .min(1, "Must be at least 1 character"),
            notes: Yup.string()
            .max(50, "Can't be larger than 20 characters")
            .min(1, "Must be at least 1 character"),
            
        })}
        //On Submit action fo the form upadting the values int he backend for the logged in user.
        onSubmit={ async (values) => {
            setSubmitLoad(true);
            try {
                var res = await axios.post(API_ENDPOINT + '/inventory/add', values);
                var status = res.status
                if (status === 201) {
                    setSubmitSucess(true);
                    setSubmitResponse("Add sucessfully submitted!")
                }
            } catch {
                setSubmitSucess(false);
                setSubmitResponse("Unsucessful! Please try again later or contact adminsitrator if problem persists")
            }
        //console.log('submit');
        //console.log(values);
        setSubmitStatus(true);
        setSubmitLoad(false);
        }}>
   */
}
