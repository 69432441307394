import axios from 'axios';

export const getUser = async (username) => {
  return await axios({
    url: `/api/user/per`,
    method: 'GET',
    params: {
      username: username,
    },
  });
};

export const getNewUser = async (user) => {
  return await axios.get(`/api/user/new`, {
    username: user.casUser,
  });
};
