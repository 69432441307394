import { memo, useContext } from 'react';
import TextField from '@mui/material/TextField';
import { DebounceInput } from 'react-debounce-input';
import { SampleMethodContext } from '../../../contexts/MethodContext';
import { ErrorVisContext } from '../../../contexts/DataContext';

const SangerRowName = memo(
  ({ id, data, errorName }) => {
    const { submitShowError } = useContext(ErrorVisContext);
    const { updateSample } = useContext(SampleMethodContext);

    return (
      <DebounceInput
        debounceTimeout={500}
        minLength={1}
        element={TextField}
        required
        id="sample-name"
        variant="standard"
        sx={{
          '&.MuiTextField-root': {
            width: '100%',
          },
          '& .MuiInputBase-input': {
            width: '100%',
          },
          '& input:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px #fff inset',
            WebkitTextFillColor: '#000',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
        }}
        inputProps={{ spellCheck: false }}
        InputProps={{ disableUnderline: true }}
        label={undefined}
        placeholder="Sample Name"
        value={data.sampleName}
        onBlur={(e) => {
          var newTouched = [...data.touched];
          newTouched[0] = true;
          updateSample({ touched: newTouched }, id);
        }}
        onChange={(e) => {
          var newTouched = [...data.touched];
          newTouched[0] = true;
          updateSample(
            {
              sampleName: e.target.value,
              touched: newTouched,
            },
            id
          );
        }}
        error={
          (data.touched[0] || submitShowError) && errorName(data.sampleName)
        }
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.id === nextProps.id &&
      prevProps.data.sampleName === nextProps.data.sampleName
    );
  }
);

export default SangerRowName;
