import { Typography } from 'reusable/Typography';
import { Box, Menu, MenuItem } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSampleMapApi } from 'hooks/useSampleMapApi';
import { Button } from 'reusable/Button';
import {
  LabelDropdown,
  MachineDropdown,
} from './HeadingComponents/HeadingDropdowns';
import { Actions } from './HeadingComponents/Actions';
import { ActionsPopup } from './HeadingComponents/ActionsPopup';
import { getCartridgesByLetter } from 'utils/api/Cartridges';

export const SampleMapHeading = ({
  orderCode,
  sampleMapId,
  instrument,
  setInstrument,
  cartridgeId,
  setCartridgeId,
  cartridge,
  setCartridge,
  block,
  setBlock,
  samples,
  setSamples,
  primers,
}) => {
  const [editInstrument, setEditInstrument] = useState(false);
  const [editLabel, setEditLabel] = useState(false);
  const [hoverInstrument, setHoverInstrument] = useState(false);
  const [hoverLabel, setHoverLabel] = useState(false);
  const [label, setLabel] = useState('');
  const instrumentDropdownRef = useRef(null);
  const labelDropdownRef = useRef(null);
  const [cartridgeList, setCartridgeList] = useState([]);

  const { updateSampleMap } = useSampleMapApi();

  const getCartridgeData = async () => {
    let cartridgeList = await getCartridgesByLetter();
    setCartridgeList(cartridgeList);
  };

  useEffect(() => {
    // fetch all cartridges
    getCartridgeData();
  }, []);

  useEffect(() => {
    // set the label
    if (instrument === 'SeqStudio') {
      const cartridge = cartridgeList.find(
        (cartridge) => cartridge._id === cartridgeId
      );
      const cartridgeLetter = cartridge ? cartridge.letter : 'unassigned';
      setLabel(`Cartridge ${cartridgeLetter}`);
    } else {
      setLabel(`Block ${block ? block : 'unassigned'}`);
    }
  }, [instrument, cartridgeId, block]);

  useEffect(() => {
    // handle click events when clicking outside of the instrument dropdown
    const handleClickOutside = (event) => {
      if (
        instrumentDropdownRef.current &&
        !instrumentDropdownRef.current.contains(event.target)
      ) {
        setEditInstrument(false);
        setHoverInstrument(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [instrumentDropdownRef]);

  useEffect(() => {
    // handle click events when clicking outside of the instrument dropdown
    const handleClickOutside = (event) => {
      if (
        labelDropdownRef.current &&
        !labelDropdownRef.current.contains(event.target)
      ) {
        setEditLabel(false);
        setHoverLabel(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [labelDropdownRef]);

  useEffect(() => {
    setEditInstrument(false);
    setHoverInstrument(false);
  }, [instrument]);

  useEffect(() => {
    setEditLabel(false);
    setHoverLabel(false);
  }, [block, cartridgeId]);

  useEffect(() => {
    // update instrument on db
    updateSampleMap(sampleMapId, { instrument: instrument });
  }, [instrument, sampleMapId, updateSampleMap]);

  useEffect(() => {
    // update block and cartridge on db
    if (cartridgeId) {
      updateSampleMap(sampleMapId, { block: block, cartridge: cartridgeId });
      const newCartridge = cartridgeList.find(
        (cartridge) => cartridge._id === cartridgeId
      );
      setCartridge(newCartridge);
    }
  }, [block, cartridgeId, sampleMapId, updateSampleMap]);

  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);
  const [openIndex, setOpenIndex] = useState(null);

  const handleActionsClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        alignItems: 'center',
      }}
    >
      <Typography variant="h2">{orderCode}</Typography>
      {editInstrument ? (
        <MachineDropdown
          instrument={instrument}
          setInstrument={setInstrument}
          ref={instrumentDropdownRef}
        />
      ) : (
        <Typography
          variant="h3"
          onDoubleClick={() => setEditInstrument(true)}
          sx={{
            opacity: hoverInstrument ? 0.6 : 1,
            transition: 'all 0.2s',
            cursor: 'pointer',
          }}
          onMouseOver={() => setHoverInstrument(true)}
          onMouseLeave={() => setHoverInstrument(false)}
        >
          {instrument}
        </Typography>
      )}
      {editLabel ? (
        <LabelDropdown
          instrument={instrument}
          cartridge={cartridgeId}
          setCartridge={setCartridgeId}
          cartridgeList={cartridgeList}
          block={block}
          setBlock={setBlock}
          ref={labelDropdownRef}
        />
      ) : (
        <Typography
          variant="h3"
          onDoubleClick={() => setEditLabel(true)}
          sx={{
            opacity: hoverLabel ? 0.6 : 1,
            transition: 'all 0.2s',
            cursor: 'pointer',
          }}
          onMouseOver={() => setHoverLabel(true)}
          onMouseLeave={() => setHoverLabel(false)}
        >
          {label}
        </Typography>
      )}
      <Button
        id="actions-button"
        variant="contained"
        color="primary"
        style={{ marginLeft: 'auto' }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        Actions
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleActionsClose}
        MenuListProps={{
          'aria-labelledby': 'actions-button',
        }}
      >
        {Actions.map((action, i) => (
          <MenuItem
            onClick={() => {
              handleActionsClose();
              setOpenIndex(i);
            }}
            key={i}
          >
            {action.name}
          </MenuItem>
        ))}
      </Menu>
      {Actions.map((action, i) => (
        <ActionsPopup
          action={action}
          samples={samples}
          cartridge={cartridge}
          primers={primers}
          setSamples={setSamples}
          open={openIndex !== null && openIndex === i}
          onClose={() => setOpenIndex(null)}
          key={i}
        />
      ))}
    </Box>
  );
};
